import React, {useEffect, useState} from "react";
import {Autocomplete, Card, CardContent, CircularProgress, TextField, Typography} from "@mui/material";
import useSimilarFinderAPI from "./partials/hooks/useSimilarFinderAPI";
import {useRef} from "react";
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";
import userDefaultImage from "../assets/img/default.png";
import useGATrackAPI from "./Project/hooks/useGATrackAPI";

export default function SimilarFinder(props) {
    const initialStage = {
        inputSearchValue: '',
        inputValue: '',
        inputUuid: '',
        open: false,
        options: [],
        data: [],
        showNoOptionText: false,
    }
    const navigate = useNavigate();
    const [state, setState] = useState(initialStage);

    const [modal, setModal] = useState(false)
    const ref = useRef()
    const similarAPI = useSimilarFinderAPI({candidate: state.inputSearchValue}, !!state.inputSearchValue);

    useEffect(() => {
        if (similarAPI.status === 'success') {
            setState(prevState => ({
                ...prevState,
                inputSearchValue: '',
                options: (similarAPI.data),
                data: (similarAPI.data),
                showNoOptionText: !similarAPI.data?.length
            }))
        }
    }, [similarAPI.status]);

    const setSearchTextDebounced = useRef(
        debounce(searchText => setState(prevState => ({...prevState, inputSearchValue: (searchText)})), 1000)
    ).current;

    const handleInputChange = (event, value, type) => {
        if (type === 'input') {
            setState(prevState => ({...prevState, inputValue: (value)}))
            setSearchTextDebounced(value)
        }
    }
    const {getTrackOfEvent, getGATrackofDetails} = useGATrackAPI();
    const handleValueChange = (e, v) => {
        getGATrackofDetails("from ideal candidates")
        navigate(`${process.env.REACT_APP_BASENAME}/similar-candidate/${v.recruitplus.uuid}`)
    }

    useEffect(() => {
        if (props.tab) {
            setModal(true)
        }

        if (state.inputValue === '') {
            setState(initialStage)
        }

    }, [props, state.inputValue])

    const setUI = () => {
        props.closeSimilarFinder();
        setModal(false)
    }

    return (
        <>
            <div style={{width: 474, visibility: modal ? 'visible' : 'hidden'}}
                 className={modal ? "offcanvas offcanvas-end show" : "offcanvas offcanvas-end"}
                 id="offcanvasRight-similar" aria-labelledby="offcanvasRightLabel" ref={ref}>
                <div className="title_style_1">
                    <h6>Similar Finder</h6>
                    <button type="button" className="btn_close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close" onClick={setUI}>
                        <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13 13L1 1M13 1L1 13" stroke="#D8232A" strokeOpacity="0.9" strokeWidth="2"
                                  strokeLinecap="round"/>
                        </svg>
                    </button>
                </div>
                <div className="similar_finder_row">
                    <div className="similar_finder_column">
                        <Autocomplete
                            noOptionsText={(state.inputValue && state.showNoOptionText) ? 'No results found' : 'Start your search'}
                            sx={{
                                input: {
                                    color: '#3D3E40;', "&::placeholder": {
                                        opacity: 1,
                                    },
                                }
                            }}
                            className="similar_finder_dropdown"
                            openOnFocus
                            /* open={state.open}
                             onOpen={() => {
                                 setState(prevState => ({...prevState, open: true}))
                             }}
                             onClose={() => {
                                 setState(prevState => ({...prevState, open: false}))
                             }}*/
                            isOptionEqualToValue={(option, value) => (option?.recruitplus?.uuid === value?.recruitplus?.uuid)}
                            options={state.options}
                            getOptionLabel={(option) => `${option?.basic?.fullname}`}
                            filterOptions={options => options}
                            loading={similarAPI.isLoading}
                            onChange={handleValueChange}
                            onInputChange={handleInputChange}
                            inputValue={state.inputValue}
                            renderOption={(props, option, {selected}) => (
                                <Card variant="" {...props} key={option.id}>
                                    <CardContent className="similar_finder_dd">
                                        <div className="rp-img">
                                            <div
                                                className={option.recruitplus.cm_member !== '' ? 'user_profl' : 'user_profl'}>
                                                <img
                                                    src={option.basic.image_url ?? userDefaultImage}
                                                    onError={({currentTarget}) => currentTarget.src = userDefaultImage}
                                                    alt=""/>
                                            </div>
                                            <div>
                                                <Typography variant="h6" component="h6">
                                                    {option?.basic?.fullname}
                                                </Typography>
                                                <Typography variant="div" component="div">
                                                    {option?.current_position?.title &&
                                                        <>
                                                            <span
                                                                className="rp_text1">{option?.current_position?.title}</span>
                                                            <span className="rp_text2">@</span>
                                                        </>
                                                    }
                                                    {option?.current_position?.company &&
                                                        <span
                                                            className="rp_text1">{option?.current_position?.company}</span>
                                                    }
                                                </Typography>
                                                <Typography variant="div" component="div">
                                                    <h5>{option?.basic?.location}</h5>
                                                </Typography>
                                            </div>
                                        </div>
                                    </CardContent>
                                </Card>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder="Search similar profiles to"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {similarAPI.isLoading ?
                                                    <CircularProgress color="inherit" size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <div className="btn_inline">
                            {/*{state.inputValue &&*/}
                            {/*    <button onClick={() => {*/}
                            {/*        console.log("inside clear field")*/}
                            {/*        setState(initialStage)*/}
                            {/*    }}>*/}
                            {/*        <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*            <path d="M13 13L1 1M13 1L1 13" stroke="#65676B" strokeWidth="2" strokeLinecap="round"/>*/}
                            {/*        </svg>*/}
                            {/*    </button>*/}
                            {/*}*/}
                            <div className="hrline"></div>
                            <button onClick={() => {
                                if (!state.inputUuid) return
                                {
                                    getGATrackofDetails("from ideal candiates")
                                    navigate(`${process.env.REACT_APP_BASENAME}/similar-candidate/${state.inputUuid}`)

                                }
                            }}>
                                {state.inputValue ?
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                                            fill="#D8232A"/>
                                    </svg>
                                    :
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629V10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                                            fill="#CCCCCC"/>
                                    </svg>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {modal ? <div className="modal-backdrop fade show" onClick={setUI}></div> : <></>}
        </>
    )
}