import React from "react";
import InputField from "./InputField";
import PropTypes from "prop-types";
import useGetSearchList from "../hooks/useGetSearchList";
import {useState} from "react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {OverlayTrigger} from "react-bootstrap";
import Tooltip from 'react-bootstrap/Tooltip';
import useGetSavedSearchListAPI from "../../Search/hooks/useGetSavedSearchListAPI";
import InputButton from "./InputButton";
import useGATrackAPI from "../../Project/hooks/useGATrackAPI";

function LeftPanel(props) {
    const [enabled, setEnabled] = useState(false);
    const [showComponent, setShowComponent] = useState('default');
    const [showSavedSearch, setShowSavedSearch] = useState(false)
    const searchList = useGetSearchList(null, enabled);
    const [load, setLoad] = useState(false);
    const navigate = useNavigate();
    const [options, setOptions] = useState({});
    const [showAllSavedSearches, setShowAllSavedSearches] = useState(false)
    const getSavedSearchListAPI = useGetSavedSearchListAPI({data: '', prefix: props.type}, !!props.type)
    const showFilterPanel = props.showFilterPanel;

    useEffect(() => {
        if (searchList.status === 'success') {
            const arr = searchList.data.map((item) => ({
                label: item.search_name,
                value: item.search_data,
                type: 'savedSearch',
                id: item.id,
                icon: <ion-icon name="trash-outline"></ion-icon>
            }))
            setOptions(arr)
            if (load) {
                setShowComponent('dropdown')
            }
        }
    }, [searchList.status, load]);


    useEffect(() => {
        if (props.savedFilterDetails?.name) {
            props.setName(props.savedFilterDetails.name)
        }
    }, [props.savedFilterDetails])

    const handleClick = (e) => {
        e.preventDefault()
        setEnabled(true)
        setLoad(true)
        if (load) {
            setShowComponent('default')
            setLoad(false)
        }
    }
    const {getGATrackofDetails} = useGATrackAPI();
    const viewSearch = (v) => {
        if (searchList.status === 'success') {
            if (v.value !== undefined) {
                navigate(`${process.env.REACT_APP_BASENAME}/candidate-search`, {
                    state: {
                        searchData: v.value,
                        id: v.id,
                        name: v.label
                    }
                })
            }
        }
    }

    const savedSearches = []

    if (getSavedSearchListAPI.isSuccess) {
        if (getSavedSearchListAPI.data?.data) {
            const savedSearchesData = getSavedSearchListAPI.data?.data
            savedSearchesData.slice(0, showAllSavedSearches ? undefined : 2).map(({
                                                                                      search_name,
                                                                                      uuid,
                                                                                      search_data,
                                                                                      project_id
                                                                                  }) => {
                savedSearches.push(
                    <li key={uuid} onClick={() => {
                        props.setName(search_name)
                        props.setFilter(JSON.parse(search_data).filter)
                        props.setSavedFilterDetails({id: uuid, name: search_name, searchData: search_data})
                        getGATrackofDetails("from filter saved search")
                    }}>{search_name} - Search</li>
                )
            })
        }
    }

    /*   function SavedOverlay() {
           const renderTooltip = (props) => (
               <Tooltip id="button-tooltip" {...props}>
                   Save Your searches using this
               </Tooltip>
           );

           return (
               <OverlayTrigger
                   placement="top"
                   delay={{show: 250, hide: 400}}
                   overlay={renderTooltip}
               >
                   <a href="#" className="save_btn dropdown-toggle" data-bs-toggle="dropdown"
                      aria-expanded="false" id="dropdownMenuLink">
                       <span><ion-icon name={props.filterData ? "bookmark" : "bookmark-outline"}
                                       style={{pointerEvents: "none"}}></ion-icon></span>
                   </a>
               </OverlayTrigger>
           );
       }*/

    return (<>
        {showFilterPanel ?
            <div className={`prof_filter`}>
                <div className="tf_page">
                    <h3>{props.pageTitle}</h3>
                    {props.buttons &&
                        props.buttons
                    }
                </div>
                <div className="show_area">
                    <h3>Showing Results For</h3>
                    <div className="show_area_btn">
                        {props.showSavedSearch &&
                            <button onClick={() => {
                                props.setOpenSaveFilter(true)
                                props.setError('')
                            }}> {`${props.savedFilterDetails ? 'Update' : 'Save'} Search`}</button>
                        }
                        <button onClick={() => {
                            props.resetFilter()

                            if (props?.saveFilter) {
                                props?.setSavedFilterDetails(null)
                                props?.setName('')
                            }
                        }}>Clear All
                        </button>
                    </div>
                </div>
                {props.showSavedSearch && props.openSaveFilter &&
                    <div className="saved_search">
                        <div className={`dropdown-menu ${props.openSaveFilter ? 'show' : ''}`}>
                            <span className="save_btn">{`${props.savedFilterDetails ? 'Update' : 'Save'} Search`}</span>
                            <InputField
                                fullWidth
                                required
                                errorText={props.error}
                                id="name"
                                type="text"
                                className="form-control"
                                onChange={(e) => props.setName(e.target.value)}
                                placeholder="Enter Name"
                                value={props.name}/>
                            <div className="rp-btn_area">
                                <InputButton
                                    className='btn btn_fill btn_small_1'
                                    type="submit"
                                    onClick={props.handleSubmit}
                                    isLoading={props.isLoading}>
                                    {props.savedFilterDetails ? "Update" : "Save"}
                                </InputButton>
                                <button className="btn btn_outline btn_small_2" onClick={() => {
                                    props.setOpenSaveFilter(false)
                                    setShowSavedSearch(false)
                                }}>Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                }

                {props.showSavedSearch && getSavedSearchListAPI.data?.data?.length > 0 &&
                    <div className="saved_search">
                        <a className={`save_btn ${showSavedSearch ? 'chevron_up' : ''}`} onClick={() => {
                            setShowSavedSearch(!showSavedSearch)
                            setShowAllSavedSearches(showSavedSearch)
                            props.setOpenSaveFilter(false)
                        }}>
                            <span>Saved Searches</span>
                            <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_602_9556)">
                                    <path
                                        d="M6.03683 10.1515C6.08843 10.2264 6.15749 10.2877 6.23804 10.33C6.3186 10.3724 6.40824 10.3945 6.49924 10.3945C6.59024 10.3945 6.67988 10.3724 6.76043 10.33C6.84099 10.2877 6.91004 10.2264 6.96165 10.1515L12.0245 2.83838C12.0831 2.75403 12.1175 2.65523 12.1239 2.55272C12.1303 2.45021 12.1085 2.34791 12.0608 2.25693C12.0132 2.16595 11.9415 2.08977 11.8536 2.03668C11.7656 1.98358 11.6648 1.95559 11.5621 1.95575H1.43634C1.33387 1.95617 1.23345 1.98452 1.14589 2.03775C1.05832 2.09097 0.986926 2.16706 0.939372 2.25783C0.891818 2.3486 0.869907 2.45062 0.875996 2.55291C0.882085 2.6552 0.915943 2.75389 0.97393 2.83838L6.03683 10.1515Z"
                                        fill="#65676B"/>
                                    <path
                                        d="M6.03683 10.1515C6.08843 10.2264 6.15749 10.2877 6.23804 10.33C6.3186 10.3724 6.40824 10.3945 6.49924 10.3945C6.59024 10.3945 6.67988 10.3724 6.76043 10.33C6.84099 10.2877 6.91004 10.2264 6.96165 10.1515L12.0245 2.83838C12.0831 2.75403 12.1175 2.65523 12.1239 2.55272C12.1303 2.45021 12.1085 2.34791 12.0608 2.25693C12.0132 2.16595 11.9415 2.08977 11.8536 2.03668C11.7656 1.98358 11.6648 1.95559 11.5621 1.95575H1.43634C1.33387 1.95617 1.23345 1.98452 1.14589 2.03775C1.05832 2.09097 0.986926 2.16706 0.939372 2.25783C0.891818 2.3486 0.869907 2.45062 0.875996 2.55291C0.882085 2.6552 0.915943 2.75389 0.97393 2.83838L6.03683 10.1515Z"
                                        fill="black" fillOpacity="0.5"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_602_9556">
                                        <rect width="12" height="10.5" fill="white"
                                              transform="translate(0.5 0.650024)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>

                        {showSavedSearch && !props.openSaveFilter &&
                            <div className="clearfix">
                                <div className="saved_search_list">
                                    <ul>
                                        {savedSearches}
                                    </ul>
                                    {getSavedSearchListAPI.data?.data?.length > 2 &&
                                        <button className="show_more_btn mt-0" onClick={() => {
                                            setShowAllSavedSearches(!showAllSavedSearches)
                                        }}>{showAllSavedSearches ? "Show less" : "Show more"}</button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                <div className="side_panel scrollbar">
                    <div className="sidepanel_bg">
                        {props.children}
                    </div>
                </div>
            </div>
            :
            ''
        }
    </>)

}

LeftPanel.propTypes = {
    setName: PropTypes.func,
    saveFilter: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.element
    ]),
}

export default LeftPanel
