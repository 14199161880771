import {useMutation, useQueryClient} from "react-query";
import {companyService} from "../../../redux/services";
import {alertActions} from "../../../redux/actions";
import {useDispatch} from "react-redux";

const addOrEditProject = async (input) => {
    if (input.id) {
        return await companyService.editProject(input);
    } else {
        return await companyService.addProject(input);
    }
}

export default function useAddOrEditProjectAPI(onAddProjectSuccess = () => {}) {
    const queryClient = useQueryClient()
    const dispatch = useDispatch()
    return useMutation(addOrEditProject, {
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries(['projectData'])
            dispatch(alertActions.success(data.status));
            onAddProjectSuccess({...data, ...variables})
        },
        onError: (error, variables, context) => {
            console.log(error, variables, context)
        }
    })
}