import {Gtm} from "../../../helpers/gtm";

export default function useGATrackAPI() {
    function getTrackOfEvent(name, value) {
        if (process.env.REACT_APP_GA_ENABLED === 'true') {
            Gtm.gtag('event', 'search', {
                "search_name": `${name} clicked`,
                "search_value": `${value} searched`
            })
        }
    }

    function getGATrackofDetails(name) {
        if (process.env.REACT_APP_GA_ENABLED === 'true') {
            Gtm.gtag('event', "search", {
                "candidate_data": `${name} clicked`
            })
        }

    }

    return {getTrackOfEvent, getGATrackofDetails};
}