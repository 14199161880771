import React from "react";
import {TextField} from "@mui/material";
import {Validate} from 'mui-validate';
import PropTypes from "prop-types";

const InputField = (props) => {
    const {
        value,
        name,
        placeholder,
        type, id,
        onChange,
        onBlur,
        error,
        label,
        className,
        readOnly,
        helperText,
        inputProps,
        InputLabelProps,
        sx,
        autoComplete,
        disabled,
        defaultValue,
        errorText,
        fullWidth,
        strongPassword,
        size,
        variant,
        classes,
        autoFocus
    } = props

    let {
        customValidation,
        required,
        regexValidation,
    } = props


    if (type === 'email') {
        regexValidation = [
            /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
            'Please enter a valid Email'
        ]
    }
    if (type === 'number') {
        regexValidation = [
            /\d*/,
            'Please enter a valid number'
        ]
    }

    let handleKeyDown = () => {};
    if (type === 'tel' || name === 'phone') {
        regexValidation = [
            /^[6-9][0-9]{9}$/,
            'Please enter a valid phone number'
        ]
        handleKeyDown = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
    }
    if (strongPassword) {
        regexValidation = [
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
            'Password must be at least 8 characters with an uppercase, lowercase, number and special character.'
        ]
    }

    return (
        <Validate
            name={name}
            required={required}
            regex={regexValidation}
            custom={customValidation}>
            <TextField
                variant={variant}
                size={size}
                fullWidth={fullWidth}
                sx={sx}
                type={type}
                classes={classes}
                InputProps={inputProps}
                InputLabelProps={InputLabelProps}
                error={error || !!errorText}
                name={name}
                onBlur={onBlur}
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                autoComplete={autoComplete}
                value={value}
                readOnly={readOnly}
                autoFocus={autoFocus}
                defaultValue={defaultValue}
            helperText={!!errorText ? <span className='rp-error'>{errorText}</span> : (!!helperText &&
                    <span className='rp-error'>{helperText}</span>)}
                label={label}
                id={id}
                className={className}
                disabled={disabled}
            />
        </Validate>
    );
};


InputField.propTypes = {
    value:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    error: PropTypes.object,
    label: PropTypes.string,
    classname: PropTypes.string,
    readOnly: PropTypes.string,
    helperText: PropTypes.string,
    inputProps: PropTypes.object,
    InputLabelProps: PropTypes.object,
    sx: PropTypes.object,
    autoComplete: PropTypes.string,
    disabled: PropTypes.bool,
    errorText: PropTypes.string,
    fullWidth: PropTypes.bool,
    strongPassword: PropTypes.bool,
    customValidation: PropTypes.array,
    required: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.array
    ]),
    regexValidation: PropTypes.object,
    classes: PropTypes.object
}


export default InputField;