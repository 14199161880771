import React from "react";
import {Button} from "@mui/material";
import PropTypes from 'prop-types'

const InputButton = (props) => {
    const {
        type,
        className,
        id,
        onClick,
        onSubmit,
        onBlur,
        value,
        isLoading,
        data_bs_toggle,
        data_bs_target,
        aria_controls,
        aria_selected,
        variant,
        size,
        style,
        color,
        classes,
        sx,
    } = props

    let {disabled} = props;


    if (isLoading) {
        disabled = true;
    }

    return (
        <>
            <Button
                sx={sx}
                color={color}
                type={type}
                className={className}
                id={id}
                onClick={onClick}
                onSubmit={onSubmit}
                data-bs-toggle={data_bs_toggle}
                data-bs-target={data_bs_target}
                // aria-controls={aria_controls}
                // aria-selected={aria_selected}
                size={size}
                variant={variant}
                onBlur={onBlur}
                style={style}
                disabled={disabled}
                classes={classes}
                value={value}>
                {!isLoading ? (props.children) : 'Loading...'}
            </Button>
        </>
    );
};

InputButton.propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.object,
    onClick: PropTypes.func,
    onSubmit: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.object,
    isLoading: PropTypes.bool,
    data_bs_toggle: PropTypes.object,
    data_bs_target: PropTypes.object,
    variant: PropTypes.string,
    size: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string,
    sx: PropTypes.object,
    disabled: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]),
}


export default InputButton;