import React, {useState} from 'react';
import InputButton from "./partials/components/InputButton";
import useTermsAcceptanceAPI from "./Project/hooks/useTermsAcceptanceAPI";
import {useQueryClient} from "react-query";
import {useDispatch, useSelector} from "react-redux";
import useGATrackAPI from "./Project/hooks/useGATrackAPI";
import {userConstants} from "../redux/constants";
import useUpdateUserDetails from "../hooks/useUpdateUserDetails";

const TermsModal = () => {
    const authUser = useSelector(state => state.authentication.user)
    const queryClient = useQueryClient()
    const dispatch = useDispatch();
    const {getGATrackofDetails} = useGATrackAPI();
    const {updateUser} = useUpdateUserDetails()

    const handleCloseModal = () => {
        updateUser({...authUser, terms_acceptance: true})
        document.querySelector('html').classList.remove('modal_overflow');
    }

    const {isSuccess, isLoading, isRefetching, isError} = useTermsAcceptanceAPI({
        onSuccess: (data) => {
            handleCloseModal()
        }
    });

    if (isError) {
        handleCloseModal()
    }

    document.querySelector('html').classList.add('modal_overflow');

    return (
        <div className='modal_pd modal modal_style active'>
            <div className="modal_terms modal_content width-full p-0">
                <div className="height_100vh">
                    <div className="p_20">
                        <h1><b>Terms of Use</b></h1>
                        <div>
                            <article className="article">
                                <hr/>
                                <div><u><a href="https://coffeemug.ai/talentmug/"
                                           target="_blank">https://coffeemug.ai/talentmug/</a></u>&nbsp;(the &ldquo;
                                    <strong>Sites</strong>&rdquo;), the Communication Services (as defined below) and
                                    other ancillary services (together, the &quot;<strong>Services</strong>&quot;)
                                </div>
                                <div>&nbsp;</div>
                                <div>By accessing, browsing, downloading, using, or registering to receive the Services,
                                    you acknowledge that you have read and understood these Terms of Use and that you
                                    accept and agree to be bound by them in full. If you do not agree to these Terms of
                                    Use, do not use any portion of the Services.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>Changes to the Terms of Use</b></div>
                                <div>We may update or modify these Terms of Use at any time. Any amendments are
                                    effective immediately upon the posting of the revised Terms of Use on the Sites. We
                                    may give notice of such updates and modifications by various means, including
                                    without limitation, by posting a revised version of these Terms of Use on the Sites,
                                    or providing notice on the Services. You should review the Terms of Use often to
                                    stay informed of any changes that may affect you. Your continued access or use of
                                    the Services thereafter signifies your acceptance to such changes.
                                </div>
                                <div>&nbsp;</div>
                                <div>The version of these Terms of Use posted on the Services on the respective date you
                                    visit the Services will be the Terms of Use applicable to your access and use of the
                                    Services on that date. We reserve the right to terminate these Terms of Use, or to
                                    refuse, restrict, or discontinue access to the Services (or any portions,
                                    components, or features thereof) to you or any other person or entity, for any
                                    reason or for no reason whatsoever, at any time, without notice or liability.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>Privacy Policy</b></div>
                                <div><u><a href="https://coffeemug.ai/privacy-policy/"
                                           target="_blank">https://coffeemug.ai/privacy-policy/</a></u> applies to your
                                    use of the Services.
                                    We encourage you to read and familiarize yourself with the Privacy Policy, which
                                    explains how we process your personal information.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>Password Accounts, Passwords, and Security</b></div>
                                <div>In order to access the Services, you would need to register by providing us with
                                    current, complete and accurate information as prompted by the registration process.
                                    The system might also permit the user to login using a third party account, for
                                    instance user&rsquo;s LinkedIn or Google account, Apple account.
                                </div>
                                <div>&nbsp;</div>
                                <div>You acknowledge and agree that you are not permitted to create more than one
                                    account on the Services. You agree to: (a) provide true, accurate, current and
                                    complete information about yourself as prompted by the registration process
                                    mechanism (the &quot;<strong>Registration Data</strong>&quot;) and (b) maintain and
                                    promptly update the Registration Data to keep it true, accurate, current and
                                    complete. If you provide any information that is untrue, inaccurate, or incomplete,
                                    or TalentMug has reasonable grounds to suspect that such information is untrue,
                                    inaccurate, or incomplete, TalentMug has the right to limit, suspend or terminate
                                    your account and refuse any and all current or future use of the Services. You are
                                    entirely responsible for all activities that occur under your account. You agree to
                                    (a) notify us immediately at <a
                                        href="mailto:contact@talentmug.ai">contact@talentmug.ai</a> of any unauthorized
                                    use of your
                                    account or any other breach of security of which you become aware, and (b) exit
                                    completely from your account at the end of each online session. We will not be
                                    liable for any loss that you may incur as a result of someone else using your
                                    password or account, either with or without your knowledge. However, you could be
                                    held liable for losses incurred by us or another party due to any unauthorized use
                                    of your account or password. You may not use anyone else&#39;s account at any time
                                    without the permission of the account holder, or sell, resell, rent, lease, share or
                                    provide access to your account to anyone else. We reserve all available legal rights
                                    and remedies to prevent unauthorized use of the Services. You understand that you
                                    may voluntarily terminate your use of and delete your account from the Services by
                                    sending us a request at&nbsp;<a
                                        href="mailto:contact@talentmug.ai">contact@talentmug.ai</a>.
                                </div>
                                <div>&nbsp;</div>
                                <div>In consideration of your access to and use of the Services, you represent that you
                                    have attained the minimum age of contractual capacity and are not barred from
                                    accessing or using the Services under applicable laws. Only individuals who are of
                                    legal age to form a binding contract under applicable laws are permitted to create
                                    an account or register to use the Services.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>The TalentMug Mobile App</b></div>
                                <div>You must have a compatible mobile device to access the App. TalentMug does not
                                    warrant that the App will be compatible with your mobile device.
                                </div>
                                <div>&nbsp;</div>
                                <div>If you access the App through the Apple Store, or the Google Play Store, Apple Inc.
                                    or Google, Inc., and their subsidiaries, respectively, shall be a third-party
                                    beneficiary to this contract and you agree that these third-party beneficiaries have
                                    the right to enforce these Terms of Use against you. However, these third-party
                                    beneficiaries are not a party to these Terms of Use and are not responsible for the
                                    provision or support of the App. You agree that your access to the App shall also be
                                    subject to the provisions in the applicable third-party beneficiary&rsquo;s terms of
                                    service (&ldquo;<strong>App Store Terms</strong>&rdquo;).
                                </div>
                                <div>&nbsp;</div>
                                <div>The App may automatically download and install updates from time to time or require
                                    the downloading and installation of updates or new versions for continued use. You
                                    acknowledge that in some instances updates and/or new versions may reduce or remove
                                    features and functionality in prior versions of the App. We may also restrict,
                                    modify or limit your access and use of certain features or functionalities depending
                                    on the territory in which you are located.
                                </div>
                                <div>&nbsp;&nbsp;</div>
                                <div><b>License Grant</b></div>
                                <div>Subject to your compliance with the Terms of Use, TalentMug grants you a revocable,
                                    personal, non-transferable, non-exclusive and non-sub licensable limited right and
                                    license to:
                                </div>
                                <ul>
                                    <li>Download and install the App on your device as permitted by the App Store
                                        Terms;
                                    </li>
                                    <li>Access and use the Services solely for your personal use.</li>
                                </ul>
                                <div>&nbsp;</div>
                                <div>As a condition for your access and use of the Services, you will not (and will not
                                    allow any third party to):
                                </div>
                                <ul>
                                    <li>Copy, modify, adapt, translate, data mine, decompile, disassemble or make
                                        derivative works from, reverse engineer, reverse assemble, or otherwise attempt
                                        to derive any source code from the Services;
                                    </li>
                                    <li>Sell, assign, sublicense, grant a security interest in, or otherwise transfer
                                        any right in or access to the Services or access to any products, information,
                                        or other content available therein; or
                                    </li>
                                </ul>
                                <div>&nbsp;</div>
                                <div><b>You agree not to:</b></div>
                                <div>&nbsp;</div>
                                <ul>
                                    <li>Use or launch any manual or automated software, devices or other processes
                                        (including robots, spiders, scrapers, crawlers, or offline readers) that access
                                        the services in a manner that sends more messages, requests, information, or
                                        content in a given period of time than can be reasonably performed by a human
                                        being;
                                    </li>
                                    <li>Modify the Services or any part thereof, in any form or manner, nor to use any
                                        modified versions of the Services or other TalentMug products, software, or
                                        other content in order to gain unauthorized access to the Services, or for any
                                        reason whatsoever, without the express written consent of TalentMug; &nbsp;or
                                    </li>
                                    <li>Attempt to gain any unauthorized access to the Services or any of its associated
                                        content, including computer systems, software, or networks.
                                    </li>
                                </ul>
                                <div>&nbsp;&nbsp;</div>
                                <div>TalentMug reserves the right, in its sole discretion, to restrict, suspend or
                                    terminate your access to the Services or any portion thereof at any time, without
                                    notice.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>No Unlawful or Prohibited Use</b></div>
                                <div>As a condition of your use of the Services, you agree that you will not use the
                                    Services for any purpose that is unlawful or prohibited by these Terms of Use. You
                                    also agree to reimburse us for any damage, loss, cost or expense we incur, including
                                    any legal fees, because of your use of the Services for an unlawful or prohibited
                                    purpose.
                                </div>
                                <div>&nbsp;</div>
                                <div>You may not use the Services in any manner which could damage, disable, overburden,
                                    or impair the Services or interfere with any other party&#39;s use and enjoyment of
                                    the Services. Furthermore, you may not probe, scan, test the vulnerability of, or
                                    breach any security or authentication measures of the Services. You may not obtain
                                    or attempt to obtain any materials or information through any means not
                                    intentionally made available or provided for through the Services. Also, you may not
                                    register or attempt to register, any party for use or access to the Services, unless
                                    expressly authorized by such party to do so.
                                </div>
                                <div>&nbsp;</div>
                                <div>The Services may contain profiles, news feeds, chat areas, news groups, forums,
                                    communities, personal pages, and/or other message or communication facilities
                                    designed to enable you to communicate with a specified group or generally
                                    (collectively, &quot;<strong>Communication Services</strong>&quot;), you agree to
                                    use the Communication Services only to post, send and receive messages and content
                                    that are appropriate and related to the particular Communication Service. By way of
                                    example, and not as a limitation, you agree that when using a Communication Service,
                                    you will not:
                                </div>
                                <div>&nbsp;</div>
                                <ul>
                                    <li>Defame, abuse, harass, stalk, threaten or otherwise violate the legal interests
                                        and rights (such as rights of privacy or publicity) of others;
                                    </li>
                                    <li>Publish, post, upload, download, distribute or disseminate any inappropriate,
                                        profane, defamatory, infringing, obscene, indecent or unlawful content;
                                    </li>
                                    <li>Upload, post, transmit, or otherwise make available any content that infringes
                                        any patent, trademark, trade secret, copyright, or other proprietary right of
                                        any party;
                                    </li>
                                    <li>Upload, post, email, transmit, or otherwise make available any material or
                                        content that contains viruses, or any computer code, files, or programs designed
                                        to interrupt, destroy, or limit the functionality of the Services or any
                                        software or computer hardware or other telecommunications apparatus or otherwise
                                        damage the property of another entity or person;
                                    </li>
                                    <li>Download any file posted by another user of a Communication Service that you
                                        know, or reasonably should know, cannot be legally distributed in such manner;
                                    </li>
                                    <li>Falsify or delete any author attributions, legal or other proper notices or
                                        proprietary designations or labels of the origin or source of software or other
                                        material contained in a file that is uploaded;
                                    </li>
                                    <li>Restrict or inhibit any other user from using and enjoying the Communication
                                        Services;
                                    </li>
                                    <li>Violate any code of conduct or other guidelines which may be applicable for any
                                        particular Communication Service;
                                    </li>
                                    <li>Harvest or otherwise collect information about others, including phone numbers
                                        or e-mail addresses, without their consent;
                                    </li>
                                    <li>Contravene any applicable laws or regulations;</li>
                                    <li>Harm minors in any way;</li>
                                    <li>Impersonate any person or entity, or falsely state or otherwise misrepresent
                                        your affiliation with a person or entity;
                                    </li>
                                    <li>Stalk or otherwise harass another person;</li>
                                    <li>Use the Services to conduct pyramid schemes, multi-level marketing or similar
                                        solicitations;
                                    </li>
                                    <li>Use any robot, spider, scraper, or other automated or manual means to access the
                                        Services, or copy any Services Content (as defined below) or information
                                        thereon; or
                                    </li>
                                    <li>Delete or revise any content posted by another person or entity without their
                                        express consent, unless such activity is expressly permitted by the Service
                                        used.
                                    </li>
                                </ul>
                                <div>&nbsp;</div>
                                <div>TalentMug has no obligation to monitor the Communication Services, and has no
                                    responsibility for the conduct of other users or any User-Generated Content (as
                                    defined below). However, TalentMug reserves the right to review materials posted to
                                    a Communication Service and to remove any materials in its sole discretion.
                                    TalentMug reserves the right to terminate your access to any or all of the
                                    Communication Services at any time without notice for any reason whatsoever. For
                                    clarity, the &quot;Communication Services&quot; are included as part of
                                    the &quot;Services.&quot;</div>
                                <div>&nbsp;</div>
                                <div>TalentMug reserves the right at all times to disclose any information as necessary
                                    to satisfy any applicable law, regulation, legal process or governmental request, or
                                    to edit, refuse to post or to remove any information or materials, in whole or in
                                    part, in TalentMug&rsquo;s sole discretion.
                                </div>
                                <div>&nbsp;</div>
                                <div>Always use caution when giving out any personally identifying information about
                                    yourself via the Services. TalentMug does not control or endorse the content,
                                    messages or information posted or sent via the Communication Service and, therefore,
                                    TalentMug specifically disclaims any liability with regard to the Communication
                                    Services and any liability resulting or arising from your use of any Communication
                                    Service.
                                </div>
                                <div>&nbsp;</div>
                                <div>Materials uploaded to a Communication Service may be subject to limitations on
                                    usage, reproduction and/or dissemination. You are responsible for adhering to such
                                    limitations if you download the materials.
                                </div>
                                <div>&nbsp;</div>
                                <div>TalentMug does not own the materials you provide to TalentMug (including feedback
                                    and suggestions) or post, upload, input or submit via the Services (&ldquo;<strong>User-Generated
                                        Content</strong>&rdquo;), and you may request deletion of any such
                                    User-Generated Content at any time, unless such content has been shared with, or
                                    copied and stored by other users of the Services. However, by posting, uploading,
                                    inputting, providing or submitting such User-Generated Content, you are granting
                                    TalentMug a nonexclusive, irrevocable, worldwide, sub licensable, perpetual,
                                    unlimited, assignable, fully paid up and royalty-free right to TalentMug to copy,
                                    publish, prepare derivative works of, distribute, process, analyze, use and
                                    commercialize, in any way now known or in the future discovered, such User-Generated
                                    Content. This includes publishing or featuring your profile, introduction, feedback
                                    and other User-Generated Content on our Services or otherwise making it available to
                                    the public without compensation to you.
                                </div>
                                <div>&nbsp;</div>
                                <div>All User-Generated Content posted, uploaded, inputted, or submitted by you via the
                                    Services is at your own risk and you hereby represent and warrant that you have the
                                    full legal right to use the User-Generated Content in such a manner and that it is
                                    not confidential or proprietary to any third party, nor are you using it in
                                    violation of any applicable law or contractual restriction.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>Copyright and Proprietary Rights</b></div>
                                <div>Unless expressly provided otherwise in these Terms of Use, the Services are the
                                    property of TalentMug and are protected by applicable laws. All copyright,
                                    trademark, patent, and other proprietary rights in the Services and in the software,
                                    text, graphics, design elements, audio, music, and all other materials contributed
                                    or used by TalentMug in the development and provision of the Services (&quot;
                                    <strong>Services Content</strong>&quot;) are reserved to TalentMug and any other
                                    owners who have granted TalentMug the right and license to use such Services
                                    Content. You may not reproduce, upload, post, transmit, download, or distribute any
                                    part of the Services Content or information accessed at other sites through links
                                    made at the Services (&quot;<strong>Third Party Sites</strong>&quot;).
                                </div>
                                <div>&nbsp;</div>
                                <div>You may not modify the Services, Third Party Sites, or any material residing on
                                    such sites, except as specifically instructed or allowed in such sites. You shall
                                    observe copyright and other restrictions imposed by Third Party Sites. You may not
                                    use the Services or Third Party Sites in any manner that infringes on the rights of
                                    any person or entity.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>Indemnification</b></div>
                                <div>You agree to indemnify, defend and hold TalentMug and its affiliates harmless from
                                    and against all claims, demands, suits or other proceedings, and all resulting loss,
                                    damage, liability, cost, and expense (including attorneys&#39; fees), brought by any
                                    third party in connection with or arising out of User-Generated Content that you
                                    submit, post to, or transmit through the Services, your access to and use of the
                                    Services, your violation of these Terms of Use, your violation of any applicable
                                    law, regulation or code, or any third party rights or interests. We reserve, and you
                                    grant to us, the right to assume exclusive defense and control of any matter subject
                                    to indemnification by you hereunder. All rights and duties of indemnification that
                                    are set forth herein shall survive termination of these Terms of Use.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>Liability Disclaimer</b></div>
                                <div>THE INFORMATION, SOFTWARE, PRODUCTS, AND CONTENT PUBLISHED ON THE SERVICES MAY
                                    INCLUDE INACCURACIES OR ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION ON
                                    THE SERVICES. TALENTMUG, OUR PARTNERS, AFFILIATES, AND/OR OUR SUPPLIERS AND
                                    LICENSORS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SERVICES AT ANY TIME.
                                </div>
                                <div>&nbsp;</div>
                                <div>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS, TALENTMUG, OUR PARTNERS, OUR
                                    AFFILIATES, AND/OR OUR RESPECTIVE SUPPLIERS AND LICENSORS PROVIDE THE SERVICES ON
                                    AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS, MAKE NO REPRESENTATIONS
                                    OR WARRANTIES ABOUT THE SERVICES, INCLUDING SUITABILITY OF THE INFORMATION,
                                    SOFTWARE, PRODUCTS, AND SERVICES CONTAINED ON OR OBTAINED THROUGH THE PLATFORM FOR
                                    ANY PURPOSE, OR THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. TO THE
                                    FULLEST EXTENT PERMITTED BY APPLICABLE LAWS, TALENTMUG, OUR PARTNERS, OUR
                                    AFFILIATES, AND/OR OUR RESPECTIVE SUPPLIERS AND LICENSORS HEREBY DISCLAIM ALL
                                    REPRESENTATIONS AND WARRANTIES IMPLIED BY STATUTE WITH REGARD TO THE SERVICES,
                                    INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
                                    PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. TALENTMUGAND OUR PARTNERS MAKE
                                    EVERY EFFORT TO PROTECT YOUR SECURITY, BUT, BECAUSE THE INTERNET IS NOT A SECURE
                                    MEDIUM, NEITHER TALENTMUG, NOR OUR PARTNERS, AFFILIATES, OR SUPPLIERS MAKE ANY
                                    REPRESENTATION OR WARRANTY THAT ANY INFORMATION TRANSMITTED OR TRANSACTION CONDUCTED
                                    THROUGH THE SERVICES WILL NECESSARILY BE SECURE.
                                </div>
                                <div>&nbsp;</div>
                                <div>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS, IN NO EVENT SHALL TALENTMUG,
                                    OUR PARTNERS, OUR AFFILIATES, AND/OR OUR RESPECTIVE SUPPLIERS AND LICENSORS BE
                                    LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL
                                    DAMAGES (INCLUDING LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, LOSS OF GOODWILL OR
                                    DAMAGE TO REPUTATION, LOSS OF DATA) ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE
                                    USE OF THE SERVICES OR WITH THE DELAY OR INABILITY TO USE THE SERVICES, OR FOR ANY
                                    INFORMATION, SOFTWARE, PRODUCTS AND SERVICES OBTAINED THROUGH THE SERVICES, OR
                                    OTHERWISE ARISING OUT OF THE USE OF THE SERVICES, WHETHER BASED ON CONTRACT, TORT,
                                    STRICT LIABILITY, OR OTHERWISE, EVEN IF ANY OF TALENTMUG, OUR PARTNERS, OUR
                                    AFFILIATES, AND/OR OUR RESPECTIVE SUPPLIERS AND LICENSORS HAS BEEN ADVISED OF THE
                                    POSSIBILITY OF DAMAGES.
                                </div>
                                <div>&nbsp;</div>
                                <div>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS, IN NO EVENT WILL THE AGGREGATE
                                    LIABILITY OF TALENTMUG, OUR PARTNERS, OUR AFFILIATES, AND OUR RESPECTIVE SUPPLIERS
                                    AND LICENSORS FOR ANY DAMAGES ARISING OUT OF OR RELATED TO ANY DEFAULT HEREUNDER,
                                    REGARDLESS OF THE FORM OF THE ACTION, EXCEED THE AMOUNT OF FEES ACTUALLY PAID BY YOU
                                    FOR USE OF THE SERVICES, IF ANY.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>General uses/ policies</b></div>
                                <div><u>Choice of Law; Venue:</u> These Terms of Use, as well as the Privacy Policy
                                    referenced herein, shall be governed by, and construed and enforced in accordance
                                    with, the laws of India without regard to its conflicts of laws principles. The
                                    United Nations Convention on Contracts for the International Sale of Goods is
                                    expressly disclaimed. You hereby consent to this choice of law for all disputes
                                    arising out of or relating to these Terms of Use and/or to the use of the Services.
                                    The use of the Services is unauthorized in any jurisdiction that does not give
                                    effect to all provisions of these terms and conditions, including without limitation
                                    this paragraph.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>No Joint Venture</u>: You agree that no joint venture, partnership, employment,
                                    or agency relationship exists between you and TalentMug, our Partners, or any of our
                                    affiliates or suppliers as a result of these Terms of Use or use of the Services.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Subject to Law</u>: These Terms of Use are subject to applicable laws, and
                                    nothing contained in these Terms of Use is in derogation of our right to comply with
                                    law enforcement requests or requirements relating to your use of the Services or
                                    information provided to or gathered by us with respect to such use.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Severability</u>: If any part of these Terms of Use is determined to be invalid
                                    or unenforceable pursuant to applicable laws including, but not limited to, the
                                    warranty disclaimers and liability limitations set forth above, then the invalid or
                                    unenforceable provision will be deemed superseded by a valid, enforceable provision
                                    that most closely matches the intent of the original provision and the remainder of
                                    these Terms of Use, or the referenced Privacy Policy, shall continue in effect.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Waiver</u>: Any waiver (express or implied) by either party of any breach of
                                    these Terms of Use, or the Privacy Policy referenced herein, shall not constitute a
                                    waiver of any other or subsequent breach.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Entire Agreement:</u> These Terms of Use and any additional agreements entered
                                    into between you and TalentMug, constitute the entire agreement between you and us
                                    with respect to the Services and it supersedes any and all prior or contemporaneous
                                    communications and proposals, whether electronic, oral, or written, between you,
                                    TalentMug, and/or our Partners with respect to the Services, including prior
                                    versions of these Terms of Use and the associated Privacy Policy. A printed version
                                    of these Terms of Use and of any notice given in electronic form shall be admissible
                                    in judicial or administrative proceedings based upon or relating to these Terms of
                                    Use to the same extent and subject to the same conditions as other business
                                    documents and records originally generated and maintained in printed form.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Modification Right</u>: We reserve the right, at any time, to modify, alter, or
                                    update these Terms of Use. If we do so, we will post such modifications,
                                    alterations, or updates on the Services, and your continued use of the Services will
                                    constitute acceptance of such modifications, alterations, or updates.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Reservation of Rights</u>: Any rights not expressly granted herein are reserved.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>No Editorial Control of Third Party Content; No Statement as to
                                    Accuracy</u>: &ndash; To the extent that any of the content included in the Services
                                    is provided by third party content providers or user, we have no editorial control
                                    or responsibility over such content. Therefore, any User-Generated Content or
                                    opinions, statements, products, services or other information expressed or made
                                    available by third parties on the Services are those of such users or third parties.
                                    We do not represent or endorse the accuracy or reliability of any opinion, statement
                                    or other information provided by any users or third parties, or represent or warrant
                                    that your use of such content or referenced content or service providers will not
                                    infringe rights of third parties not owned by or affiliated with us.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Links to Third Party Sites</u>: The Services may contain hyperlinks to Third
                                    Party Sites owned and operated by parties other than us. We do not control such
                                    Third Party Sites and cannot be held responsible for their content or accuracy and
                                    do not endorse these Third Party Sites unless we specifically so state. You
                                    acknowledge and agree that we are not responsible for and are not liable for the
                                    content, products, services or other materials on or available from such Third Party
                                    Sites. Any such Third Party Sites are likely to set forth specific terms of use and
                                    privacy policies that you should review. We are under no obligation to maintain any
                                    link on the Services and may remove a link at any time in our sole discretion for
                                    any reason whatsoever. We shall not be responsible or liable, directly or
                                    indirectly, for any damages or losses caused or alleged to be caused by or in
                                    connection with the use of or reliance on such content, products, services or other
                                    materials available on or through any such Third Party Sites. We are not responsible
                                    for the privacy practices of any Third Party Sites.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Disclaimer</u>: Content and other information contained on the Services has been
                                    prepared by us as a convenience to its users and is not intended to constitute
                                    advice or recommendations upon which a user may rely. We have used reasonable
                                    efforts in collecting, preparing and providing quality information and material, but
                                    we make no warranties about the accuracy, completeness, or adequacy of the content
                                    or other information contained in or linked to the Services or any other website
                                    maintained by us. Users relying on content or other information from the Services do
                                    so at their own risk.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Notices:</u> Any notices to you from us regarding Services or these Terms of Use
                                    will be posted on the Services, provided via Communication Services, or made by
                                    email.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Electronic Communications:</u> When you visit the Services or send emails or
                                    other messages via the Services to us, you are communicating with us electronically.
                                    You consent to receive communications from us electronically. We will communicate
                                    with you by e-mail, WhatsApp, SMS, phone call or by posting notices on the Services.
                                    You agree that all agreements, notices, disclosures and other communications that we
                                    provide to you electronically, including those provided via the Services, satisfy
                                    any legal requirement that such communications be in writing. You further agree that
                                    any notices provided by us electronically are deemed to be given and received on the
                                    date we transmit any such electronic communication as described in these Terms of
                                    Use.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Partners</u>: For purposes of these Terms of Use, and the Privacy Policy
                                    referenced herein, &quot;<strong>Partner</strong>&quot; shall mean any person or
                                    company with whom TalentMug has entered into a legal arrangement for joint provision
                                    of or shared responsibility for the Services.
                                </div>
                                <div>&nbsp;</div>
                                <div><u>Remedies:</u> You agree that any violation, or threatened violation, by you of
                                    these Terms of Use, or the referenced Privacy Policy, may cause us irreparable and
                                    unquantifiable harm. You also agree that monetary damages would be inadequate for
                                    such harm and consent to our obtaining any injunctive or equitable relief that we
                                    deem necessary or appropriate. These remedies are in addition to any other remedies
                                    we may have at law or in equity.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>General Obligations of the Users:</b></div>
                                <div>By using the web application, Chrome extension, and/or API access, you are bound by
                                    these Terms of Service along with the following specific terms:
                                </div>
                                <div>&nbsp;</div>
                                <ul>
                                    <li><u>Damage and Liability</u></li>
                                </ul>
                                <div>You acknowledge and agree that TalentMug will not be held liable for any direct,
                                    indirect, incidental, special, consequential, or exemplary damages, including but
                                    not limited to loss of profits, goodwill, use, data, or other intangible losses
                                    (even if TalentMug has been advised of the possibility of such damages). These
                                    damages may result from your use of the service or third-party products that access
                                    data.
                                </div>
                                <div>&nbsp;</div>
                                <ul>
                                    <li><u>Rate Limiting</u></li>
                                </ul>
                                <div>Excessive or abusive requests to TalentMug may lead to the temporary or permanent
                                    suspension of your account&#39;s access. The determination of abuse or excessive
                                    usage will be made at TalentMug&rsquo;s sole discretion. TalentMug may, at its sole
                                    discretion, send an email warning to the account owner prior to or after the
                                    suspension.
                                </div>
                                <div>&nbsp;</div>
                                <ul>
                                    <li><u>Discontinuation</u></li>
                                </ul>
                                <div>TalentMug reserves the right to modify or discontinue your access, temporarily or
                                    permanently, with or without notice, at any time.
                                </div>
                                <div>&nbsp;</div>
                                <ul>
                                    <li><u>Unacceptable Behavior</u></li>
                                </ul>
                                <div>We do not tolerate certain behaviors from our users and/or clients. It is important
                                    for users to be aware that engaging in these behaviors can cause harm to third
                                    parties and/or directly to TalentMug. Therefore, you agree to refrain from engaging
                                    in such behaviors within the platform. Specifically, the following actions are
                                    strictly prohibited:
                                </div>
                                <div>&nbsp;</div>
                                <div>The following actions are strictly prohibited and considered unacceptable within
                                    the TalentMug platform:
                                </div>
                                <div>&nbsp;</div>
                                <div className='terms-bullets'>
                                    <ol>
                                        <li>Reselling TalentMug services without proper authorization, as well as
                                            engaging
                                            in any commercial use of information extracted from our platform.
                                        </li>
                                        <li>Using the platform for purposes related to child pornography, child abuse,
                                            or
                                            any form of maltreatment affecting children, their families, or any third
                                            party.
                                        </li>
                                        <li>Transmitting any material (via email, comments, web space, or any other
                                            means)
                                            that threatens, encourages bodily harm, or promotes the destruction of
                                            property
                                            or harm to individuals.
                                        </li>
                                        <li>Transmitting explicit adult or pornographic content, such as explicit sex
                                            scenes
                                            or full nudity.
                                        </li>
                                        <li>Harassing other users or third parties through the transmission of offensive
                                            or
                                            abusive content.
                                        </li>
                                        <li>Inserting messages or advertisements without complying with legal
                                            requirements,
                                            or engaging in spamming behaviors by sending unsolicited messages.
                                        </li>
                                        <li>Impersonating others, including the illegal use of debit and/or credit
                                            information. This includes attempting to access other users&#39; accounts,
                                            penetrating TalentMug&rsquo;s security measures, and its software, the
                                            hardware
                                            of another entity, or electronic communication and telecommunications
                                            systems.
                                        </li>
                                        <li>Using disrespectful language, content, or graphics that infringe upon the
                                            rights
                                            of other clients, users, or third parties.
                                        </li>
                                        <li>Using material that infringes upon the intellectual or industrial property
                                            rights of other clients, users, or third parties, such as trademarks, trade
                                            names, slogans, pictures, or content, without proper authorization or
                                            license.
                                        </li>
                                        <li>Collecting or attempting to collect personal information of third parties
                                            without complying with data protection laws.
                                        </li>
                                        <li>Engaging in activities that disrupt the ability of other individuals or
                                            systems,
                                            including &quot;denial of service&quot; (DOS) attacks against network hosts
                                            or
                                            individual users.
                                        </li>
                                        <li>Engaging in deceptive activities that manipulate or mislead individuals,
                                            causing
                                            harm or injury.
                                        </li>
                                        <li>Engaging in excessive or abusive use of shared resources on the network,
                                            which
                                            negatively impacts other users or clients.
                                        </li>
                                        <li>Engaging in any other actions for unlawful purposes, actions that harm or
                                            violate the rights of others or TalentMug (including honor, dignity,
                                            integrity,
                                            and reputation), or actions that constitute crimes or criminal offenses.
                                        </li>
                                        <li>Propagating hate speech or prejudice against minorities, justifying crimes
                                            or
                                            human rights violations.
                                        </li>
                                        <li>Extracting information, decompiling, or processing the information obtained
                                            from
                                            our reports for the purpose of reselling or profiting from it. The use of
                                            our
                                            services and reports is intended for business analysis, project viability
                                            assessment, and investment evaluation, and should not be used in violation
                                            of
                                            these provisions.
                                        </li>
                                        <li>Engaging in scraping, spidering, or similar actions, even if you are a
                                            registered user. It is also prohibited to resell, transfer, or use our
                                            information or newsletter content for any commercial purpose without our
                                            consent.
                                        </li>
                                    </ol>
                                </div>
                                <div>&nbsp;</div>
                                <div><b>Disclaimer</b></div>
                                <div>TalentMug does not store any bank account related information or any credit / debit
                                    card details. TalentMug shall not be liable for any loss or damage sustained by user
                                    as a result of any disclosure (inadvertent or otherwise) of any information
                                    concerning the user&rsquo;s account, credit cards or debit cards in the course of
                                    any online transactions or payments made for any products and/or services offered
                                    through the Platform.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>Contact TalentMug about our Terms of Use</b></div>
                                <div>If you have any questions or comments about these Terms of Use or other TalentMug
                                    policies relating to use of the Services, please contact us
                                    at&nbsp;<a href="mailto:contact@talentmug.ai">contact@talentmug.ai</a>.
                                </div>
                                <div>&nbsp;</div>
                                <div><b>These Terms of Use were last updated and posted on 30th June 2023.</b></div>
                                <div>&nbsp;</div>
                            </article>
                        </div>
                    </div>
                    <div className="btn_sticky">
                        {/*<div className="terms_chekbox">*/}
                        {/*    <div className="checkbox_column">*/}
                        {/*        <div className="ck_type_1">*/}
                        {/*            <input type="checkbox"/>*/}
                        {/*            <label></label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="checkbox_column">*/}
                        {/*        <div className="ck_type_1">*/}
                        {/*            <input type="checkbox"/>*/}
                        {/*            <label></label>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <InputButton
                            className="textCenter"
                            variant="contained"
                            size="large"
                            onClick={() => {
                                getGATrackofDetails("terms acceptance")
                                queryClient.refetchQueries('AcceptTermsOfUse')
                            }}
                            type="submit"
                            color="warning">
                            Accept
                        </InputButton>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsModal;
