import {apiHit} from './helpers';

export const masterService = {
    masterData,
    relatedResult
};

function masterData(url, inputValue, params) {

    // const allUrls = ['skills', 'cities', 'seniority', 'experience', 'careerpath', 'industries', 'expertise', 'job-titles', 'companies'];
    const urlencoded = new URLSearchParams();
    if (inputValue) {
        urlencoded.append("search", inputValue)
    }
    if (params) {
        const paramsKeys = Object.keys(params)

        for (const key in paramsKeys) {
            const param = paramsKeys[key]
            urlencoded.append(param, params[param])
        }
    }
    return apiHit(url, urlencoded)
}

function relatedResult(url, inputValue) {
    const urlencoded = new URLSearchParams();
    if (inputValue) {
        if(Array.isArray(inputValue)){
            for (let arrayKey in inputValue) {
                urlencoded.append("search[]", inputValue[arrayKey])
            }
        } else {
            urlencoded.append("search[]", inputValue)
        }
    }

    return apiHit(url, urlencoded).then(user => {
        return user;
    })
}