import React from "react";
import InputButton from "./partials/components/InputButton";
export default function DeleteConfirmation({ message, onConfirm, setDetails, detailsInitialState, isLoading }) {

    const [showDialog, setShowDialog] = React.useState(true);
    function handleConfirmClick() {
        onConfirm();
    }

        return (
            <>
                {showDialog && (
                    <div className="modal modal_style default_modal">
                        <div className="modal_content">
                            <button className="close" onClick={() => {
                                setShowDialog(false)
                                setDetails(detailsInitialState)
                            }}>X</button>
                            <h4>Are you sure?</h4>
                            <p>{message}</p>
                            <ul className="modal_footer">
                                <li>
                                    <a onClick={() => {
                                        setShowDialog(false)
                                        setDetails(detailsInitialState)}
                                    } className="btn btn_fill fill_btn text_dec">Cancel</a>
                                </li>
                                <li>
                                    <InputButton
                                        type="submit"
                                        className='btn btn_fill fill_btn text_dec'
                                        onClick={handleConfirmClick}
                                        isLoading={isLoading}
                                        > Delete
                                    </InputButton>
                                </li>
                            </ul>
                        </div>
                    </div>
                )}
            </>
        );
}
