import {useQuery} from "react-query";
import {candidateService} from "../../../../redux/services";

const downloadProfile = async ({queryKey}) => {
    const [_key, {candidate}] = queryKey
    return await candidateService.downloadProfile({candidate});
};

export default function useDownloadCandidateProfileAPI({candidate, enabled, onSuccess}) {
    return useQuery(["downloadProfile", {candidate}], downloadProfile, {
        refetchOnWindowFocus: false, enabled: enabled, onSuccess: onSuccess
    });
}
