import {useMutation, useQueryClient} from "react-query";
import {useDispatch} from "react-redux";
import {searchServices} from "../../../redux/services";
import {alertActions} from "../../../redux/actions";
const updateFilter = async (input) => {
    return await searchServices.updateFilter(input.data, input.prefix);
}

export default function useUpdateFilterAPI() {
    const queryClient = useQueryClient()
    const dispatch = useDispatch();

    return useMutation(updateFilter, {
        onSuccess: (data, variables) => {

            if (variables?.id) {
                queryClient.setQueryData(['userData', {project_id: variables.id} ], data)
            }

            if(variables?.data?.search_id) {
                queryClient.refetchQueries('savedSearch')
            }

            dispatch(alertActions.success(data.status));
        },
        onError: (error, variables, context) => {
            console.log(error, variables, context)
        }
    })
}