import React from 'react';
import PropTypes from "prop-types";
import {DOTS, usePagination} from "../hooks/usePagination";

const RECORD_PER_PAGE = 10;

function Paginate(props) {
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    const {totalRecords, nextPageNum, getNextPage} = props
    let {recordPerPage, recordsPerPage} = props
    if (recordsPerPage) {
        recordPerPage = recordsPerPage;
    }
    if (!recordPerPage) {
        recordPerPage = RECORD_PER_PAGE
    }
    const siblingVal =  isMobile ? 1 : 3 ;

    const totalPages = Math.ceil(totalRecords / recordPerPage);
    const currentPage = nextPageNum;
    const paginationRange = usePagination({
        totalCount: +totalRecords, pageSize: recordPerPage, siblingCount: totalPages > 9  ? siblingVal : 5, currentPage: +currentPage
    })

    if (!nextPageNum || !totalRecords) {
        return false
    }

    const generatePageNumbers = () => {

        return paginationRange?.map((pageNumber, i) => {

            if (pageNumber === DOTS) {
                return <li key={i}>{DOTS}</li>
            }

            const activeClass = +pageNumber === +currentPage ? 'pagination_active_page' : ''
            return <li className={`rp-link ${activeClass}`} key={i} onClick={goToPage(pageNumber)}>{pageNumber}</li>
        })
    }


    const changeNextPage = (e) => {
        const value = e.target.value;
        if (value > 0 && value <= totalPages) {
            getNextPage(e.target.value)
        } else {
            if (value < 1) {
                getNextPage(1)
            } else {
                getNextPage(totalPages)
            }
        }
    }

    const goToPage = (page) => {
        if (page === 'first') {
            page = 1;
        } else if (page === 'last') {
            page = totalPages;
        } else if (page < 1) {
            page = 1;
        } else if (page > totalPages) {
            page = totalPages;
        }
        return (e) => {
            e.preventDefault();
            getNextPage(page);
        }
    }

    return (<div className="candidates_header footer_pagination_wrapper">
        <div className="pagination_area">
            <div className="page_no">
                        <span className={currentPage > 1 ? 'rp-link pagination_active_page' : 'rp-link'}
                              onClick={goToPage(1)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1067_8106)">
                                    <path d="M15.9526 7L10.9526 12L15.9526 17"
                                          stroke={currentPage > 1 ? '#d8232a' : '#ABABAB'} strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M6.47315 5.9823C6.73836 5.9823 6.99272 6.06539 7.18025 6.21328C7.36779 6.36118 7.47314 6.56177 7.47314 6.77093V17.2551C7.47314 17.4643 7.36779 17.6648 7.18025 17.8127C6.99272 17.9606 6.73836 18.0437 6.47315 18.0437C6.20793 18.0437 5.95357 17.9606 5.76604 17.8127C5.5785 17.6648 5.47314 17.4643 5.47314 17.2551V6.77093C5.47314 6.56177 5.5785 6.36118 5.76604 6.21328C5.95357 6.06539 6.20793 5.9823 6.47315 5.9823V5.9823Z"
                                        fill={currentPage > 1 ? '#d8232a' : '#ABABAB'}/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1067_8106">
                                        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            First
                        </span>
                <span className={currentPage > 1 ? 'rp-link pagination_active_page' : 'rp-link'}
                      onClick={goToPage(currentPage - 1)}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.5 7L9.5 12L14.5 17" stroke={currentPage > 1 ? '#d8232a' : '#ABABAB'}
                                      strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Prev
                        </span>
                <ul className="footer-pagination">
                    {generatePageNumbers()}
                </ul>
                <span className={totalPages === +currentPage ? 'rp-link' : 'rp-link pagination_active_page'}
                      onClick={goToPage(currentPage + 1)}>
                            Next
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 7L14.5 12L9.5 17"
                                      stroke={totalPages === +currentPage ? '#ABABAB' : '#d8232a'} strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                <span className={totalPages === +currentPage ? 'rp-link' : 'rp-link pagination_active_page'}
                      onClick={goToPage(totalPages)}>
                            Last
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1067_8160)">
                                        <path d="M8.04736 7L13.0474 12L8.04736 17"
                                              stroke={totalPages === +currentPage ? '#ABABAB' : '#d8232a'}
                                              strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path
                                            d="M17.5269 5.9823C17.2616 5.9823 17.0073 6.06539 16.8197 6.21328C16.6322 6.36118 16.5269 6.56177 16.5269 6.77093V17.2551C16.5269 17.4643 16.6322 17.6648 16.8197 17.8127C17.0073 17.9606 17.2616 18.0437 17.5269 18.0437C17.7921 18.0437 18.0464 17.9606 18.234 17.8127C18.4215 17.6648 18.5269 17.4643 18.5269 17.2551V6.77093C18.5269 6.56177 18.4215 6.36118 18.234 6.21328C18.0464 6.06539 17.7921 5.9823 17.5269 5.9823Z"
                                            fill={totalPages === +currentPage ? '#ABABAB' : '#d8232a'}/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1067_8160">
                                            <rect width="24" height="24" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                        </span>
            </div>
        </div>
    </div>)
}

Paginate.propTypes = {
    nextPageNum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    totalRecords: PropTypes.number,
    getNextPage: PropTypes.func,
}

export default Paginate