import {useMutation} from "react-query";
import {useDispatch} from "react-redux";

import {userService} from "../../../redux/services";
import {alertActions} from "../../../redux/actions";
import {userConstants} from "../../../redux/constants";

const logout = async () => {
    return await userService.logout()
}

export default function useLogoutAPI() {
    const dispatch = useDispatch();
    return useMutation(logout, {
        retry: 3,
        retryDelay: (retryCount) => ((retryCount+1) * 500),
        onSuccess: (data, variables) => {
            const user = JSON.parse(localStorage.getItem('user'))

            localStorage.setItem(`lastPage-${variables?.id ?? user?.id}`, `${window.location.pathname}${window.location.search}`)

            dispatch({type: userConstants.LOGOUT})

            localStorage.removeItem('user');
            localStorage.removeItem('api_token');

        }, onError: (error, variables, context) => {
            // console.log(error, variables, context)
            // dispatch({type: userConstants.LOGIN_FAILURE})
            // dispatch(alertActions.error(error.toString()));
        }
    })
}