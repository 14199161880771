import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Skeleton} from "@mui/material";
import useAddCandidateAPI from "../../candidates/partials/hooks/useAddCandidateAPI";
import SaveToPipeline from "../../candidates/partials/SaveToPipeline";
import {useParams} from "react-router";
import useRemoveCandidateAPI from "../../candidates/partials/hooks/useRemoveCandidateAPI";
import InputButton from "./InputButton";

const prevIcon = require('../../../assets/img/prev_icon.png');
const nextIcon = require('../../../assets/img/next_icon.png');

const RECORD_PER_PAGE = 10;

function Paginate(props) {

    const {
        totalRecords,
        title,
        type,
        nextPageNum,
        getNextPage,
        extra,
        isLoading,
        showFilterPanel
    } = props

    const projectId = useParams()
    const [saveToPipeline, setSaveToPipeline] = useState({showModal: false, candidateId: ''})
    const removeCandidateAPI = useRemoveCandidateAPI();
    const addCandidateAPI = useAddCandidateAPI();
    const paginateSkeleton = () => {
        return (
            <div className="candidates_header">
                <div className="all_select">
                    <h3>
                        <Skeleton variant="text" width={80}/>
                        &nbsp;
                        <Skeleton variant="text" width={100}/>
                    </h3>
                </div>
                <div className="pagination_area">
                    <div className="page_no">
                        <Skeleton variant="text" width={120}/>
                    </div>
                    <nav aria-label="...">
                        <ul className="pagination">
                            <li className="page-item rp-link">
                                <a className="page-link rp-link" tabIndex="-5">
                                    <Skeleton variant="rectangular" width={120}/>
                                </a>
                            </li>

                            <div className="pagination_input">
                                <Skeleton variant="text" width={100}/>
                            </div>

                        </ul>
                    </nav>
                </div>
            </div>
        );
    };

    if (isLoading) {
        return paginateSkeleton()
    }

    let {recordPerPage, recordsPerPage} = props

    if (recordsPerPage) {
        recordPerPage = recordsPerPage;
    }
    if (!recordPerPage) {
        recordPerPage = RECORD_PER_PAGE
    }
    const totalPages = Math.ceil(totalRecords / recordPerPage);

    const changeNextPage = (e) => {
        if (totalPages === 0) return
        const value = e.target.value;
        if (value > 0 && value <= totalPages) {
            getNextPage(value)
        } else {
            if (value < 1) {
                getNextPage(1)
            } else {
                getNextPage(totalPages)
            }
        }
    }

    const goToPage = (page) => {
        if (totalRecords === 0) return

        if (page === 'first') {
            page = 1;
        } else if (page === 'last') {
            page = totalPages;
        } else if (page < 1) {
            page = 1;
        } else if (page > totalPages) {
            page = totalPages;
        }
        getNextPage(page);
    }
    const handleSaveToPipeline = () => {
        if (props.pipeline) {
            removeCandidateAPI.mutate({
                project: projectId?.project_id,
                candidate_uuid: props.checkedCandidate,
                page: nextPageNum
            })
        } else {

            if (projectId?.project_id) {
                addCandidateAPI.mutate({
                    status: '1',
                    candidate_uuid: props.checkedCandidate,
                    project: projectId?.project_id,
                    stage: 'Uncontacted'
                })
            } else {
                setSaveToPipeline(prevState => ({
                    ...prevState,
                    showModal: true,
                    candidateId: props.checkedCandidate
                }))
            }
        }
    }

    const paginationArea = <div className="pagination_area">
        {props?.checkedCandidate?.length > 0 && (
            <div>
                {props?.pipeline ? (
                    <InputButton
                        className="btn btn_outline btn_sm"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={() => handleSaveToPipeline()}
                        isLoading={removeCandidateAPI.isLoading}
                    >
                        Remove From Pipeline
                    </InputButton>
                ) : (
                    <InputButton
                        className="btn btn_outline btn_sm"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        onClick={(e) => handleSaveToPipeline()}
                        isLoading={addCandidateAPI.isLoading}
                    >
                        Save to Pipeline
                    </InputButton>
                )}
            </div>
        )}

        {!props?.extra &&
            <div className="page_no">
                <span><b>{((nextPageNum - 1) * recordPerPage) + 1}-{(recordPerPage * nextPageNum > totalRecords) ? totalRecords : (recordPerPage * nextPageNum)}</b></span> Of <span><b>{totalRecords} </b></span>
                <strong>
                    {/*<ion-icon name="caret-down"></ion-icon>*/}
                </strong>
            </div>
        }
        <nav aria-label="...">
            <ul className="pagination">
                <li className="page-item rp-link">
                    <a className="page-link rp-link" tabIndex="-5"
                       onClick={() => goToPage('first')}><img
                        src={prevIcon}
                        alt=""/></a>
                </li>
                <li className="page-item rp-link">
                    <a className="page-link prev_link" tabIndex="-1"
                       onClick={() => goToPage(+nextPageNum - 1)}>
                        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.5 1L1.5 6L6.5 11" stroke={nextPageNum > 1 ? '#d8232a' : '#CCCCCC'}
                                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a>
                </li>
                <div className="pagination_input">
                    <input type="number" onChange={changeNextPage} value={nextPageNum}/> of {totalPages}
                </div>
                <li className="page-item rp-link">
                    <a className="page-link"
                       onClick={() => goToPage(+nextPageNum + 1)}>
                        <svg className="next_btn" width="8" height="12" viewBox="0 0 8 12" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 1L6.5 6L1.5 11" stroke={totalPages === +nextPageNum ? '#CCCCCC' : '#d8232a'}
                                  strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a>
                </li>
                <li className="page-item">
                    <a className="page-link"
                       onClick={() => goToPage('last')}><img src={nextIcon} alt=""/>
                    </a>
                </li>
            </ul>
        </nav>
        {extra}
    </div>
    return (<>
        {saveToPipeline.showModal &&
            <SaveToPipeline
                saveToPipeline={saveToPipeline}
                closeSaveToPipeline={() => setSaveToPipeline(prevState => ({
                    ...prevState,
                    showModal: false,
                    candidateId: ''
                }))}
                setCandidateSavedInPipeline={props.setCandidateSavedInPipeline}
                isPaginate={true}
            />
        }
        {type === 'footer-div' ?
            paginationArea :
            <>
                <div className={type === 'header' ? `candidates_header ${title === 'Target Companies Found' ? 'pl_20 hide_filter_mob rp_cb' : ''}` : 'bt_pagination'}>
                    {type === 'header' &&
                        <>
                            <div className="filter_toggle" onClick={() => {
                                showFilterPanel();
                            }
                            }>
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/images/filter-toggle.svg`}
                                    alt=""/>
                            </div>
                            <div className="all_select">
                                <h3>
                                    {title !== 'Similar Companies Found' && title !== 'Kaggle Details Found' &&
                                        <div className="ck_type_1 mr_18">
                                            <input type="checkbox" checked={props.isAllCandidateChecked}
                                                   onChange={(e) => {
                                                       props.handleCheckAll(!props.isAllCandidateChecked)
                                                   }}/>
                                            <label></label>
                                        </div>
                                    }
                                    <span className="fw">{props?.displayRecord ?? totalRecords}</span>&nbsp;
                                    <span dangerouslySetInnerHTML={{__html: title}}></span></h3>
                            </div>
                        </>
                    }
                    <div className="header_paginate">
                        {paginationArea}
                    </div>
                </div>
            </>
        }
    </>)
}

Paginate.propTypes = {
    nextPageNum: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    totalRecords: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
    getNextPage: PropTypes.func,
    extra: PropTypes.object,
    rightSection: PropTypes.object,
    recordsPerPage: PropTypes.object,
}

export default Paginate