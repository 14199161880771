import {useMutation, useQuery, useQueryClient} from "react-query";
import {candidateService} from "../../../redux/services";

const termsAcceptance = async () => {
    return await candidateService.termsAcceptance();
}

export default function useTermsAcceptanceAPI({onSuccess}) {

    return useQuery(["AcceptTermsOfUse"], termsAcceptance, {
        enabled: false, refetchOnWindowFocus: false, onSuccess: onSuccess, onError: (error) => {

        },
    });

}