import {useQuery} from "react-query";
import {candidateService} from "../../../../redux/services";

const getCandidateDetails = async ({queryKey}) => {
    const [_key, {uuid}] = queryKey
    return await candidateService.getCandidateDetails(uuid);
};

export default function useGetCandidateDetailsAPI(uuid, fetchData) {
    return useQuery([`fetchCandidateDetails-${uuid}`, {uuid}], getCandidateDetails, {
        enabled: fetchData,
        retry: false,
        refetchOnWindowFocus: false
    });
}