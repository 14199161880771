import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {useNavigate} from "react-router-dom";
import useAddCandidateAPI from "../hooks/useAddCandidateAPI";
import {Autocomplete, Card, CardContent, CircularProgress, TextField, Typography} from "@mui/material";
import {debounce} from "lodash";
import PropTypes from 'prop-types'
import useGetAllProjectAPI from "../../../Project/hooks/useGetAllProjectAPI";
import useRemoveCandidateAPI from "../hooks/useRemoveCandidateAPI";
import InputButton from "../../../partials/components/InputButton";
import DeleteConfirmation from "../../../DeleteConfirmation";
import useUpdateCandidateStageAPI from "../hooks/useUpdateCandidateStageAPI";
import useGATrackAPI from "../../../Project/hooks/useGATrackAPI";

const userDefaultImage = require('../../../../assets/img/default.png')

function CardHeader(props) {
    const {candidate, pipeline, highlight, showGithub, page, checkAll} = props;
    const ref = useRef()
    const navigate = useNavigate();
    const {project_id} = useParams();
    const loading = false;
    const addCandidateAPI = useAddCandidateAPI();
    const removeCandidateAPI = useRemoveCandidateAPI();
    const updateCandidateStageAPI = useUpdateCandidateStageAPI();
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inputSearchValue, setInputSearchValue] = useState('');
    const getAllProjectAPI = useGetAllProjectAPI({name: inputSearchValue}, !!inputSearchValue)
    const profileViewed = props.candidate?.recruitplus?.profile_viewed ?? false;
    const filters = props.filters;

    const seniorityLength = filters?.seniority?.length;

    if (getAllProjectAPI.status === 'success') {
        setOptions(getAllProjectAPI.data.projects);
        setInputSearchValue('')
    }

    const setSearchTextDebounced = useRef(debounce(searchText => setInputSearchValue(searchText), 1000)).current;

    const handleInputChange = (event, value) => {
        setInputValue(value)
        setSearchTextDebounced(value)
    }
    const {getGATrackofDetails} = useGATrackAPI();
    const getOptions = (option) => (`${option?.name ?? ''} ${option?.created_at ?? ''}`)

    const formatDate = (dateString) => {
        const options = {year: "numeric", month: "long", day: "numeric"}
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const [addCandidate, setAddCandidate] = useState({
        project: project_id ?? '', status: ''
    });

    const [state, setState] = useState({
        pipelineClass: 'custom_dropbox', isPipelineMenuOpen: false, showComponent: 'default', error: ''
    })

    const availabilityScore = candidate.recruitplus?.availability_score ?? 0;
    let availabilityVal = candidate.recruitplus?.availability_level ?? 'Low';
    if (availabilityVal === '') {
        availabilityVal = 'Low';
    }
    /*    if (availabilityScore <= 3) {
            availabilityVal = 'Low'
        } else if (availabilityScore <= 7) {
            availabilityVal = 'Medium'
        } else if (availabilityScore <= 10) {
            availabilityVal = 'High'
        }*/
    const candidate_uuid = candidate.recruitplus?.uuid;


    useEffect(() => {
        const checkIfClickedOutside = e => {
            if (state.isPipelineMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setState(prevState => ({...prevState, pipelineClass: 'custom_dropbox', isPipelineMenuOpen: false}))
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [state.isPipelineMenuOpen])


    /*if (addCandidateAPI.status === 'success') {
        setState(prevState => ({...prevState, showComponent: 'success'}))
    }
    if (addCandidateAPI.status === 'error') {
        setState(prevState => ({...prevState, error: addCandidateAPI.error, showComponent: 'error'}))
        addCandidateAPI.reset()
    }*/

    const similarProfile = (userId) => {
        getGATrackofDetails("Similar Profile")
        const url = `${process.env.REACT_APP_BASENAME}/similar-candidate/${userId}${project_id ? `/project/${project_id}` : ''}`;
        window.open(url, '_blank');
    }

    const handleChange = (e) => {
        const {id, value} = e.target;
        setAddCandidate(prevState => ({...prevState, [id]: value}));
    }

    /*const handleSubmit = (e) => {
        e.preventDefault();
        setState(prevState => ({...prevState, pipelineClass: 'custom_dropbox'}))
        return (addCandidateAPI.mutate({...addCandidate, candidate_uuid}))
    }*/

    const openJsonPopupFun = (event, type) => {
        if (type === 'removeCandidate') {
            removeCandidateAPI.mutate({project: project_id, candidate_uuid: [candidate_uuid], page})
        } else if (type === 'pipeline') {
            if (project_id) {
                addCandidateAPI.mutate({
                    status: '1', candidate_uuid: [candidate_uuid], project: project_id, stage: 'Uncontacted'
                })
            } else {
                props.openSaveToPipeline(candidate_uuid)
                // setState(prevState => ({
                //     ...prevState,
                //     pipelineClass: 'custom_dropbox active',
                //     isPipelineMenuOpen: !(state.isPipelineMenuOpen)
                // }))
            }
        } else if (type === 'closePipeline') {
            setState(prevState => ({...prevState, pipelineClass: 'custom_dropbox'}))
        } else if (type === 'json') {
            props.openJsonPopup(event, candidate)
        }

    }

    const candidateDetailsPageLink = `${process.env.REACT_APP_BASENAME}/profile/${candidate_uuid}`
    const handleProfileClick = (e) => {
        e.preventDefault();
        if (window.event.ctrlKey) {
            //ctrl was held down during the click
            window.open(candidateDetailsPageLink, '_blank')
        } else {
            props.openDetailsModal(candidate.recruitplus.uuid, props.position)
        }
    }

    const getCandidateOtherDetails = (e, url, type, value) => {
        const userName = url.substring(url.lastIndexOf('/') + 1)
        props.setCandidateOtherNetworkDetails(prevState => ({...prevState, userName, type, value}))
        props.setIsQueryEnable(true)
    }

    const c = props.candidate;
    const social = c.basic.social_links || c.basic.socialLinks;
    const isChecked = checkAll[candidate.recruitplus.uuid]?.isChecked ?? false

    return (<>
            {deleteModalOpen && <DeleteConfirmation
                message={`Are you sure you want to remove ${candidate?.basic?.fullname} from pipeline ?`}
                onConfirm={(e) => openJsonPopupFun(e, 'removeCandidate')}
                setDetails={setDeleteModalOpen}
                detailsInitialState={false}
                isLoading={removeCandidateAPI.isLoading}/>}
            <div className={highlight ? "user_template_item highlight-item" : "user_template_item"}>
                <div className="user_template_header">
                    <div className="user_template_info">
                        <div className="user_title user_all_details">
                            <div className={candidate?.coffeemug ? 'user_profl bg_after' : 'user_profl'}>
                                <div className="checkbox_column">
                                    <div className="ck_type_1">
                                        <input type="checkbox"
                                               onChange={(e) => props.setCheckAll(prevState => ({
                                                   ...prevState, [candidate.recruitplus.uuid]: {
                                                       uuid: candidate.recruitplus.uuid, isChecked: e.target.checked
                                                   }
                                               }))}
                                               checked={isChecked}
                                        />
                                        <label></label>
                                    </div>
                                </div>
                                <div>
                                    <div className="userimage">
                                        <img className="user_img" src={candidate.basic.image_url ?? userDefaultImage}
                                             onError={({currentTarget}) => currentTarget.src = userDefaultImage}
                                             alt="user-image"/>
                                        {availabilityVal === 'High' &&
                                            <div className="likely_img">
                                                <img src={`${process.env.PUBLIC_URL}/assets/images/likely_img.png`} alt=""/>
                                            </div>
                                        }
                                        {props.network?.forGithub && <div className="coder_score_budge">
                                            <span>{candidate?.other_sources?.github?.profile_rank}</span>
                                            <ion-icon name="star"></ion-icon>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            <div className="user_details_area full_grid">
                                <div className="user_details">
                                    {!highlight && <div className="user_right row_gap pull-right resposnive_hide">
                                        {pipeline && <>
                                            <select className="btn btn_outline btn_sm stage" onChange={(e) => {
                                                updateCandidateStageAPI.mutate({
                                                    candidate_uuid,
                                                    stage: e.target.value,
                                                    project_id: candidate?.pipelineData?.project_id,
                                                    projectUuid: project_id,
                                                    page
                                                })
                                            }} value={candidate?.pipelineData?.stage}>
                                                <option value="Uncontacted">Uncontacted</option>
                                                <option value="Contacted">Contacted</option>
                                                <option value="Rejected">Rejected</option>
                                            </select>
                                        </>}

                                        {process.env.REACT_APP_ENV !== 'prod' &&
                                            <button className="btn btn_fill btn_sm" type="button"
                                                    data-bs-toggle="offcanvas"
                                                    onClick={(e) => openJsonPopupFun(e, 'json')}>JSON</button>}
                                        <div className="btn-group">
                                            {pipeline !== true ? <InputButton
                                                className="btn btn_fill dropdown-toggle btn_sm"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                onClick={(e) => openJsonPopupFun(e, 'pipeline')}
                                                isLoading={addCandidateAPI.isLoading}
                                            >
                                                Save to Pipeline
                                            </InputButton> : <InputButton
                                                className="btn btn_fill dropdown-toggle btn_sm"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                onClick={() => setDeleteModalOpen(true)}
                                            >
                                                Remove From Pipeline
                                            </InputButton>}
                                            {state.showComponent === 'success' && (<>
                                                <div className="rp-checked-message">
                                                    <ion-icon name="checkmark"></ion-icon>
                                                    1 Candidate Saved to Pipeline successfully
                                                </div>
                                            </>)}

                                            {state.showComponent === 'error' && (<>
                                                <div className="rp-checked-message">
                                                    {state.error && <div className="form-error">
                                                        <ion-icon name="alert"></ion-icon>
                                                        <span>{state.error}</span></div>}
                                                </div>
                                            </>)}

                                            <div className={state.pipelineClass} ref={ref}>
                                                <h4>Save to Pipeline</h4>
                                                <div className="divider_line"></div>
                                                <Autocomplete
                                                    sx={{height: 70}}
                                                    open={open}
                                                    onOpen={() => {
                                                        setOpen(true);
                                                    }}
                                                    onClose={() => {
                                                        setOpen(false);
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option.title === value.title}
                                                    options={options}
                                                    getOptionLabel={getOptions}
                                                    loading={loading}
                                                    onInputChange={handleInputChange}
                                                    inputValue={inputValue}
                                                    renderOption={(props, option, {selected}) => (
                                                        <Card variant="outlined">
                                                            <CardContent>
                                                                <div>
                                                                    <Typography variant="h6" component="div">
                                                                        {option?.name}
                                                                    </Typography>
                                                                    <Typography color="text.secondary">
                                                                        {formatDate(option?.created_at)}
                                                                    </Typography>
                                                                </div>

                                                            </CardContent>
                                                        </Card>)}
                                                    renderInput={(params) => (<TextField
                                                        {...params}
                                                        label="Projects"
                                                        InputProps={{
                                                            ...params.InputProps, endAdornment: (<React.Fragment>
                                                                {loading ? <CircularProgress color="inherit"
                                                                                             size={20}/> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>),
                                                        }}
                                                    />)}
                                                />
                                                <div className="group_item">
                                                    <label htmlFor="">Status</label>
                                                    <div className="option_area">
                                                            <span className="dwn_arow"><i
                                                                className="fa-solid fa-chevron-down"></i></span>
                                                        <select className="form-select"
                                                                aria-label="Default select example"
                                                                id="status"
                                                                value={addCandidate.status}
                                                                onChange={handleChange}>
                                                            <option>Select Stage</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                {/*<div className="btn_area">
                                            <a href="#"
                                               onClick={handleSubmit}>Save to Pipeline</a>
                                            <a href="#"
                                               onClick={openJsonPopupFun('closePipeline')}>Cancel</a>
                                        </div>*/}
                                            </div>

                                        </div>
                                        <button className="btn btn_outline btn_sm" onClick={() => {
                                            similarProfile(candidate.recruitplus.uuid)
                                        }}>Similar Profiles
                                        </button>
                                    </div>}
                                    <div className="template_user_name pr_20">
                                        <h3>
                                            <a href={candidateDetailsPageLink}
                                               onClick={(e) => {
                                                   handleProfileClick(e)
                                               }} dangerouslySetInnerHTML={{__html: (candidate.basic.fullname)}}
                                               className={profileViewed ? 'profile_viewed' : ''}
                                            ></a>
                                            {seniorityLength !== 0 && candidate?.currentPosition?.c_seniority && (
                                                <span className="rp_tags desktop_hide mob_show ml-1">
                                            <span
                                                className="rp_tag_text">{candidate?.currentPosition?.c_seniority}</span>
                                        </span>)}
                                        </h3>
                                    </div>
                                    <div className="user_designation">
                                        {candidate?.basic?.current_title && <>
                                            <span className="rp_current_role"
                                                  dangerouslySetInnerHTML={{__html: (candidate?.basic?.current_title)}}></span>
                                        </>}
                                        {/*{candidate?.basic?.current_title &&
                                            <>
                                                <span className="rp_company">{candidate?.basic?.current_title}</span>
                                            </>}*/}
                                        {seniorityLength !== 0 && candidate?.currentPosition?.c_seniority && (
                                            <span className="rp_tags hide_rp_tag_mob">
                                            <span className="dot"></span>
                                            <span
                                                className="rp_tag_text">{candidate?.currentPosition?.c_seniority}</span>
                                        </span>)}

                                        {/*<h4>{candidate.basic.current_title}</h4>*/}
                                    </div>
                                    <div className="user_address">
                                        {candidate?.basic?.location}
                                    </div>
                                    {/*<div className="l_content">*/}
                                    {/*    <img className="likely_svg" src={`${process.env.PUBLIC_URL}/assets/images/likely-to-respond.svg`}  alt="Likely to Switch"/>*/}
                                    {/*<div className="perfomance_row">*/}
                                    {/*    <p>Likely to Respond</p>*/}
                                    {/*    <ul>*/}
                                    {/*        {[1, 3, 5, 8, 10].map((i) => <li key={i}*/}
                                    {/*                                         className={availabilityScore < i ? '' : 'active'}></li>)}*/}

                                    {/*    </ul>*/}
                                    {/*    <span>{availabilityVal}</span>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                    <span className="other_network_btn">
                                    {props?.network?.forTwitter && <InputButton
                                        className="btn btn_fill btn_sm"
                                        onClick={(e) => getCandidateOtherDetails(e, candidate?.basic?.social_links?.twitter, 'twitter', 'Tweets')}
                                        isLoading={props.candidateOtherNetworkDetails.type && props.candidateOtherNetworkDetails.type === 'twitter' && props.isLoading}
                                    >
                                        Get tweets
                                    </InputButton>}

                                        {/*{props?.network?.forKaggle && <InputButton*/}
                                        {/*    className="btn btn_fill btn_sm"*/}
                                        {/*    onClick={(e) => getCandidateOtherDetails(e, candidate?.basic?.social_links?.kaggle, 'kaggle-kernels', 'Kaggle kernels')}*/}
                                        {/*    isLoading={props.candidateOtherNetworkDetails.type && props.candidateOtherNetworkDetails.type === 'kaggle-kernels' && props.isLoading}*/}
                                        {/*    disabled={props.candidateOtherNetworkDetails?.type === 'kaggle-datasets' && props.isLoading}*/}
                                        {/*>*/}
                                        {/*    Get Kaggle Kernels*/}
                                        {/*</InputButton>*/}

                                        {/*}*/}
                                        {/*{props?.network?.forKaggle && <InputButton*/}
                                        {/*    className="btn btn_fill btn_sm"*/}
                                        {/*    onClick={(e) => getCandidateOtherDetails(e, candidate?.basic?.social_links?.kaggle, 'kaggle-datasets', 'Kaggle Dataset')}*/}
                                        {/*    isLoading={props.candidateOtherNetworkDetails.type && props.candidateOtherNetworkDetails.type === 'kaggle-datasets' && props.isLoading}*/}
                                        {/*    disabled={props.candidateOtherNetworkDetails?.type === 'kaggle-kernels' && props.isLoading}*/}
                                        {/*>*/}
                                        {/*    Get Kaggle Dataset*/}
                                        {/*</InputButton>}*/}
                                </span>
                                </div>
                            </div>

                        </div>
                        {/*<div className="likely_row main_user_content pt-0 mt-2">
                            <ul>
                                <li>
                                    <div className="l_content text-end"><img className="likely_svg"
                                                                             src={`${process.env.PUBLIC_URL}/assets/images/likely-to-respond.svg`}
                                                                             alt="Likely to Respond" /></div>
                                    <div className="r_content">
                                        <div>
                                            <div className="perfomance_row">
                                                <p>Likely to Respond</p>
                                                <ul>
                                                    {[1, 3, 5, 8, 10].map((i) => <li key={i}
                                                                                     className={availabilityScore < i ? '' : 'active'}></li>)}

                                                </ul>
                                                <span>{availabilityVal}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>*/}
                    </div>
                </div>
            </div>
            <div className="main_user_content order-last">
                <ul>
                    <li>
                        <div></div>
                        {!highlight && <div className="user_right row_gap pull-right desktop_hide mob_show ">
                            {pipeline &&
                                <>
                                    <select className="btn btn_outline btn_sm stage" onChange={(e) => {
                                        updateCandidateStageAPI.mutate({
                                            candidate_uuid,
                                            stage: e.target.value,
                                            project_id: candidate?.pipelineData?.project_id,
                                            projectUuid: project_id,
                                            page
                                        })
                                    }} value={candidate?.pipelineData?.stage}>
                                        <option value="Uncontacted">Uncontacted</option>
                                        <option value="Contacted">Contacted</option>
                                        <option value="Rejected">Rejected</option>
                                    </select>
                                </>
                            }
                            {process.env.REACT_APP_ENV !== 'prod' &&
                                <button className="btn btn_fill btn_sm" type="button" data-bs-toggle="offcanvas"
                                        onClick={(e) => openJsonPopupFun(e, 'json')}>JSON</button>
                            }
                            <div className="btn-group">
                                {pipeline !== true ? <InputButton
                                    className="btn btn_fill dropdown-toggle btn_sm"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={(e) => openJsonPopupFun(e, 'pipeline')}
                                    isLoading={addCandidateAPI.isLoading}
                                >
                                    Save to Pipeline
                                </InputButton> : <InputButton
                                    className="btn btn_fill dropdown-toggle btn_sm"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={() => setDeleteModalOpen(true)}
                                >
                                    Remove From Pipeline
                                </InputButton>}
                                {state.showComponent === 'success' && (<>
                                    <div className="rp-checked-message">
                                        <ion-icon name="checkmark"></ion-icon>
                                        1 Candidate Saved to Pipeline successfully
                                    </div>
                                </>)}
                                {state.showComponent === 'error' && (<>
                                    <div className="rp-checked-message">
                                        {state.error && <div className="form-error">
                                            <ion-icon name="alert"></ion-icon>
                                            <span>{state.error}</span></div>}
                                    </div>
                                </>)}
                                <div className={state.pipelineClass} ref={ref}>
                                    <h4>Save to Pipeline</h4>
                                    <div className="divider_line"></div>
                                    <Autocomplete
                                        sx={{height: 70}}
                                        open={open}
                                        onOpen={() => {
                                            setOpen(true);
                                        }}
                                        onClose={() => {
                                            setOpen(false);
                                        }}
                                        isOptionEqualToValue={(option, value) => option.title === value.title}
                                        options={options}
                                        getOptionLabel={getOptions}
                                        loading={loading}
                                        onInputChange={handleInputChange}
                                        inputValue={inputValue}
                                        renderOption={(props, option, {selected}) => (<Card variant="outlined">
                                            <CardContent>
                                                <div>
                                                    <Typography variant="h6" component="div">
                                                        {option?.name}
                                                    </Typography>
                                                    <Typography color="text.secondary">
                                                        {formatDate(option?.created_at)}
                                                    </Typography>
                                                </div>
                                            </CardContent>
                                        </Card>)}
                                        renderInput={(params) => (<TextField
                                            {...params}
                                            label="Projects"
                                            InputProps={{
                                                ...params.InputProps, endAdornment: (<React.Fragment>
                                                    {loading ? <CircularProgress color="inherit"
                                                                                 size={20}/> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>),
                                            }}
                                        />)}
                                    />
                                    <div className="group_item">
                                        <label htmlFor="">Status</label>
                                        <div className="option_area">
                                                            <span className="dwn_arow"><i
                                                                className="fa-solid fa-chevron-down"></i></span>
                                            <select className="form-select"
                                                    aria-label="Default select example"
                                                    id="status"
                                                    value={addCandidate.status}
                                                    onChange={handleChange}>
                                                <option>Select Stage</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/*<div className="btn_area">
                                            <a href="#"
                                               onClick={handleSubmit}>Save to Pipeline</a>
                                            <a href="#"
                                               onClick={openJsonPopupFun('closePipeline')}>Cancel</a>
                                        </div>*/}
                                </div>
                            </div>
                            <button className="btn btn_outline btn_sm" onClick={() => {
                                similarProfile(candidate.recruitplus.uuid)
                            }}>Similar Profiles
                            </button>
                        </div>}
                    </li>
                </ul>
            </div>

        </>
    )
}

CardHeader.propTypes = {
    candidate: PropTypes.object, pipeline: PropTypes.bool, highlight: PropTypes.bool
}
export default CardHeader