import {apiHit} from './helpers';

export const companyService = {
    addProject,
    editProject,
    getProject,
    getAllProject,
    getSimilar,
    candidateList,
    deleteProject,
    getSimilarToData,
    getSimilarCompanySearch,
    saveSimilarCompanyFilter
};

function getSimilar(signal, filters, page) {
    const urlencoded = new URLSearchParams();
    if (filters) {
        const filtersKey = Object.keys(filters);
        for (const k in filtersKey) {
            const key = filtersKey[k]
            if (Array.isArray(filters[key])) {
                for (const k in filters[key]) {
                    urlencoded.append(`${key}[]`, filters[key][k].value)
                }
            } else if (typeof filters[key] === 'object') {
                urlencoded.append(`${key}`, filters[key].value)
            } else {
                urlencoded.append(`${key}`, filters[key])
            }
        }
    }

    return apiHit(`companies/similar?page=${page}`, urlencoded, signal).then(user => {
        return user;
    })
}

function getSimilarToData(signal, filters) {
    const urlencoded = new URLSearchParams();
    if (filters) {
        const filtersKey = Object.keys(filters);
        for (const k in filtersKey) {
            const key = filtersKey[k]
            if (Array.isArray(filters[key])) {
                for (const k in filters[key]) {
                    urlencoded.append(`${key}[]`, filters[key][k].value)
                }
            } else if (typeof filters[key] === 'object') {
                urlencoded.append(`${key}`, filters[key].value)
            } else {
                urlencoded.append(`${key}`, filters[key])
            }
        }
    }

    return apiHit(`companies/similar-to`, urlencoded, signal).then(user => {
        return user;
    })
}

function getProject(signal, company_id, params) {
    const urlencoded = new URLSearchParams();
    if (company_id) {
        urlencoded.append('company', company_id)
    }
    if (params) {
        const paramsKeys = Object.keys(params)
        for (const key in paramsKeys) {
            const param = paramsKeys[key]
            urlencoded.append(param, params[param])
        }
    }

    return apiHit(`client/projects`, urlencoded, signal).then(user => {
        return user;
    })
}

function getAllProject(signal, params) {
    const urlencoded = new URLSearchParams(params);
    return apiHit(`user-projects`, urlencoded).then(user => {
        return user;
    })
}

function addProject(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    return apiHit(`client/projects/add`, urlencoded).then(user => {
        return user;
    })
}


function editProject(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    return apiHit(`client/projects/edit`, urlencoded).then(user => {
        return user;
    })
}

function candidateList(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    return apiHit(`candidates/list`, urlencoded).then(user => {
        return user;
    })
}

function deleteProject(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    return apiHit(`client/projects/delete`, urlencoded, null, 'DELETE').then(user => {
        return user;
    })
}

function getSimilarCompanySearch(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);
    return apiHit(`similar-companies/search-history`, urlencoded).then(result => {
        return result;
    })
}

function saveSimilarCompanyFilter(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);
    return apiHit(`similar-companies/search/add`, urlencoded).then(result => {
        return result;
    })
}
