import React from "react";
import InputField from "./InputField";
import TextareaAutosize from "@mui/base/TextareaAutosize";

export default function Textarea(props) {
    return <TextareaAutosize
        className="mui_textarea"
        name={props.name}
        fullWidth
        value={props.value}
        inputProps={{
            inputComponent: TextareaAutosize,
        }}
        onChange={props.onChange}
        label={props.label}
        placeholder={props.placeholder}
    />
}