import {useMutation, useQueryClient} from "react-query";
import {candidateService} from "../../../../redux/services";
import {alertActions} from "../../../../redux/actions";
import {useDispatch} from "react-redux";

const updateCandidateStage = async(input) => {
    return await candidateService.updateCandidateStage(input);
}

export default function useUpdateCandidateStageAPI() {
    const queryClient = useQueryClient()
    const dispatch = useDispatch();

    return useMutation(updateCandidateStage, {
        onSuccess: (data, variables) => {
            queryClient.refetchQueries(`pipelineCandidateData-${variables?.page}-${variables.projectUuid}`)
            queryClient.refetchQueries(`fetchCandidateDetails-${variables.candidate_uuid}`)
            dispatch(alertActions.success(data.status));
        }
    })
}