import {useQuery} from "react-query";
import {candidateService, companyService} from "../../../../redux/services";

const searchCandidates = async ({queryKey}) => {
    const [_key, {filters, page}] = queryKey
    return await candidateService.filter(filters, page)
};

const searchGithubCandidates = async ({queryKey}) => {
    const [_key, {filters, page}] = queryKey
    return await candidateService.githubFilter(filters, page)
};

const getOtherNetworkCandidates = async ({queryKey}) => {
    const [_key, {filters, page, network}] = queryKey
    const networkName = network === 'twitterCandidateData' ? 'twitter' : 'kaggle';

    return await candidateService.getOtherNetworkCandidates(filters, page, networkName)
}

const pipelineList = async ({queryKey}) => {
    const [_key, {filters, page}] = queryKey

    const inputObject  = {
        project: filters?.projectId,
        candidate: filters?.candidate,
        stage: filters?.stage,
        recentlyAdded: filters?.recentlyAdded,
        'page': page
    }

    return await companyService.candidateList(inputObject);
};

export default function useCandidateDataAPI(
    {
        forGithub = false,
        forTwitter = false,
        forKaggle = false,
        forPipeline = false
    } = {}, filters, page, current) {

    let queryKey = 'candidateData';
    let queryFunc = searchCandidates;

    if(forGithub) {
        queryKey = 'githubCandidateData'
        queryFunc = searchGithubCandidates;
    }

    if(forTwitter) {
        queryKey = 'twitterCandidateData'
        queryFunc = getOtherNetworkCandidates;
    }

    if(forKaggle) {
        queryKey = 'kaggleCandidateData'
        queryFunc = getOtherNetworkCandidates;
    }

    if(forPipeline) {
        queryKey = `pipelineCandidateData-${page}-${filters.projectId}`
        queryFunc = pipelineList
    }

    return useQuery([queryKey, {filters, page, network:queryKey}], queryFunc, {
        refetchOnWindowFocus: false, enabled: current,
        retryDelay: (retryCount) => retryCount * 2000
    })

}
