import {useQuery} from "react-query";
import {companyService} from "../../../redux/services";

const projectList = async ({queryKey, signal}) => {
    const [_key, {company_id, params}] = queryKey
    const data = await companyService.getProject(signal, company_id, params);
    return data.data;
};

const projectAllList = async ({queryKey, signal}) => {
    const [_key, {params}] = queryKey
    const data = await companyService.getAllProject(signal, params);
    return data.data;
};

export default function useGetProjectAPI(allProject, params, fetchData) {
    return useQuery((allProject ? ["allProjectData", { params }] : ["projectData", {
             params
    }]), (allProject ? projectAllList : projectList), {
        enabled: fetchData, refetchOnWindowFocus: false, retryDelay: (retryCount) => retryCount * 2000
    })
}

