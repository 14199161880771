import {useQuery} from "react-query";
import {candidateService} from "../../../../redux/services";

const getCandidateOtherDetails = async ({queryKey}) => {
    const [_key, search] = queryKey

    return await candidateService.getHeaderSearchSuggestion({search});
};
export default function useGetHeaderSearches(search, enabled) {
    return useQuery(["headerSearch", search], getCandidateOtherDetails, {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: enabled,
        cacheTime: 0
    });
}
