import {useMutation, useQueryClient} from "react-query";
import {candidateService} from "../../../../redux/services";
import {alertActions} from "../../../../redux/actions";
import {useDispatch} from "react-redux";

const addCandidateDetails = async (input) => {
    return await candidateService.addCandidateDetails(input);
}

export default function useAddCandidateDetailsAPI() {
    const queryClient = useQueryClient()
    const dispatch = useDispatch();

    return useMutation(addCandidateDetails, {
        onSuccess: (data, variables) => {
            dispatch(alertActions.success(data.message));
        }, onError: (error, variables, context) => {

        }
    })
}