import {useMutation} from "react-query";
import {useDispatch} from "react-redux";

import {userService} from "../../../redux/services";
import {userConstants} from "../../../redux/constants";

const login = async (input) => {
    return await userService.login(input.username, input.password)
}

const loginWithToken = async (input) => {
    return await userService.loginWithToken(input)
}

export default function useLoginAPI(token = null) {
    const dispatch = useDispatch();
    const apiFunction = token ? loginWithToken : login;
    return useMutation(apiFunction, {
        onSuccess: (data, variables) => {
            const user = data.user
            dispatch({type: userConstants.LOGIN_SUCCESS, user})
            if (variables.redirectGpt) {
                const apiToken = data?.user?.api_token?.replace(/^"|"$/g, '') || false;
                window.location.href = process.env.REACT_APP_TALENT_GPT_URL + `/authorize/${apiToken}`;
            }
        },
        onError: (error, variables, context) => {
            dispatch({type: userConstants.LOGIN_FAILURE})
            // dispatch(alertActions.error(error.toString()));
        }
    })
}