import React, {useState} from "react";
import PropTypes from "prop-types";
import {nanoid} from "nanoid";
import loader from '../../../../assets/img/loader.gif'
import {Months} from "../../../../helpers";

function CardData(props) {
    const c = props.candidate;
    const availabilityScore = props.candidate.recruitplus?.availability_score ?? 0;
    const [handleShow, setHandleShow] = useState({
        allPositions: false, allEducations: false, allSkills: false, kaggleAllDataset: false, kaggleAllKernels: false,
        showKernelBtn: true, showDatasetBtn: true
    })

    const getCandidateOtherDetails = (e, url, type, value) => {
        const userName = url.substring(url.lastIndexOf('/') + 1)
        props.setCandidateOtherNetworkDetails(prevState => ({...prevState, userName, type, value}))
        props.setIsQueryEnable(true)

        if (value === 'Datasets') {
            setHandleShow(prevState => ({...prevState, showDatasetBtn: false}))
        }

        if (value === 'Kernels') {
            setHandleShow(prevState => ({...prevState, showKernelBtn: false}))
        }

    }

    const positions = [], educations = [], datasetAndKernels = [];

    let availabilityVal = props.candidate.recruitplus?.availability_level ?? 'Low';
    if (availabilityVal === '') {
        availabilityVal = 'Low';
    }

    if (c?.positions?.length > 0) {
        const companiesSortedByOrder = c?.positions?.sort((a, b) => a?.order - b?.order)
        companiesSortedByOrder?.slice(0, handleShow.allPositions ? undefined : 3).map((company, key) => {
            let industriesNames = '';
            /*if (company?.industries) {
                industriesNames = company?.industries.map(industry => industry.name).join(", ")
            }*/

            positions.push(<div key={key}>
                <div className="user_designation">
                    {company?.title &&
                        <>
                            <span className="rp_user_text1" dangerouslySetInnerHTML={{__html: company.title}}></span>
                            <span className="rp_text">@</span>
                        </>
                    }
                    {company?.company && <>
                        <span className="rp_user_text2">{company.company}</span>
                        {/*{industriesNames && <span> ( {industriesNames})</span>}*/}
                    </>}
                    {company?.startDate &&
                        <span className="rp_tags">
                            <span className="dot black_dot"></span>
                            <span className="rp_year_text">({company.startDate + ' - ' + (company.endDate ?? 'present')})</span>
                        </span>
                    }
                </div>
            </div>)
        })
    }

    if (c?.educations?.length > 0) {
        const educationByDuration = c?.educations?.sort((a, b) => {

            return b.startYear - a.startYear
        })

        educationByDuration?.slice(0, handleShow.allEducations ? undefined : 3).map((education, key) => {
            educations.push(<div key={key}>
                <div className="user_designation">
                    {education?.major?.length > 0 && <span className="rp_user_text2">{education.major}<span
                        className="dot black_dot"></span></span>}
                    {education.degree && <span className="rp_user_text1">{education.degree} at </span>}
                    <span className="rp_user_text1">{education.school}</span>
                    {education.startYear && education.startYear &&
                        <span className="rp_tags">
                        <span className="dot black_dot"></span>
                        <span
                            className="rp_year_text">({education.startYear + ' - ' + education.endYear})
                        </span>
                    </span>
                    }
                </div>

            </div>)
        })
    }
    let skills = [];
    let highlightSkills = [];
    if (c.recruitplus.calculated_skills.length > 0) {
        const skillFilter = [...(props?.filters?.skill ?? []).map(skill => skill.value.toLowerCase()), ...(props?.filters?.mandatorySkill ?? []).map(skill => skill.value.toLowerCase()), ...(props?.filters?.keyword ?? []).map(skill => skill.value.toLowerCase()),]
        let highlights = c.recruitplus.calculated_skills.filter(skill => !skill.name)
        if (highlights[0]) {
            highlights = highlights[0]
        }
        let highlightsCheck = [];
        if (highlights.length > 0) {
            highlightsCheck = highlights.map(highlight => highlight.toString().replace(/<\/?[^>]+>/gi, ''))
        }
        c.recruitplus.calculated_skills.forEach((skill) => {
            const weight = Math.round((skill.weight + Number.EPSILON) * 100) / 100;
            if (skill.name && !highlightsCheck.includes(skill.name) && !skillFilter.includes(skill.name.toLowerCase())) {
                skills.push(`${skill.name}`)
            } else {
                highlightSkills.push(`<strong>${skill.name}</strong>`)
            }
        })
        if (highlights.length > 0) {
            skills.unshift(...highlights)
        }
        if (highlightSkills.length > 0) {
            skills.unshift(...highlightSkills)
        }
    }

    let debugInfo = {}
    if (props.showGithub) {
        debugInfo = {
            "Total Repositories": c.other_sources.github.total_repos ?? 0,
            "Total Forks": c.other_sources.github.total_forks ?? 0,
            "Total Stars": c.other_sources.github.total_stars ?? 0,
            "Repo Score": c.other_sources.github.profile_score_debug.repo_score ?? 0,
            "Achievement Score": c.other_sources.github.profile_score_debug.achievement_score ?? 0,
            "Contributions": c.other_sources.github.profile_score_debug.contributes ?? 0,
            "Followers": c.other_sources.github.profile_score_debug.followers ?? 0,
            "Profile Score": c.other_sources.github.profile_score ?? 0,
        };
        if (props.filters && props.filters.skill && props.filters.skill.length > 0) {
            const skillsValue = props.filters.skill.map(skill => skill.value.toLowerCase())
            c.other_sources.github.languages.forEach(lang => {
                if (skillsValue.includes(lang.lang.toLowerCase())) {
                    debugInfo[lang.lang + " Score"] = lang.score
                }
            })
        }
        if (props.filters && props.filters.mandatorySkill && props.filters.mandatorySkill.length > 0) {
            const skillsValue = props.filters.mandatorySkill.map(skill => skill.value.toLowerCase())
            c.other_sources.github.languages.forEach(lang => {
                if (skillsValue.includes(lang.lang.toLowerCase())) {
                    debugInfo[lang.lang + " Score"] = lang.score
                }
            })
        }

    } else {
        debugInfo = {
            "Growth trajectory": c.recruitplus?.growth_trajectory ?? 0,
            "Seniority level score": c.recruitplus?.growth_trajectory_debug?.seniority_level_score ?? 0,
            "Current position duration": c.recruitplus?.growth_trajectory_debug?.current_position_duration ?? 0,
            "Current seniority duration": c.recruitplus?.growth_trajectory_debug?.current_seniority_duration ?? 0,
            "User company tenure median": c.recruitplus?.availability_logic?.overall_median ?? 0,
            "User median tenure": c.recruitplus?.availability_logic?.candidate_median ?? 0,
            "Past founder score": c.sort.past_founder ?? 0,
            "Job hopper score": c.sort.not_job_hopper ?? 0,
            "Availability score": c.sort.availability ?? 0,
            "Premium education score": c.sort.premium_education ?? 0,
        };

        if (props.filters && props.filters.mandatorySkill && props.filters.mandatorySkill.length > 0) {
            const skillsValue = props.filters.mandatorySkill.map(skill => skill.value.toLowerCase())
            c.recruitplus.calculated_skills.forEach(lang => {
                if (skillsValue.includes(lang.name.replace(/<[^>]*>?/gm, '').toLowerCase())) {
                    debugInfo[lang.name.replace(/<[^>]*>?/gm, '') + " Score"] = lang.weight
                }
            })
        }
        if (props.filters && props.filters.skill && props.filters.skill.length > 0) {
            const skillsValue = props.filters.skill.map(skill => skill.value.toLowerCase())
            c.recruitplus.calculated_skills.forEach(lang => {
                if (skillsValue.includes(lang.name.replace(/<[^>]*>?/gm, '').toLowerCase())) {
                    debugInfo[lang.name.replace(/<[^>]*>?/gm, '') + " Score"] = lang.weight
                }
            })
        }
    }

    const handleShowMoreAndLess = (type) => {
        switch (type) {
            case 'Kernels':
                setHandleShow(prevState => ({...prevState, kaggleAllKernels: !prevState.kaggleAllKernels}))
                break;
            case 'Datasets':
                setHandleShow(prevState => ({...prevState, kaggleAllDataset: !prevState.kaggleAllDataset}))
                break;
        }
    }

    if (props?.otherNetworkDetails && props?.otherNetworkDetails.length) {
        props?.otherNetworkDetails?.forEach(function (details, i) {
            let showMoreAndLessText = ''

            switch (details?.type) {
                case 'Kernels':
                    showMoreAndLessText = handleShow.kaggleAllKernels ? 'Show less' : 'Show all';
                    break;
                case 'Datasets':
                    showMoreAndLessText = handleShow.kaggleAllDataset ? 'Show less' : 'Show all';
                    break;
            }

            datasetAndKernels.push(
                <li className="list_mb" key={i}>
                    <div className="l_content"></div>
                    <div className="r_content kaggle_list">
                        <div className="row_lg">
                            <h6>{details.type}</h6>
                        </div>
                        <div className="user_designation">
                            {details?.value?.length > 0 ?
                                details?.value.slice(0, (showMoreAndLessText === 'Show less' ? undefined : 2)).map((data, key) => (
                                    <span key={key}><a
                                        href={details.type === 'Dataset' ? `https://www.kaggle.com/datasets/${data?.ref}` : `https://www.kaggle.com/code/${data?.ref}`}
                                            target="_blank" rel="noreferrer"> {data?.title} </a></span>
                                )) : <span className="no_data fw_500">No Data Found</span>
                            }
                        </div>
                        {details?.value?.length > 2 &&
                            <div className="row_lg">
                                <button className="show_more_btn mt-0"
                                        onClick={() => handleShowMoreAndLess(details?.type)}>
                                    {showMoreAndLessText}
                                </button>
                            </div>
                        }
                    </div>
                </li>
            )
        })
    }

    let fullTimeYearsText = Math.round(c.recruitplus.full_time_years) + ' Yrs';
    if (c?.recruitplus?.full_time_years_text) {
        fullTimeYearsText = c?.recruitplus?.full_time_years_text;
    }

    return (
        <>
        <div className="main_user_content">
        <ul>
            {c?.kaggle && <li className="kaggle_list_style list_mb" key="kaggle">
                <div className="l_content"><h4>Kaggle</h4></div>
                <div className="kaggle_list">
                    {c?.kaggle?.tier &&
                        <div className="r_content_colmun">
                            <div><h6>Tier</h6></div>
                            <div><p>{c?.kaggle?.tier}</p></div>
                        </div>
                    }

                    <div className="r_content_colmun">
                        <div><h6>Medals</h6></div>
                        <div className="meadal_column">
                            {c?.kaggle?.medals && c?.kaggle?.medals.map(({total, name}, i) => {
                                return <span key={i}>
                                            {i > 0 &&
                                                <span className="dot black"></span>
                                            }
                                    <span className="text_1">{total} </span>
                                            <span className="text_2">{name}</span>
                                        </span>
                            })}
                        </div>
                    </div>
                    <div className="r_content_colmun">
                        <div><h6>Upvotes</h6></div>
                        <div><p>{c?.kaggle?.up_votes}</p></div>
                    </div>
                    <div className="r_content_colmun">
                        <div><h6>Followers</h6></div>
                        <div><p>{c?.kaggle?.followers}</p></div>
                    </div>
                    <div
                        className={handleShow.showKernelBtn && handleShow.showDatasetBtn ? 'kaggle_btns mt_3' : 'mt_3'}>
                        {props.isLoading &&
                            <img src={loader}/>
                        }
                        {handleShow.showDatasetBtn &&
                            <button
                                onClick={(e) => getCandidateOtherDetails(e, c?.basic?.social_links?.kaggle, 'kaggle-datasets', 'Datasets')}
                                className="show_more_btn">Get Kaggle Dataset</button>
                        }
                        {handleShow.showKernelBtn &&
                            <button
                                onClick={(e) => getCandidateOtherDetails(e, c?.basic?.social_links?.kaggle, 'kaggle-kernels', 'Kernels')}
                                className="show_more_btn">Get Kaggle Actvities</button>
                        }
                    </div>
                </div>
            </li>
            }
            {datasetAndKernels}
            {c?.positions?.length > 0 && <li key='experience'>
                <div className="l_content">
                    <h4>Experience</h4>
                    <div className="row_lg">
                        <p className="highlight_text" style={{"textTransform": "capitalize"}}>{fullTimeYearsText}</p>
                    </div>
                </div>
                <div className="r_content">
                    {positions}
                    {c?.positions?.length > 3 && <div className="row_lg">
                        <button className="show_more_btn mt-0" onClick={() => {
                            setHandleShow(prevState => ({...prevState, allPositions: !prevState.allPositions}))
                        }}>{handleShow.allPositions ? 'Show less' : 'Show all'}
                        </button>
                    </div>}
                </div>
            </li>}
            {c?.educations?.length > 0 && <li key="education">
                <div className="l_content">
                    <h4>Education</h4>
                </div>

                <div className="r_content">
                    {educations}
                    {c?.educations?.length > 3 && <div className="row_lg">
                        <button className="show_more_btn mt-0" onClick={() => {
                            setHandleShow(prevState => ({...prevState, allEducations: !prevState.allEducations}))
                        }}>{handleShow.allEducations ? 'Show less' : 'Show all'}
                        </button>
                    </div>}
                </div>
            </li>}
            {skills?.length > 0 && <li key="skill">
                <div className="l_content">
                    <h4>Skills</h4>
                </div>
                <div className="r_content inline_pills">
                    <ul>
                        {skills?.length > 0 && skills.slice(0, handleShow.allSkills ? undefined : 5).map((skill, i) => {
                            return <li key={i}><span dangerouslySetInnerHTML={{__html: skill}}></span>
                            </li>
                        })}
                    </ul>
                    {skills?.length > 5 && <div className="row_lg">
                        <button className="show_more_btn mt-0" onClick={() => {
                            setHandleShow(prevState => ({...prevState, allSkills: !prevState.allSkills}))
                        }}>{handleShow.allSkills ? 'Show less' : 'Show all'}
                        </button>
                    </div>}
                </div>
            </li>}
            {/*<li key="extra">
                <div className="l_content">
                    <h4>Attributes for Sorting</h4>
                </div>

                <div className="r_content">
                    <table className="table table-sm table-bordered">
                        <tbody>
                        {Object.keys(debugInfo).map(val => (
                            <tr key={nanoid(5)}>
                                <th>{val}</th>
                                <td>{debugInfo[val]}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </li>*/}
        </ul>
    </div></>)

}

CardData.propTypes = {
    candidate: PropTypes.object
}

export default CardData
