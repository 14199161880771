import {apiHit} from './helpers';

export const candidateService = {
    filter,
    addCandidate,
    removeCandidate,
    details,
    similarFinder,
    githubFilter,
    getSearchList,
    addCandidateDetails,
    getCandidateEmailAndPhone,
    getCandidateDetails,
    getSimilarCandidates,
    getOtherNetworkCandidates,
    getCandidateOtherNetworkDetails,
    getKaggleDetails,
    updateCandidateStage,
    getHeaderSearchSuggestion,
    downloadProfile,
    getPendingDownloadedProfile,
    termsAcceptance,
    updateCandidatePlanNotification,
    getSubscriptionDetails
}

function filter(inputValue, page) {
    const urlencoded = new URLSearchParams();
    if (inputValue) {
        const filters = Object.keys(inputValue);
        for (const k in filters) {
            const key = filters[k]
            if (Array.isArray(inputValue[key])) {
                for (const k in inputValue[key]) {
                    urlencoded.append(`${key}[]`, inputValue[key][k].value)
                }
            } else if (typeof inputValue[key] === 'object') {
                urlencoded.append(`${key}`, inputValue[key].value)
            } else {
                urlencoded.append(`${key}`, inputValue[key])
            }
        }
    }

    return apiHit(`candidates-new?page=${page}`, urlencoded).then(user => {
        return user;
    })
}

function githubFilter(inputValue, page) {
    const urlencoded = new URLSearchParams();
    if (inputValue) {
        const filters = Object.keys(inputValue);
        for (const k in filters) {
            const key = filters[k]
            if (Array.isArray(inputValue[key])) {
                for (const k in inputValue[key]) {
                    urlencoded.append(`${key}[]`, inputValue[key][k].value)
                }
            } else if (typeof inputValue[key] === 'object') {
                urlencoded.append(`${key}`, inputValue[key].value)
            } else {
                urlencoded.append(`${key}`, inputValue[key])
            }
        }
    }

    return apiHit(`github-search?page=${page}`, urlencoded).then(user => {
        return user;
    })
}

function details(details) {
    const urlencoded = new URLSearchParams({candidate: details.uuid, fullpage: !!details.fullPage});
    return apiHit(`candidate/details`, urlencoded).then(user => {
        return user;
    })
}

function getSearchList() {
    const urlencoded = new URLSearchParams();
    return apiHit(`search/list`, urlencoded).then(user => {
        return user;
    })
}

function addCandidate(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    inputValueObj?.candidate_uuid?.forEach(function (value) {
        urlencoded.append(`candidate_uuid[]`, value)
    })
    urlencoded.delete('candidate_uuid')

    return apiHit(`candidates/add`, urlencoded).then(user => {
        return user;
    })
}

function removeCandidate(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    inputValueObj?.candidate_uuid?.forEach(function (value) {
        urlencoded.append(`candidate_uuid[]`, value)
    })
    urlencoded.delete('candidate_uuid')

    return apiHit(`candidates/remove`, urlencoded).then(user => {
        return user;
    })
}

function similarFinder(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);
    return apiHit(`candidate/finder`, urlencoded).then(user => {
        return user;
    })
}

function addCandidateDetails(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);
    return apiHit(`candidates-details`, urlencoded).then(user => {
        return user;
    })
}

function getCandidateEmailAndPhone(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);
    return apiHit(`candidate-data-finder`, urlencoded).then(user => {
        return user;
    })
}

function getSimilarCandidates(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);
    return apiHit(`candidate/similar`, urlencoded).then(user => {
        return user;
    })
}

function getCandidateDetails(candidateIds) {
    const urlencoded = new URLSearchParams();

    candidateIds.forEach(function (candidateId) {
        urlencoded.append(`uuid[]`, candidateId)
    })
    return apiHit(`fetch-candidates-details`, urlencoded).then(user => {
        return user;
    })
}

function getOtherNetworkCandidates(inputValue, page, networkName) {
    const urlencoded = new URLSearchParams();
    if (inputValue) {
        const filters = Object.keys(inputValue);
        for (const k in filters) {
            const key = filters[k]
            if (Array.isArray(inputValue[key])) {
                for (const k in inputValue[key]) {
                    urlencoded.append(`${key}[]`, inputValue[key][k].value)
                }
            } else if (typeof inputValue[key] === 'object') {
                urlencoded.append(`${key}`, inputValue[key].value)
            } else {
                urlencoded.append(`${key}`, inputValue[key])
            }
        }
    }

    return apiHit(`${networkName}/candidate-detail-other-networks?page=${page}`, urlencoded).then(result => result)
}

function getCandidateOtherNetworkDetails(userName, networkName) {
    let urlencoded = new URLSearchParams(userName);

    return apiHit(`other-networks/${networkName}/search`, urlencoded).then(result => result)
}

function getKaggleDetails(inputValue, page) {
    let urlencoded = new URLSearchParams();

    inputValue?.details?.forEach(function (value) {
        urlencoded.append(`keyword[]`, value.value)
    })

    return apiHit(`kaggle/search?page=${page}`, urlencoded).then(result => result)
}

function updateCandidateStage(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    return apiHit(`candidates/update-stage`, urlencoded).then(result => result)
}

function getHeaderSearchSuggestion(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    return apiHit(`headerSearch`, urlencoded).then(result => result)
}

function downloadProfile(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    return apiHit(`downloadProfile`, urlencoded).then(result => result)
}

function getPendingDownloadedProfile() {
    return apiHit(`pendingDownloadProfiles`, null).then(result => result)
}

function termsAcceptance() {
    return apiHit('acceptTerms', null).then(result => result)
}

function updateCandidatePlanNotification(input) {
    const urlencoded = new URLSearchParams(input);

    return apiHit('subscription-events', urlencoded).then(result => result)
}

function getSubscriptionDetails() {
    return apiHit('mySubscription', []).then(result => result)
}