import {alertActions} from "../actions";
import {history, store} from "../../helpers";
import {userConstants} from "../constants";
import {QueryClient} from "react-query";

const queryClient = new QueryClient();

const API_URL = process.env.REACT_APP_API_URL;

export {
    apiHit
};

const getCsrfToken = () => {
    return fetch(`${API_URL}/sanctum/csrf-cookie`, {method: 'GET', headers: _getHeaders()})
}

async function apiHit(url, body, signal, type = 'POST') {
    const requestOptions = {
        method: type, headers: _getHeaders(),
    };

    if (signal) {
        requestOptions['signal'] = signal;
    }
    if (body) {
        requestOptions['body'] = body;
    }

    // if(type === 'POST') {
    //    const token  = await getCsrfToken()
    //     console.log(token)
    // }

    return fetch(`${API_URL}/${url}`, requestOptions)
        .then(_handleResponse);
}

function _getHeaders() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    // myHeaders.append("Content-Type", "application/json");
    if (localStorage.getItem('api_token')) {
        const api_token = JSON.parse(localStorage.getItem('api_token'));
        if (api_token) {
            myHeaders.append("Authorization", "Bearer " + api_token);
        }
    }

    return myHeaders;
}

function _handleResponse(response) {
    return response.text().then(text => {
        // console.log(store.getState().authentication);
        const state = store.getState()
        // console.log('cancel1', state.authentication.cancel)
        if (state.authentication.cancel) {
            return Promise.reject(401);
        }
        if (!response.ok) {
            if (response.status === 401) {
                let user = JSON.parse(localStorage.getItem('user'));
                // console.log('user?.refresh_token', user?.refresh_token)
                store.dispatch({type: userConstants.REFRESH_TOKEN})
                if (user?.refresh_token) {
                    // console.log('state.authentication.cancel', state.authentication.cancel)
                    if (!state.authentication.cancel) {
                        const urlencoded = new URLSearchParams({"refresh_token": user.refresh_token});
                        // console.log('refresh api hit')
                        return fetch(`${API_URL}/token/refresh`, {
                            method: 'POST', headers: _getHeaders(), body: urlencoded
                        }).then(response => {
                            return response.text().then(text => {
                                const refreshData = text && JSON.parse(text);
                                // console.log('refreshData', refreshData);
                                if (refreshData?.data?.access_token) {
                                    const updatedUser = {
                                        ...user,
                                        api_token: refreshData?.data?.access_token,
                                        refresh_token: refreshData?.data?.refresh_token
                                    };
                                    localStorage.setItem('user', JSON.stringify(updatedUser));
                                    localStorage.setItem('api_token', JSON.stringify(updatedUser?.api_token));
                                    store.dispatch({type: userConstants.LOGIN_SUCCESS, user: updatedUser})
                                    return Promise.reject(401);
                                } else if (refreshData?.data?.error || !refreshData.data) {
                                    return logout(null);
                                }
                            })
                        })
                    }
                } else {
                    return logout(null);
                }
            } else if (response.status !== 422 && response.status !== 429) {
                return Promise.reject('Something went wrong! Please try again');
            }
        }
        const data = text && JSON.parse(text);

        if (data?.data?.debug) {
            console.log(response?.url, data?.data?.debug)
        }

        if (data.error) {
            let errorMsg = '';
            if (typeof data.error !== 'object') {
                if (data?.data) {
                    return Promise.reject(data);
                } else {
                    errorMsg = data.error;
                    if (data?.additionalError) {
                        errorMsg = `${data.error} </br></br> ${data?.additionalError}`;
                    }

                    return Promise.reject(errorMsg);
                }
            } else {
                // let errorMsg = '';
                // Object.keys(data.error).every(function (v) {
                //     errorMsg = data.error[v]
                //     return false;
                // })
                errorMsg = data.error;
                if (data?.additionalError) {
                    errorMsg = `${data.error} </br></br> ${data?.additionalError}`;
                }

                return Promise.reject(errorMsg);
            }
        }

        return data;
    });
}

function logout(message = 'Logged out') {
    localStorage.removeItem('user');
    localStorage.removeItem('api_token');
    store.dispatch({type: userConstants.LOGOUT});
    if (message) {
        store.dispatch(alertActions.success(message))
    }
    return Promise.reject('');
}
