import React, {useRef, useState} from 'react';
import moment from "moment";

const Timeline = ({timeLineData}) => {
    const [showMore, setShowMore] = useState(false);
    const [showDropDown, setDropDown] = useState('')
    const strongTagRegex = /<strong>(.*?)<\/strong>/g;

    const handleButtonClick = () => {
        setShowMore(!showMore); // Toggle the value of showMore
    };

    const convertToYearsAndMonths = (months) => {
        if (months < 12) {
            return months + " month" + (months === 1 ? "" : "s");
        } else {
            let years = Math.floor(months / 12);
            let remainingMonths = months % 12;
            let result = years + " year" + (years === 1 ? " " : "s ");
            if (remainingMonths > 0) {
                result += remainingMonths + " month" + (remainingMonths === 1 ? "" : "s");
            }
            return result;
        }
    }

    const capitalizeFirst = str => {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
        return '';
    };

    const generateContent = (timelineData) => {
        const parentTimeLineContent = []
        timelineData.forEach((position, i) => {
            parentTimeLineContent.push(<div
                className={`rp_grid_2`}>
                <div>
                    <ion-icon
                        name={position?.is_experience ? "briefcase-outline" : "school-outline"}></ion-icon>
                </div>
                <div>
                    <p>
                        {position?.title && strongTagRegex.test(position?.title) ? capitalizeFirst(position?.title.replace(strongTagRegex, '$1')) : capitalizeFirst(position?.title)}
                        {position?.title && position?.is_experience ? ' - ' : ' at '}

                        <span>{position?.company}</span>
                    </p>
                    {/* <p dangerouslySetInnerHTML={{__html: position?.title}}/>
                        {position?.company &&
                            <span>{`-${position?.company}`}</span>
                        }*/}
                    {position?.start_date && position?.end_date && (
                        <small>{position?.start_date} {position.is_experience ? ' till ' : ' - '} {position?.end_date}</small>)}
                </div>
            </div>)
        })

        /*parentTimeLineContent.length > 3 && parentTimeLineContent.push(<div className="rp_grid_2 rp_more_btn">
            <div></div>
            <div>
                <button className="show_more_btn" onClick={handleButtonClick}>
                    {showMore ? 'Show Less' : 'Show More'}
                </button>
            </div>
        </div>)*/

        return parentTimeLineContent
    }

    const dropDownContent = []
    const timeLineContent = []
    if (timeLineData) {
        const totalPositions = Object.keys(timeLineData).length - 1

        Object.keys(timeLineData)?.forEach((timeLine, j) => {
            const createdTimeLineData = []
            const dataContent = []
            const value = generateContent(timeLineData[timeLine]);
            let isGap = false;
            dropDownContent[`showParentDropDown-${j}`] = value

            timeLineData[timeLine].forEach((position, i) => {
                const gapContent = []

                if (i === 0) {
                    dataContent.push(<div className="timeline_parent" data-key={`showParentDropDown-${j}`}>
                        <div className="circle" onMouseEnter={() => {
                            setDropDown(`showParentDropDown-${j}`)
                        }}>
                            <ion-icon
                                name={position?.is_experience ? "briefcase-outline" : "school-outline"}
                            ></ion-icon>
                        </div>
                        <div className="upper-content">
                            <p>{position?.start_date ? moment(position?.string_start_date).year() : ""}</p>
                        </div>
                    </div>)
                } else {
                    dataContent.push(<div className="timeline_child" data-key={`showParentDropDown-${j}-${i}`}>
                        <div className="circle" onMouseEnter={() => {
                            setDropDown(`showParentDropDown-${j}-${i}`)
                        }}></div>
                    </div>)

                    dropDownContent[`showParentDropDown-${j}-${i}`] = <div className="rp_grid_2">
                        <div>
                            <ion-icon
                                name={position?.is_experience ? "briefcase-outline" : "school-outline"}></ion-icon>
                        </div>
                        <div>
                            <p>
                                {position?.title && strongTagRegex.test(position?.title) ? capitalizeFirst(position?.title.replace(strongTagRegex, '$1')) : capitalizeFirst(position?.title)}
                                {position?.title && position?.is_experience ? ' - ' : ' at '}

                                <span>{position?.company}</span>
                            </p>
                            {position?.start_date && position?.end_date && (
                                <small>{position?.start_date} - {position?.end_date}</small>)}
                        </div>
                    </div>
                }

                if (position?.gap_in_months > 1) {
                    isGap = true
                    gapContent.push(<div className="point blank_point" data-key={`gapContent-${j}-${i}`}>
                        <div className="timeline_parent">
                            <div className="circle" onMouseEnter={() => {
                                setDropDown(`gapContent-${j}-${i}`)
                            }}>
                                <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                            </div>
                            <div className="upper-content">
                                <p>{moment(position?.end_date).year()}</p>
                            </div>
                        </div>
                    </div>)


                    dropDownContent[`gapContent-${j}-${i}`] = <>
                        <p>{`${convertToYearsAndMonths(position?.gap_in_months)}`}</p>
                        <small>Gap duration between work</small>
                    </>
                }

                dataContent.push(gapContent)
            })
            createdTimeLineData.push(<>
                <div className={`point ${isGap && 'rp_br'}`}>{dataContent}</div>
            </>)

            if (totalPositions === j) {
                createdTimeLineData.push(<>
                    <div className="point last_point">
                        <div className="timeline_parent">
                            <div className="circle"></div>
                            <div className="upper-content">
                                <p>Present</p>
                            </div>
                        </div>
                    </div>
                </>)
            }

            timeLineContent.push(createdTimeLineData)

        })
    }

    const getTooltipStyle = (dataAttribute) => {
        if (showDropDown === dataAttribute) {
            const buttonRef = document.querySelector(`[data-key="${dataAttribute}"]`);
            if (buttonRef) {
                const containerRect = buttonRef.closest('div.timeline').getBoundingClientRect();
                const buttonRect = buttonRef.getBoundingClientRect();
                return {
                    position: 'absolute',
                    top: buttonRect.top - containerRect.top + buttonRect.height,
                    left: buttonRect.left - containerRect.left,
                    bottom: "auto",
                    right: "auto",
                };
            }
        }
        return {display: 'none'};
    };

    return (<div id="content" onMouseLeave={() => setDropDown('')}>
        <div className="timeline">
            <div className="timeline_points">
                {timeLineContent}
            </div>
            {showDropDown && <div className="bottom-content"
                                  style={{...getTooltipStyle(showDropDown)}}>
                <div className="tooltip_data">
                    <div>
                        {dropDownContent[showDropDown]}
                    </div>
                </div>
            </div>}
        </div>
    </div>);
};

export default Timeline;