import {useQuery} from "react-query";
import {candidateService} from "../../../../redux/services";

const getCandidateOtherDetails = async ({queryKey}) => {
    const [_key, {candidateDetails}] = queryKey

    return await candidateService.getCandidateOtherNetworkDetails({user: candidateDetails.userName, page: 1}, candidateDetails.type);
};
export default function useGetCandidateOtherNetworkDetailsAPI(candidateDetails, enabled) {
    return useQuery(["candidateOtherDetails", {candidateDetails}], getCandidateOtherDetails, {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: enabled
    });
}
