import {apiHit} from './helpers';
export const searchServices = {
    getSavedSearchList,
    deleteSavedSearch,
    addSearchHistory,
    getSearchHistory,
    getDashboardHistory,
    addFilter,
    updateFilter
};
function getSavedSearchList(getSearchFrom, projectId) {
    const urlencoded = new URLSearchParams(projectId);

    return apiHit(`${getSearchFrom}/search/list`, urlencoded).then(result => {
        return result;
    })
}
function deleteSavedSearch(searchIds, deleteSearchFrom) {
    const urlencoded = new URLSearchParams();

    searchIds.forEach(function (searchId) {
        urlencoded.append(`search_id[]`, searchId)
    })

    return apiHit(`${deleteSearchFrom}/search/delete`, urlencoded).then(result => {
        return result;
    })
}

function addSearchHistory(search, saveSearchFor) {
    const urlencoded = new URLSearchParams(search);
    return apiHit(`${saveSearchFor}/search-history`, urlencoded).then(result => {
        return result;
    })
}

function getSearchHistory(date, getHistoryFrom) {
    const urlencoded = new URLSearchParams(date);

    return apiHit(`${getHistoryFrom}/get-search-history`, urlencoded).then(result => {
        return result;
    })
}

function getDashboardHistory(getHistoryFrom) {
    return apiHit(`${getHistoryFrom}/history-dashboard-api`, null, null, "GET").then(result => {
        return result;
    })
}

function addFilter(inputValueObj, saveFilterFor) {
    const urlencoded = new URLSearchParams(inputValueObj);
    return apiHit(`${saveFilterFor}/search/add`, urlencoded).then(user => {
        return user;
    })
}
function updateFilter(inputValueObj, updateFilterFor) {
    const urlencoded = new URLSearchParams(inputValueObj);
    return apiHit(`${updateFilterFor}/search/update`, urlencoded).then(user => {
        return user;
    })
}