import {lazy} from "react";
import SimilarCandidates from "./components/candidates/SimilarCandidates";

const LoginV1 = lazy(() => import("./components/./login-v1"))
const ForgetPassword = lazy(() => import("./components/ForgetPassword"))
const EmailMessage = lazy(() => import("./components/EmailMessage"))
const ResetPassword = lazy(() => import("./components/ResetPassword"))
const CandidateSearch = lazy(() => import("./components/candidates/CandidateSearch"))
const Project = lazy(() => import("./components/Project"))
const AddOrEditProject = lazy(() => import("./components/Project/AddOrEditProject"))
const Password = lazy(() => import("./components/Settings"))
const Users = lazy(() => import("./components/Users/Users"))
const AddUser = lazy(() => import("./components/Users/AddUser"))
const ProjectPipeline = lazy(() => import("./components/Project/ProjectPipeline"))
const Dashboard = lazy(() => import("./components/Dashboard"))
const SimilarCompany = lazy(() => import("./components/SimilarCompany/SimilarCompany"))
const GithubCandidate = lazy(() => import("./components/candidates/GithubCandidate"))
const History = lazy(() => import("./components/Search/History"))
const SavedSearches = lazy(() => import("./components/Search/SavedSearches"))
const CandidateProfile = lazy(() => import("./components/candidates/CandidateProfile"))
const OtherNetworkCandidate = lazy(() => import("./components/candidates/OtherNetworkCandidate"))
const Kaggle = lazy(() => import("./components/candidates/Kaggle"))
const Login = lazy(() => import("./components/Login"))

const basename = process.env.REACT_APP_BASENAME;
const routes = [
    {
        "route": `/${basename}/auth/login`,
        "component": <Login/>,
        "key": "login",
    },
    {
        "route": `/${basename}/auth/login-v1`,
        "component": <LoginV1/>,
        "key": "login-v1",
    },
    {
        "route": `/${basename}/auth/forget-password`,
        "component": <ForgetPassword/>,
        "key": "forget-password",
    },
    {
        "route": `/${basename}/auth/forget-password/email-message`,
        "component": <EmailMessage/>,
        "key": "forget-password",
    },
    {
        "route": `/${basename}/auth/reset-password`,
        "component": <ResetPassword/>,
        "key": "reset-password",
    },
];
const protectedRoutes = [
    {
        "route": `/${basename}/candidate-search`,
        "component": <CandidateSearch resetData={true}/>,
        "key": "home",
    },
    {
        "route": `/${basename}/github-search`,
        "component": <GithubCandidate resetData={true}/>,
        "key": "home",
    },
    {
        "route": `/${basename}/similar-candidate/:candidate_id`,
        "component": <SimilarCandidates/>,
        "key": "similar",
    },
    {
        "route": `/${basename}/project/:project_id/search`,
        "component": <CandidateSearch resetData={true}/>,
        "key": "project-search",
    },
    {
        "route": `/${basename}/company/:company_id/projects`,
        "component": <Project/>,
        "key": "project",
    },
    {
        "route": `/${basename}/projects`,
        "component": <Project/>,
        "key": "project",
    },
    {
        "route": `/${basename}/project/add`,
        "component": <AddOrEditProject/>,
        "key": "add-project",
    },
    {
        "route": `/${basename}/project/:project_id/edit`,
        "component": <AddOrEditProject/>,
        "key": "add-project",
    },
    {
        "route": `/${basename}/settings`,
        "component": <Password/>,
        "key": "change-password",
    },
    {
        "route": `/${basename}/user/add`,
        "component": <AddUser/>,
        "key": "user-information",
    },
    {
        "route": `/${basename}/users`,
        "component": <Users/>,
        "key": "user-information",
    },
    {
        "route": `/${basename}/user/:user_id/edit`,
        "component": <AddUser/>,
        "key": "company-user",
    },
    {
        "route": `/${basename}/project/:project_id/pipeline`,
        "component": <ProjectPipeline/>,
        "key": "project-pipeline",
    },
    {
        "route": `/${basename}/dashboard`,
        "component": <Dashboard/>,
        "key": "dashboard",
    },
    {
        "route": `/${basename}/similar-company`,
        "component": <SimilarCompany resetData={true}/>,
        "key": "SimilarCompany",
    },
    {
        "route": `/${basename}/search/saved`,
        "component": <SavedSearches type='candidate'/>,
        "key": "candidateSavedSearches",
    },
    {
        "route": `/${basename}/search/history`,
        "component": <History type='candidate'/>,
        "key": "history",
    },
    {
        "route": `/${basename}/search/similar-company-saved`,
        "component": <SavedSearches type='similar-companies'/>,
        "key": "similarCompanySavedSearches",
    },
    {
        "route": `/${basename}/search/history/similar-company`,
        "component": <History type='similar-companies'/>,
        "key": "similarCompanyhistory",
    },
    {
        "route": `/${basename}/profile/:candidateId`,
        "component": <CandidateProfile/>,
        "key": "candidate-profile",
    },
    {
        "route": `/${basename}/twitter-search`,
        "component": <OtherNetworkCandidate network={{ forTwitter: true }} name="Twitter" />,
        "key": "twitter-profile",
    },
    {
        "route": `/${basename}/kaggle-search`,
        "component": <OtherNetworkCandidate network={{ forKaggle: true }} name="Kaggle" />,
        "key": "kaggle-profile",
    },
    {
        "route": `/${basename}/kaggle`,
        "component": <Kaggle />,
        "key": "kaggle"
    },
    {
        "route": `/${basename}/similar-candidate/:candidate_id/project/:project_id`,
        "component": <SimilarCandidates/>,
        "key": "similarCandidateWithProject",
    }

];

export {routes, protectedRoutes};
