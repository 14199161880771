import React, {useEffect, useRef, useState} from 'react';
import useCandidateDataAPI from "./hooks/useCandidateDataAPI";
import CandidateList from "./CandidateList";
import Paginate from "../../partials/components/Paginate";
import PropTypes from "prop-types";
import {useParams} from "react-router";
import FooterPagination from "../../partials/components/FooterPagination";
import useCloseModalOnEsc from "../../../hooks/useCloseModalOnEsc";
import startYourSearch from "../../../assets/img/start-search.png"

function CandidateListing(props) {
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    const [nextPageNum, setNextPageNum] = useState(1)
    const [checkAll, setCheckAll] = useState({})
    const [candidatesSavedInPipeline, setCandidateSavedInPipeline] = useState(false)
    const [recordStats, setRecordStats] = useState({
        totalRecords: 0, displayRecords: 0, recordPerPage: 10
    })
    const {project_id} = useParams();
    const [showCandidateDetailsModal, setShowCandidateDetailsModal] = useState({
        showModal: false, candidateId: '', position: 1
    })

    const candidateDataAPI = useCandidateDataAPI(props.network, {
        ...props.defaultValues, projectId: project_id ?? '', ...props?.candidateType
    }, nextPageNum, props?.isApiToBeCalled)

    const candidateData = candidateDataAPI.data?.data;

    const openDetailsModal = (c, p) => {
        setShowCandidateDetailsModal({showModal: true, candidateId: c, position: p})
    }

    const hideDetailModal = () => {
        setShowCandidateDetailsModal({showModal: false, candidateId: '', position: 1})
    }

    useCloseModalOnEsc(hideDetailModal)

    const getNextPage = (page) => {
        if (page) {
            setNextPageNum(page)
        } else {
            setNextPageNum(candidateData.nextPage)
        }
    }

    const listEl = useRef();

    useEffect(() => {
        setCheckAll({})
        if (!isMobile && listEl.current && recordStats.totalRecords > 0) {
            listEl.current.scrollIntoView()
        }
    }, [nextPageNum, recordStats.totalRecords, candidatesSavedInPipeline])

    const skills = props.defaultValues.skill.map(skill => skill.value.toLowerCase());

    useEffect(() => {
        if (candidateDataAPI.status === 'success') {
            setCheckAll({})
            /*if (candidateData.data.keywordAndBoolean) {
                props.updateFilters({name: 'booleanSearch', value: [{label: true, value: true}]})
            }*/
            if (candidateData) {
                setRecordStats(prevState => ({
                    ...prevState,
                    totalRecords: candidateData.total_record ?? 0,
                    displayRecords: candidateData.display_record ?? 0,
                    recordPerPage: candidateData.record_per_page ?? 10,
                }))

                if (props?.pipeline) {

                    if (candidateData?.page && candidateData?.page !== nextPageNum) setNextPageNum(candidateData?.page)
                    props.setCandidateDetails(candidateData?.project_stat ?? '')
                }
            }
        }
    }, [candidateData, candidateData?.project_stat])

    useEffect(() => {
        if (!props?.pipeline) {
            setNextPageNum(1)
        }
    }, [props.defaultValues])

    if (props.isFilterSet === false || (candidateDataAPI.status === 'success' && candidateData && candidateData.length === 0)) {
        if (recordStats.totalRecords) {
            setRecordStats(prevState => ({...prevState, totalRecords: 0, displayRecords: 0}))
        }
        return <></>;
    }

    if (props?.pipeline && recordStats.totalRecords === 0 && showCandidateDetailsModal.showModal) {
        hideDetailModal();
    }

    const isAllCandidateChecked = Object.keys(checkAll).filter((key) => checkAll[key].isChecked)?.length === candidateData?.candidates?.length;
    const checkedCandidate = Object.keys(checkAll).filter((key) => checkAll[key].isChecked);
    const handleCheckAll = (isChecked) => {
        const newObject = candidateData?.candidates.reduce((acc, crr) => {
            acc[crr.recruitplus.uuid] = {
                uuid: crr.recruitplus.uuid,
                isChecked: isChecked
            }
            return acc
        }, {})
        setCheckAll(() => newObject)
    }

    return (<div className="sh_column" ref={listEl}>
        <Paginate
            type="header"
            title="Candidates"
            nextPageNum={nextPageNum}
            isLoading={candidateDataAPI.isLoading}
            totalRecords={recordStats.totalRecords}
            displayRecord={recordStats.displayRecords}
            getNextPage={getNextPage}
            recordPerPage={recordStats.recordPerPage}
            isAllCandidateChecked={(candidateData?.candidates?.length && isAllCandidateChecked)}
            handleCheckAll={handleCheckAll}
            checkedCandidate={checkedCandidate}
            setCandidateSavedInPipeline={setCandidateSavedInPipeline}
            pipeline={props.pipeline}
            showFilterPanel={props.showFilterPanel}
        />
        {candidateDataAPI?.isSuccess && candidateData?.total_record === 0 &&
            <>
                <div className="rp_search_vc no_search_found">
                    <div className="rp_search_col">
                        <img src={startYourSearch} alt=""/>
                        <h5>No search results</h5>
                        <p>Try changing the filters or clearing the <br/> search to start over.</p>
                    </div>
                </div>
                {isMobile &&
                    <div className="mobile_start_search">
                        <button className="btn btn_fill btn_sm"
                                onClick={() => props.showFilterPanel()}>Modify your search
                        </button>
                    </div>
                }
            </>
        }
        <CandidateList
            data={candidateData}
            filters={props.defaultValues}
            skills={skills}
            status={candidateDataAPI.status}
            nextPageNum={nextPageNum}
            forGithub={props?.network?.forGithub ?? false}
            network={props.network}
            pipeline={props.pipeline}
            hideDetailModal={hideDetailModal}
            openDetailsModal={openDetailsModal}
            recordPerPage={recordStats.recordPerPage}
            details={showCandidateDetailsModal}
            candidateType={props?.candidateType}
            setCheckAll={setCheckAll}
            checkAll={checkAll}
        />
        {candidateDataAPI.status === 'success' && candidateData && candidateData.total_record > 0 &&
            <FooterPagination
                nextPageNum={nextPageNum}
                totalRecords={recordStats.totalRecords}
                getNextPage={getNextPage}
                recordPerPage={recordStats.recordPerPage}
            />}
    </div>)
}

CandidateListing.propTypes = {
    defaultValues: PropTypes.object, forGithub: PropTypes.bool, isFilterSet: PropTypes.bool,
}

export default CandidateListing