import React, {useState} from 'react';
import CardHeader from "./CardHeader";
import CardData from "./CardData";
import GithubData from "./GithubData";
import PropTypes from "prop-types";
import useGetCandidateOtherNetworkDetailsAPI from "../hooks/useGetCandidateOtherNetworkDetailsAPI";
import OtherNetworkData from "./OtherNetworkData";

function Card(props) {
    const {
        candidate,
        pipeline,
        openJsonPopup,
        openDetailsModal,
        openSaveToPipeline,
        skills,
        position,
    } = props;

    const [candidateOtherNetworkDetails, setCandidateOtherNetworkDetails] = useState({
        userName: null,
        type: null,
        value: null
    })
    const [otherNetworkDetails, setOtherNetworkDetails] = useState([])
    const [isQueryEnable, setIsQueryEnable] = useState(false)
    const getCandidateOtherDetails = useGetCandidateOtherNetworkDetailsAPI(candidateOtherNetworkDetails, isQueryEnable)

    if (getCandidateOtherDetails.isSuccess) {
        if (isQueryEnable) {
            setIsQueryEnable(false)
        }

        const isExist = otherNetworkDetails.filter((row) => row.type === candidateOtherNetworkDetails.value)

        if (isExist?.length === 0) {
            setOtherNetworkDetails(prevState => {
                return [...prevState, {
                    type: candidateOtherNetworkDetails.value,
                    value: getCandidateOtherDetails?.data?.data?.data
                }]
            })
        }
    }

    return (
        <div className="prof_layout3 user_item">
            <CardHeader
                pipeline={pipeline}
                candidate={candidate}
                openJsonPopup={openJsonPopup}
                openDetailsModal={openDetailsModal}
                openSaveToPipeline={openSaveToPipeline}
                skills={skills}
                position={position}
                showGithub={props.forGithub}
                network={props.network}
                page={props.page}
                setCandidateOtherNetworkDetails={setCandidateOtherNetworkDetails}
                candidateOtherNetworkDetails={candidateOtherNetworkDetails}
                isLoading={getCandidateOtherDetails.isLoading}
                setIsQueryEnable={setIsQueryEnable}
                setCheckAll={props.setCheckAll}
                checkAll={props.checkAll}
                filters={props.filters}
            />
            <GithubData
                showGithub={props.forGithub}
                candidate={candidate}
            />
            <CardData
                showGithub={props.forGithub}
                candidate={candidate}
                filters={props.filters}
                setCandidateOtherNetworkDetails={setCandidateOtherNetworkDetails}
                otherNetworkDetails={otherNetworkDetails}
                setIsQueryEnable={setIsQueryEnable}
                candidateOtherNetworkDetails={candidateOtherNetworkDetails}
                isLoading={getCandidateOtherDetails.isLoading}
            />
        </div>
    )
}

Card.propTypes = {
    candidate: PropTypes.object,
    pipeline: PropTypes.bool,
    skills: PropTypes.array,
    openJsonPopup: PropTypes.func,
    openDetailsModal: PropTypes.func,
    positions: PropTypes.number,
}

export default Card