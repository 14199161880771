import React, {useState} from "react";

import {CandidateCard, CandidateCardSkeleton} from "./candidateCard";
import CandidateDetails from "./CandidateDetails";
import CustomModal from "./CustomModal";
import PropTypes from "prop-types";
import {Candidate} from "../../../helpers";
import SaveToPipeline from "./SaveToPipeline";
import ErrorBoundary from "../../ErrorBoundary";
const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
function CandidateList(props) {
    const {
        data,
        skills,
        status,
        pipeline,
        filters,
        details,
        openDetailsModal,
        hideDetailModal,
        recordPerPage
    } = props

    const [saveToPipeline, setSaveToPipeline] = useState({showModal: false, candidateId: ''})
    const [jsonDataAndShowModal, setJsonDataAndShowModal] = useState({showModal: false, jsonData: []})

    const openSaveToPipeline = (c) => {
        setSaveToPipeline({showModal: true, candidateId: [c]});
    }

    const closeSaveToPipeline = () => {
        setSaveToPipeline({showModal: false, candidateId: ''});
    }

    const openJsonPopup = (event, jsonData) => {
        setJsonDataAndShowModal({showModal: true, jsonData: jsonData})
    }

    const closeJsonPopup = (event, jsonData) => {
        setJsonDataAndShowModal({showModal: false, jsonData: []})
    }

    const tempData = [];
    for (let i = 0; i < 10; i++) {
        tempData.push(<CandidateCardSkeleton key={`skeleton-${i}`}/>)
    }
    console.log()
    return (
        <>

            <div className={isMobile && (details.showModal || !data?.candidates?.length) ? 'hidemodal_open' : 'responsive_row'}>
                {status === 'loading' &&
                    tempData
                }
                {status === 'success' && data.candidates && data.candidates.map((candidate, key) => {
                    candidate = new Candidate(candidate)
                    return <CandidateCard
                        key={candidate.recruitplus.uuid}
                        position={((props.nextPageNum - 1) * recordPerPage) + (key + 1)}
                        pipeline={pipeline}
                        openJsonPopup={openJsonPopup}
                        openDetailsModal={openDetailsModal}
                        openSaveToPipeline={openSaveToPipeline}
                        candidate={candidate}
                        skills={skills}
                        forGithub={props.forGithub}
                        filters={filters}
                        page={props.nextPageNum}
                        network={props.network}
                        setCheckAll={props.setCheckAll}
                        checkAll={props.checkAll}
                    />
                })}
            </div>
            <SaveToPipeline
                saveToPipeline={saveToPipeline}
                closeSaveToPipeline={closeSaveToPipeline}
            />
            {details.showModal &&
                <ErrorBoundary>
                    <CandidateDetails
                        hideDetailModal={hideDetailModal}
                        openJsonPopup={openJsonPopup}
                        openDetailsModal={openDetailsModal}
                        filters={filters}
                        network={props.network}
                        candidateOnPage={props.nextPageNum}
                        candidate={details}
                        candidateType={props?.candidateType}
                    />
                </ErrorBoundary>
            }
            <CustomModal
                filters={filters}
                closeJsonPopup={closeJsonPopup}
                data={jsonDataAndShowModal}/>
        </>
    )
}

CandidateList.propTypes = {
    data: PropTypes.object,
    pipeline: PropTypes.bool,
    skills: PropTypes.array,
    status: PropTypes.string,
    filters: PropTypes.object,

}

export default CandidateList
