import {useMutation, useQueryClient} from "react-query";
import {candidateService} from "../../../../redux/services";
import {alertActions} from "../../../../redux/actions";
import {useDispatch} from "react-redux";

const removeCandidate = async(input) => {
    return await candidateService.removeCandidate(input);
}

export default function useRemoveCandidateAPI(){
    const queryClient = useQueryClient()
    const dispatch = useDispatch();

    return useMutation(removeCandidate, {
        onSuccess: (data, variables) => {
            if (variables?.candidate_uuid) {
                queryClient.setQueryData(['userData'], data)
                if(variables?.detailsPage) {
                    queryClient.refetchQueries(`pipelineCandidateData-${variables.detailsPage}-${variables.project}`)
                }
                queryClient.refetchQueries(`pipelineCandidateData-${variables.page}-${variables.project}`)
            }
            dispatch(alertActions.success('Removed from pipeline'));
        },
        onError: (error, variables, context) => {
            console.log(error, variables, context)
            dispatch(alertActions.error(error));

        }
    })
}