import moment from "moment";

export function Date() {
    const format = (dateString = null, format = 'MMM D, YYYY') => {
        if (!dateString) {
            return moment().format(format)
        }
        return moment(dateString).format(format)
    }

    const formatAgo = (dateString, options = {withoutSuffix: false}) => {
        return moment(dateString).fromNow(options.withoutSuffix)
    }

    return {format, formatAgo};
}

export const Months = {
    jan: '01',
    feb: '02',
    mar: '03',
    apr: '04',
    may: '05',
    jun: '06',
    jul: '07',
    aug: '08',
    sep: '09',
    oct: '10',
    nov: '11',
    dec: '12',
    january: '01',
    february: '02',
    march: '03',
    april: '04',
    june: '06',
    july: '07',
    august: '08',
    september: '09',
    october: '10',
    november: '11',
    december: '12',
}