import {useMutation, useQueryClient} from "react-query";
import {searchServices} from "../../../redux/services";
import {alertActions} from "../../../redux/actions";
const addSearchHistory = async (input) => {
    return await searchServices.addSearchHistory(input.data, input.prefix);
}

export default function useAddSearchHistoryAPI() {
    return useMutation(addSearchHistory, {
        retryDelay: (retryCount) => retryCount * 2000
    })
}