import React, {useState, useRef, useEffect} from "react";
import {Slider} from "@mui/material";
import {debounce} from "lodash";
import PropTypes from "prop-types";
import useGATrackAPI from "../../Project/hooks/useGATrackAPI";

function InputSlider(props) {

    const [input, setInput] = useState(false)
    const {getTrackOfEvent} = useGATrackAPI();

    const setSearchTextDebounced = useRef(
        debounce(searchText => props.setInputValue(searchText), 500)
    ).current;

    const setValtoGA = useRef(
        debounce(getText => getTrackOfEvent(`${props.gaTrackValue} ${props.name}`, props.name === 'experience' ? `${props.gaTrackValue} ${getText.toString().replace(',', '-')}-Exp` : `${props.gaTrackValue} ${getText.toString()} CodeScore`), 600)
    ).current;

    useEffect(() => {
        setInput(props.value)
    }, [props.value])

    const setInputVal = (value) => {
        if (value !== false) {
            setInput(value);
            setSearchTextDebounced(value)
            setValtoGA(value)
        }
    }

    let marks = [
        {
            value: 1,
            label: '1',
        },
        {
            value: 5,
            label: '5',
        },
        {
            value: 10,
            label: '10',
        },
        {
            value: 15,
            label: '15',
        },
        {
            value: 20,
            label: '20',
        },
        {
            value: 25,
            label: '25+',
        }
    ];
    if (props.marks) {
        marks = props.marks;
    }

    let inputValue = input
    if (input === false) {
        inputValue = [props.min, props.max]
    }

    return (
        <Slider
            value={inputValue}
            onChange={(e, value) => {
                setInputVal(value)
            }}
            name={props.name}
            valueLabelDisplay="auto"
            marks={marks}
            min={props.min}
            step={props.step}
            max={props.max}
        />
    )
}

InputSlider.propTypes = {
    setInputValue: PropTypes.func,
    name: PropTypes.string,
    min: PropTypes.number,
    step: PropTypes.number,
    max: PropTypes.number,
    marks: PropTypes.array,
    value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.bool,
    ])
}

export default InputSlider