/**
 * Candidate data object
 * @constructor
 * @param {object} candidate
 *
 * @property {object} recruitplus
 * @property {uuid} recruitplus.uuid
 * @property {number} recruitplus.availability_score
 * @property {string} recruitplus.availability_level
 * @property {boolean} recruitplus.cm_member
 * @property {array} recruitplus.calculated_skills
 * @property {array} recruitplus.growth_trajectory
 * @property {object} recruitplus.growth_trajectory_debug
 * @property {boolean} recruitplus.is_premium
 * @property {number} recruitplus.seniority_level_score
 * @property {number} recruitplus.current_position_duration
 * @property {number} recruitplus.current_seniority_duration
 * @property {object} recruitplus.availability_logic
 * @property {number} recruitplus.overall_median
 * @property {number} recruitplus.candidate_median
 * @property {number} recruitplus.full_time_years
 *
 * @property {object} recruitplus.coffeemug
 * @property {array} recruitplus.coffeemug.industry_interest
 * @property {string} recruitplus.coffeemug.job_stage
 *
 * @property {object} sort
 * @property {number} sort.past_founder
 * @property {number} sort.not_job_hopper
 * @property {number} sort.availability
 * @property {number} sort.premium_education
 *
 * @property {object} basic
 * @property {string} basic.location
 * @property {object} basic.social_links
 * @property {object} basic.socialLinks
 * @property {string} basic.image_url
 * @property {string} basic.fullname
 * @property {string} basic.current_title
 *
 * @property {object} basic.currentPosition
 * @property {string} basic.currentPosition.position_summary
 *
 * @property {object} other_sources
 * @property {object} other_sources.github
 * @property {array} other_sources.pinnedrepos
 * @property {number} other_sources.profile_rank
 * @property {number} other_sources.profile_score
 * @property {number} other_sources.total_repos
 * @property {number} other_sources.total_forks
 * @property {number} other_sources.total_stars
 * @property {array} other_sources.github.languages
 *
 * @property {object} other_sources.github.profile_score_debug
 * @property {number} other_sources.github.repo_score
 * @property {number} other_sources.github.achievement_score
 * @property {string} other_sources.github.contributes
 * @property {string} other_sources.github.followers
 *
 * @property {object} analytics
 * @property {string} analytics.title
 * @property {number} analytics.fulltime_years
 *
 * @property {Position[]} positions
 *
 * @property {Timeline[]} timeline
 *
 * @property {object} educations
 *
 * @property {object} explanation
 *
 */
export function Candidate(candidate) {
    const capitalizeFirst = str => {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
        return '';
    };

    if (typeof candidate?.recruitplus?.growth_trajectory_debug === "string") {
        candidate.recruitplus.growth_trajectory_debug = JSON.parse(`${candidate.recruitplus.growth_trajectory_debug}`)
    }
    if (typeof candidate?.recruitplus?.availability_logic === "string") {
        candidate.recruitplus.availability_logic = JSON.parse(`${candidate.recruitplus.availability_logic}`)
    }

    if (typeof candidate?.github?.profile_score_debug === "string") {
        candidate.github.profile_score_debug = JSON.parse(`${candidate.github.profile_score_debug}`)
    }
    const githubLanguages = candidate?.github?.calculated_languages ?? [];
    const githubSkills = [];
    githubLanguages.forEach(lang => {
        githubSkills.push({name: lang.lang, weight: lang.score});
    })

    const skills = [...(candidate?.recruitplus?.calculated_skills ?? []), ...githubSkills]
    // const arrangedSkills = skills.sort((a, b) => b.weight - a.weight)

    this.recruitplus = {
        ...candidate.recruitplus,
        availability_score: candidate?.recruitplus?.availability_score ?? 0,
        availability_level: candidate?.recruitplus?.availability_level ?? '',
        uuid: candidate?.recruitplus?.uuid,
        cm_member: candidate.recruitplus?.cm_member,
        calculated_skills: skills,
        growth_trajectory: candidate?.recruitplus?.growth_trajectory ?? 0,
        growth_trajectory_debug: candidate?.recruitplus?.growth_trajectory_debug,
        is_premium: candidate?.recruitplus?.is_premium,
        seniority_level_score: candidate?.recruitplus?.growth_trajectory_debug?.seniority_level_score ?? 0,
        current_position_duration: candidate?.recruitplus?.growth_trajectory_debug?.current_position_duration ?? 0,
        current_seniority_duration: candidate?.recruitplus?.growth_trajectory_debug?.current_seniority_duration ?? 0,
        availability_logic: candidate?.recruitplus?.availability_logic,
        overall_median: candidate?.recruitplus?.availability_logic?.overall_median ?? 0,
        candidate_median: candidate?.recruitplus?.availability_logic?.candidate_median ?? 0,
        full_time_years: candidate?.recruitplus?.full_time_years ?? 0,
        coffeemug: {
            job_role: candidate?.recruitplus?.coffeemug?.job_role,
            industry_interest: candidate?.recruitplus?.coffeemug?.industry_interest ?? [],
            objectives: candidate?.recruitplus?.coffeemug?.objectives ?? [],
            job_stage: candidate?.recruitplus?.coffeemug?.job_stage === 'Big' ? 'Late Stage' :
                (candidate?.recruitplus?.coffeemug?.job_stage === 'Small' ? 'Early Stage' :
                    candidate?.recruitplus?.coffeemug?.job_stage),
        },
    }
    this.sort = {
        ...candidate.sort,
        past_founder: candidate?.sort?.past_founder ?? 0,
        not_job_hopper: candidate?.sort?.not_job_hopper ?? 0,
        availability: candidate?.sort?.availability ?? 0,
        premium_education: candidate?.sort?.premium_education ?? 0,
    }
    this.currentPosition = {...candidate.current_position}
    this.basic = {
        ...candidate.basic,
        social_links: candidate?.basic?.social_links,
        socialLinks: candidate?.basic?.socialLinks,
        image_url: candidate?.basic?.image_url,
        fullname: capitalizeFirst(candidate?.basic?.fullname),
        current_title: capitalizeFirst(candidate?.basic?.current_title),
        fmt_location: capitalizeFirst(candidate?.basic?.fmt_location),
        location: candidate?.basic?.location ? capitalizeFirst(candidate?.basic?.location) :
            candidate?.github ? capitalizeFirst(candidate?.basic?.fmt_location) : capitalizeFirst(formatAddress({
                city: candidate?.basic?.city,
                state: candidate?.basic?.state,
                country: candidate?.basic?.country
            })),
        currentPosition: {
            position_summary: candidate?.basic?.currentPosition?.position_summary,
        },
    }
    this.other_sources = {
        ...candidate?.other_sources,
        github: {
            ...candidate?.github,
            pinnedrepos: candidate?.github?.pinnedrepos ?? [],
            profile_rank: candidate?.github?.profile_rank ?? 0,
            profile_score: candidate?.github?.profile_score ?? 0,
            languages: githubLanguages,
            rating: candidate?.github?.rating ?? 0,
            total_repos: candidate?.github?.total_repos ?? 0,
            total_forks: candidate?.github?.total_forks ?? 0,
            total_stars: candidate?.github?.total_stars ?? 0,
            profile_score_debug: {
                repo_score: candidate?.github?.profile_score_debug?.repo_score,
                achievement_score: candidate?.github?.profile_score_debug?.achievement_score,
                contributes: candidate?.github?.profile_score_debug?.contributes,
                followers: candidate?.github?.profile_score_debug?.followers,
            },
        },
    }
    this.analytics = {
        ...candidate.analytics,
        title: capitalizeFirst(candidate?.analytics?.title),
        fulltime_years: candidate?.analytics?.fulltime_years
    }
    this.positions = candidate?.positions?.map(position => new Position(position))
    this.educations = candidate?.educations?.map(education => new Education(education))
    this.explanation = {...candidate.explanation}
    this.company = {...candidate.companies}

    if (candidate?.coffeemug) {
        this.coffeemug = {...candidate.coffeemug}
    }

    if (candidate?.kaggle) {
        this.kaggle = {...candidate.kaggle}
    }
    if (candidate?.kaggle_profile) {
        this.kaggle_profile = {...candidate.kaggle_profile}
    }
    if (candidate?.pipelineData) {
        this.pipelineData = {...candidate.pipelineData}
    }
    if (candidate?.timeline) {
        this.timeline = {...candidate.timeline}
    }

    if (candidate?.similarFilters) {
        this.similarFilters = {...candidate?.similarFilters}
    }
}

function Position(position) {
    let data = {};
    data.website = position?.website;
    data.industries = position?.industries;
    data.endDate = position?.endDate;
    data.is_fulltime = position?.is_fulltime;
    data.company_size_on_linkedin = position?.company_size_on_linkedin;
    data.title = position?.title;
    data.duration = position?.duration;
    data.li_username = position?.li_username;
    data.companyId = position?.companyId;
    data.glassdoor_ratings = position?.glassdoor_ratings ?? [];
    data.company = position?.company;
    data.location = position?.location;
    data.startDate = position?.startDate;
    data.seniority = position?.seniority;
    data.c_seniority = position?.c_seniority;
    data.c_role = position?.c_role;
    data.score = position?.score;
    data.seniority_level_score = position?.seniority_level_score;
    data.company_size = position?.company_size;
    data.specialities = position?.specialities;
    data.companyImage = position?.logo_url;
    data.description = position?.description;

    return {...data, ...position}
}

function Education(education) {
    let data = {}
    data.major = education?.major;
    data.school = education?.school_text ? education?.school_text : education?.school;
    data.degree = education?.degree;
    data.startYear = education?.startYear;
    data.endYear = education?.endYear;
    data.description = education?.description;
    data.school_url = education?.school_url;
    data.activities_and_societies = education?.activities_and_societies;

    return {...data, ...education}
}

const formatAddress = ({city, state, country}) => {
    const fullAddress = []

    if (city) fullAddress.push(city)
    if (state) fullAddress.push(state)
    if (country) fullAddress.push(country)

    return fullAddress.join(', ')
}