import { useQuery } from "react-query";
import {masterService} from "../../../../redux/services";

const searchMasters = async ({queryKey}) => {
    const [_key, {text, props:{url, allOptions, params, isCreatable}} ] = queryKey
    if (allOptions) {
        const data = allOptions.map(option => ({
            value: option.value || option.name, label: option.name
        }));
        if (isCreatable || isCreatable === undefined) {
            data.unshift({value: text, label: text})
        }
        return new Promise((resolve) => resolve(data))
    } else {
        let data = await masterService.masterData(url, text, params);
        if (!Array.isArray(data.data.data)) {
            return data.data;
        }
        data = data.data.data
        /*let check = true
        data = data.data.data.map(option => {
            if (option.value.toLowerCase() === text.toLowerCase()) {
                check = false
            }
            if (option.type) {
                return {value: option.value || option.name, label: option.name, type: option.type}
            } else {
                return {value: option.value || option.name, label: option.name}
            }
        });
        if (text.length > 0 && check && (isCreatable || isCreatable === undefined)) {
            data.unshift({value: text, label: text})
        }*/
        return data;

    }
};

export default function useMasterDataAPI(text, props, enabled) {
    return useQuery(["masterData", {text, props}], searchMasters, {refetchOnWindowFocus: false, enabled,
        retryDelay: (retryCount) => retryCount * 2000
    });
}
