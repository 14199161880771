import {useMutation, useQueryClient} from "react-query";
import {searchServices} from "../../../redux/services";
import {useDispatch} from "react-redux";
import {alertActions} from "../../../redux/actions";

const addFilter = async (input) => {
    return await searchServices.addFilter(input.data, input.prefix);
}


export default function useAddFilterAPI() {
    const queryClient = useQueryClient()
    const dispatch = useDispatch()
    return useMutation(addFilter, {
        onSuccess: (data, variables) => {
            if (variables.id) {
                queryClient.setQueryData(['userData', {project_id: variables.id} ], data)
            }

            if(variables?.data?.search_data) {
                queryClient.refetchQueries('savedSearch')
            }

            dispatch(alertActions.success(data.status));
        },
        onError: (error, variables, context) => {
            console.log(error, variables, context)
        }
    })
}