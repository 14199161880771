import {useMutation} from "react-query";
import {candidateService} from "../../../../redux/services";

const updateCandidateNotification = async (input) => {
    return await candidateService.updateCandidatePlanNotification(input);
}

export default function useUpdateCandidatePlanNotificationAPI() {

    return useMutation(updateCandidateNotification, {
        onSuccess: (data, variables) => {
        }, onError: (error, variables, context) => {

        }
    })
}