import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {history} from "../../helpers";
import ErrorBoundary from "../ErrorBoundary";
import {masterService} from "../../redux/services";
import Layout from "../partials/Layout";
import FilterPanel from "./partials/FilterPanel";
import CandidateListing from "./partials/CandidateListing";
import PropTypes from "prop-types";
import _ from 'lodash';
import {useFilter} from "./hooks/useFilter";
import startYourSearch from "../../assets/img/start-search.png";

function CandidateSearch(props) {

    const initialFilters = {
        keyword: [],
        location: [],
        locationDistance: [],
        jobTitle: [],
        currentJobTitle: [],
        skill: [],
        mandatorySkill: [],
        industry: [],
        company: [],
        seniority: [],
        expertise: [],
        availability: [],
        gender: [],
        techLevel: [],
        experience: [],
        education: [],
        degree: [],
        major: [],
        currentJobYears: [],
        excludeJobTitle: [],
        excludeCompany: [],
        companySize: [],
        booleanSearch: 'cmo',
        disableRadius: false,
        pastJobTitle: true,
        pastIndustry: true,
        newJobTitle: false,
        excludePastJobTitle: true,
        pastCompany: true,
        member: [],
        premiumInstitutes: false,
        premiumDegree: false,
        kaggle: false,
        hideViewedProfile: false,
        skillsAndOr: 'or'
    }
    const initialRelated = {
        jobTitle: [],
        skill: [],
        mandatorySkill: [],
        industry: [],
        company: [],
        expertise: [],
        education: [],
        excludeJobTitle: [],
        excludeCompany: [],
        experience: [],
        currentJobYears: [],
        keyword: []
    }

    const {
        filter,
        related,
        savedFilterDetails,
        isFilterSet,
        updateFilters,
        handleCheckbox,
        resetFilter,
        setRelatedValues,
        setFilter,
        setSavedFilterDetails,
        filterOptions
    } = useFilter({
        initialFilters,
        initialRelated,
        filters: props.filters,
        filterType: 'candidateSearch',
        similar: props.similar ?? false
    })
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    const [showFilterPanel, setShowFilterPanel] = useState(!isMobile);

    const handleModal = () => {
        setShowFilterPanel(!showFilterPanel);
    }

    function HeaderButton() {
        return <div className="btn_gap">
            <a href="#" className="btn btn_fill">Import Candidate</a>
            <a href="#" className="btn btn_outline">Add Candidate</a>
        </div>
    }

    return (<Layout
        title={props.similar ? "Profiles Similar to" : "Candidates"}
        similarCandidateData={props.similar ? props?.similarCandidate : ''}>
        <ErrorBoundary>
            <FilterPanel
                updateFilters={updateFilters}
                filterOptions={filterOptions}
                defaultValues={{filter, related}}
                handleCheckbox={handleCheckbox}
                resetFilter={resetFilter}
                setRelatedValues={setRelatedValues}
                showIcon={true}
                savedFilterDetails={savedFilterDetails}
                isFilterSet={isFilterSet}
                setFilter={setFilter}
                setSavedFilterDetails={setSavedFilterDetails}
                showFilterPanel={showFilterPanel}
                setShowFilterPanel={setShowFilterPanel}
            />
        </ErrorBoundary>
        <div className={`prof_cont ${isMobile && 'remove_position'}`}>
            {!isFilterSet &&
                <>
                    <div className="rp_search_vc mobile_nodata">
                        <div className="rp_search_col">
                            <img src={startYourSearch} alt=""/>
                            <h5>Start a search</h5>
                            <p>You haven't started a search yet. Use the <br/>search filters to start finding
                                candidates.
                            </p>
                        </div>
                        {isMobile &&
                            <div className="mobile_start_search">
                                <button className="btn btn_fill btn_sm"
                                        onClick={() => setShowFilterPanel(!showFilterPanel)}>Start your search
                                </button>
                            </div>
                        }
                    </div>
                </>
            }
            <ErrorBoundary>
                <CandidateListing
                    updateFilters={updateFilters}
                    similar={props.similar}
                    candidate={props.similarCandidate}
                    haveFilter={props?.haveFilter}
                    defaultValues={filter}
                    isFilterSet={isFilterSet}
                    isApiToBeCalled={!(JSON.stringify(filter) === JSON.stringify(initialFilters))}
                    showFilterPanel={handleModal}
                />
            </ErrorBoundary>
        </div>
    </Layout>);
}

CandidateSearch.propTypes = {
    filters: PropTypes.object, similar: PropTypes.bool, similarCandidate: PropTypes.object,
}

export default CandidateSearch;
