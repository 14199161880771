import React, {useEffect} from "react";
import Header from "./Header";
import {useNavigate} from "react-router-dom";

const userDefaultImage = require('../../assets/img/default.png')

export default function Layout(props) {

    let classVal = false;
    if (props.classVal) {
        classVal = props.classVal;
    } else if (props.classVal === '') {
        classVal = ' '
    }

    const navigate = useNavigate();

    useEffect(() => {
        const script = document.createElement("script");

        script.src = process.env.PUBLIC_URL + '/assets/js/layout.js';
        script.async = true;

        document.body.appendChild(script);

        return () => document.body.removeChild(script)
    }, [])

    return (<>
        <Header/>
        <div className="user_template">
            {props.similarCandidateData ? <div className="similar_user">
                <div className="similar_text">
                    {props.title}
                </div>
                <div className="similar_user_details_area">
                    <div className="userimage">
                        <img className="similar_user_img"
                             src={props.similarCandidateData.basic.image_url ?? userDefaultImage}
                             onError={({currentTarget}) => currentTarget.src = userDefaultImage} alt=""/>
                    </div>
                    <div className="user_details">
                        <div className="template_user_name">
                            <h3>
                                <span
                                    onClick={(() => navigate(`${process.env.REACT_APP_BASENAME}/profile/${props.similarCandidateData.recruitplus.uuid}`))}
                                    className="rp-link">{props.similarCandidateData.basic.fullname}</span>
                            </h3>
                        </div>
                        <div className="user_designation">
                            {props.similarCandidateData.currentPosition?.title &&
                                <span
                                    className="rp_current_role">{props.similarCandidateData.currentPosition?.title ?? ''}</span>
                            }
                            {props.similarCandidateData.currentPosition?.company && <>
                                <span className="rp_text">@</span>
                                <span
                                    className="rp_company">{props.similarCandidateData.currentPosition?.company ?? ''}</span>
                            </>}
                            {props.similarCandidateData.currentPosition?.c_seniority &&
                                <><span className="rp_tags hide_rp_tag_mob">
                                    <span className="dot"></span>
                                        <span
                                            className="rp_tag_text">{props.similarCandidateData.currentPosition?.c_seniority}</span>
                                </span>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="close_sp" onClick={() => navigate(`${process.env.REACT_APP_BASENAME}/candidate-search`)}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 13L1 1M13 1L1 13" stroke="#E77B35" strokeOpacity="0.9" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </div>
                {/*<div className="show_more">
                    <button>
                        Show more <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.6244 11.6245C5.48315 11.4832 5.40918 11.3035 5.4025 11.0852C5.39634 10.8669 5.46388 10.6871 5.60514 10.5458L9.38042 6.77056H0.770465C0.552167 6.77056 0.369053 6.69659 0.221124 6.54867C0.073708 6.40125 0 6.21839 0 6.00009C0 5.7818 0.073708 5.59868 0.221124 5.45075C0.369053 5.30334 0.552167 5.22963 0.770465 5.22963H9.38042L5.60514 1.45435C5.46388 1.3131 5.39634 1.13332 5.4025 0.915022C5.40918 0.696724 5.48315 0.516948 5.6244 0.375696C5.76565 0.234444 5.94543 0.163818 6.16372 0.163818C6.38202 0.163818 6.5618 0.234444 6.70305 0.375696L11.7881 5.46077C11.8652 5.52497 11.9199 5.6051 11.9522 5.70115C11.9841 5.79772 12 5.89737 12 6.00009C12 6.10282 11.9841 6.19913 11.9522 6.28902C11.9199 6.37891 11.8652 6.46237 11.7881 6.53942L6.70305 11.6245C6.5618 11.7657 6.38202 11.8364 6.16372 11.8364C5.94543 11.8364 5.76565 11.7657 5.6244 11.6245Z"
                            fill="#E77B35"/>
                    </svg>
                    </button>
                </div>*/}
            </div> : <div className="pro_title_area">
                <h2 dangerouslySetInnerHTML={{__html: props.title ?? 'Talent Mug'}}></h2>
                {props.buttons}
            </div>}
            {props.children &&
                <div className={classVal ? classVal : `profile_container`}>
                    {props.children}
                </div>
            }

        </div>
    </>)
}
