import React, {useState} from 'react';
import CreatableSelect from 'react-select/creatable';
import PropTypes from "prop-types";
import useGATrackAPI from "../../Project/hooks/useGATrackAPI";

const CustomOption = (props) => {
    const {children, innerProps} = props;
    return (<div {...innerProps} title={children}>
        {children}
    </div>);
};
function FilterTextInput(props) {
    const components = {
        DropdownIndicator: null,
    }

    const [state, setState] = useState('');
    const {getTrackOfEvent} = useGATrackAPI();

    const createOption = (label) => ({
        label,
        value: label,
    })

    const handleChange = (value, actionMeta) => {
        props.handleChange(value, props.name)
    };
    const handleInputChange = (inputValue) => {
        setState(inputValue);
        props.handleChildProp(state);
    };

    const handleKeyDown = (event) => {
        if (!state) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setState('');
                const gaTrackValue = props.gaTrackValue;
                getTrackOfEvent(`${gaTrackValue} keyword`, `${gaTrackValue} keyword ${state}`);
                const alreadyAdded = props.defaultValue.filter(keyword => keyword.label.toLowerCase() === state.toLowerCase())
                if (alreadyAdded.length) return;

                props.handleChange([...props.defaultValue, createOption(state)], props.name)
                event.preventDefault();
        }
    };

    const formatOptionLabel = ({label}) => <CustomOption>{label}</CustomOption>;

    return (
        <CreatableSelect
            onBlur={props.onBlur}
            components={components}
            inputValue={state}
            placeholder={props.placeholder}
            isClearable
            classNamePrefix="rp-select"
            className="rp-select-container"
            isMulti
            menuIsOpen={false}
            onChange={handleChange}
            onInputChange={handleInputChange}
            onKeyDown={handleKeyDown}
            value={props.defaultValue}
            formatOptionLabel={formatOptionLabel}
        />
    );
}

FilterTextInput.propTypes = {
    handleChange: PropTypes.func,
    name: PropTypes.string,
    defaultValue: PropTypes.array,
}

export default FilterTextInput