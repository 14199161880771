import {useMutation} from "react-query";
import {useDispatch} from "react-redux";

import {userService} from "../../../redux/services";
import {alertActions} from "../../../redux/actions";

const removeUserToken = async (input) => {
    return await userService.removeUserToken(input.email, input.uuid)
}

export default function useRemoveUserTokenAPI() {
    const dispatch = useDispatch();

    return useMutation(removeUserToken, {
        onSuccess: (data, variables) => {
            dispatch(alertActions.success(data?.message.toString()));
        },
        onError: ({error}, variables, context) => {
            // console.log(error, variables, context)
            dispatch(alertActions.error(error.toString()));
        }
    })
}