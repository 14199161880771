import React, {useEffect, useState} from 'react';
import AsyncDropdown from "./AsyncDropdown";
import FilterTextInput from "./FilterTextInput";
import {useParams} from "react-router";
import useAddFilterAPI from "../../Search/hooks/useAddFilterAPI";
import LeftPanel from "../../partials/components/LeftPanel";
import InputSlider from "./InputSlider";
import PropTypes from "prop-types";
import useUpdateFilterAPI from "../../Search/hooks/useUpdateFilterAPI";
import {isOnlyWhiteSpace} from "../../../helpers";
import useAddSearchHistoryAPI from "../../Search/hooks/useAddSearchHistoryAPI";
import {styled} from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import {FormGroup} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import InputButton from "../../partials/components/InputButton";
import useGATrackAPI from "../../Project/hooks/useGATrackAPI";

function FilterPanel(props) {

    const {project_id} = useParams();

    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [value, setValue] = React.useState(null);
    const [openSaveFilter, setOpenSaveFilter] = useState(false)
    const [expInputValue, setExpInputValue] = useState({expFrom: '', expTo: ''})
    const addSearchHistoryAPI = useAddSearchHistoryAPI();
    const addTheFilter = useAddFilterAPI();
    const updateFilter = useUpdateFilterAPI();
    const [inputValue, setInputValue] = useState('');

    const defaultValues = {...props.defaultValues};

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name || isOnlyWhiteSpace(name)) {
            setError('Please add a filter name')
            return
        }

        if (!props.isFilterSet) {
            setError('Please select any filter')
            return
        }

        let data = {}
        if (project_id) {
            data = {search_name: name, project_id: project_id, search_data: JSON.stringify(defaultValues)}
        } else {
            data = {search_name: name, search_data: JSON.stringify(defaultValues)}
        }

        addTheFilter.mutate({data, prefix: 'candidate'})
    }

    const handleChildProp = (value) => {
        setInputValue(value);
    };
    const createOption = (label) => ({
        label,
        value: label,
    })

    const mobileKeyword = () => {

        const isAlreadyAdded = defaultValues.filter.keyword.filter((row) => row.label.toLowerCase() === inputValue.toLowerCase()).length
        if (!isAlreadyAdded && inputValue) {
            handleInputChange([...defaultValues.filter.keyword, createOption(inputValue)], "keyword")
        }
        props.setShowFilterPanel(!props.showFilterPanel)

    }


    const handleUpdateSubmit = (e) => {
        e.preventDefault();

        if (!name || isOnlyWhiteSpace(name)) {
            setError('Please add a filter name')
            return
        }

        if (!props.isFilterSet) {
            setError('Please select any filter')
            return
        }

        let data = {}
        if (project_id) {
            data = {
                search_id: props.savedFilterDetails.id,
                search_name: name,
                project_id: project_id,
                search_data: JSON.stringify(defaultValues)
            }
        } else {
            data = {
                search_id: props.savedFilterDetails.id, search_name: name, search_data: JSON.stringify(defaultValues)
            }
        }

        updateFilter.mutate({data, prefix: 'candidate'})

        setError('')
        setOpenSaveFilter(false)
        props.setSavedFilterDetails(null)
        setName('')
    }
    const selectChange = (url) => {
        return (value, name) => {
            props.updateFilters({url, value, name});
        }
    }
    const {getTrackOfEvent} = useGATrackAPI();
    const handleRadioBtn = (event) => {
        const {name, value} = event.target
        getTrackOfEvent(`cdt ${name}`, `cdt ${value} member`)
        props.updateFilters({name, value: [{label: value, value}]})
    }

    const handleRelated = (url, value, name) => {
        return () => {
            getTrackOfEvent(`cdt ${name} suggestions`, `cdt ${value}`);
            const alreadyAdded = defaultValues.filter?.[name]?.filter(fil => fil?.label.toLowerCase() === value.toLowerCase())
            if (alreadyAdded.length) return;

            props.updateFilters({url, value, name});
        }
    }
    const handleInputChange = (value, name) => {
        props.updateFilters({value, name, type: 'input'})
        props.updateFilters({url: 'keyword/related/search', value, name: 'keyword'})
    }

    useEffect(() => {
        if (props?.isFilterSet && props?.defaultValues) {
            addSearchHistoryAPI.mutate({data: {search: JSON.stringify(props.defaultValues)}, prefix: 'candidate'})
            addSearchHistoryAPI.reset();
        }

        if (defaultValues.filter?.experience?.[0]?.value && (defaultValues.filter?.experience?.[0]?.value.split('-')[1] !== expInputValue.expTo || defaultValues.filter?.experience?.[0]?.value.split('-')[0] !== expInputValue.expFrom)) {
            setExpInputValue(prevState => ({
                ...prevState,
                expTo: defaultValues.filter?.experience?.[0]?.value.split('-')[1],
                expFrom: defaultValues.filter?.experience?.[0]?.value.split('-')[0]
            }))
        }

        if (!defaultValues.filter?.experience?.[0]) {
            setExpInputValue(prevState => ({...prevState, expTo: '', expFrom: ''}))
        }

    }, [props.defaultValues])

    function calculateValue(value) {
        switch (value) {
            case 1:
                return 1;
            case 2:
                return 10;
            case 3:
                return 50;
            case 4:
                return 100;
        }
    }

    function calculateMark(value) {
        switch (value) {
            case 1:
                return 1;
            case 10:
                return 2;
            case 50:
                return 3;
            case 100:
                return 4;
            default:
                return 0;
        }
    }

    const marks = [1, 2, 3, 4].map((value) => ({
        value, label: calculateValue(value)
    }));

    const BpIcon = styled('span')(({theme}) => ({
        background: 'white',
        borderRadius: '50%',
        width: 15,
        height: 15,
        boxShadow: theme.palette.mode === 'dark' ? '0 0 0 1px rgb(16 22 26 / 40%)' : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    }));

    const BpCheckedIcon = styled(BpIcon)({
        backgroundColor: '#dc0022', '&:before': {
            display: 'block',
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
            width: 8.97,
            height: 8.97,
            background: '#FFFFFF',
            boxShadow: '0px 2px 4px rgb(0 0 0 / 25%)',
            borderRadius: '50%',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto',
        }, 'input:hover ~ &': {
            backgroundColor: '#dc0022',
        },
    });

    function BpRadio(props) {
        return (<Radio
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon/>}
            icon={<BpIcon/>}
            {...props}
        />);
    }

    if (addTheFilter.isSuccess) {
        setError('')
        setName('');
        setOpenSaveFilter(false)
        addTheFilter.reset()
    }
    const handleExperienceInputs = (event) => {
        const {name, value} = event.target;

        if (name === 'expFrom' && (value === "" || (value >= 0 && value <= 25 && value.indexOf('.') === -1 && event.keyCode !== 190))) {
            setExpInputValue(prevState => ({...prevState, expFrom: value}))
        }

        if (name === 'expTo' && (value === "" || (value >= 0 && value <= 25 && value.indexOf('.') === -1 && event.keyCode !== 190))) {
            setExpInputValue(prevState => ({...prevState, expTo: value}))
        }
    }

    useEffect(() => {
        if (expInputValue.expFrom && expInputValue.expTo && +expInputValue.expTo >= +expInputValue.expFrom) {
            props.updateFilters({
                value: [{
                    label: `${expInputValue.expFrom}-${expInputValue.expTo}`,
                    value: `${expInputValue.expFrom}-${expInputValue.expTo}`
                }], name: 'experience'
            })
            getTrackOfEvent('cdt experience', `cdt ${expInputValue.expFrom}-${expInputValue.expTo} experience`)
        }
    }, [expInputValue])

    return (<LeftPanel
            saveFilter={true}
            openSaveFilter={openSaveFilter}
            setOpenSaveFilter={setOpenSaveFilter}
            name={name}
            setName={setName}
            setError={setError}
            error={error}
            handleSubmit={props.savedFilterDetails ? handleUpdateSubmit : handleSubmit}
            resetFilter={props.resetFilter}
            showIcon={props.showIcon}
            savedFilterDetails={props.savedFilterDetails}
            type='candidate'
            setFilter={props.setFilter}
            setSavedFilterDetails={props.setSavedFilterDetails}
            showSavedSearch={true}
            isLoading={props.savedFilterDetails ? updateFilter.isLoading : addTheFilter.isLoading}
            showFilterPanel={props.showFilterPanel}
            pageTitle="Candidates"
        >
            <div className="content_box">
                <div className="title pt_14">
                    <h3>Keyword {defaultValues.filter.keyword.length > 0 &&
                        <span className="count">{defaultValues.filter.keyword.length}</span>} </h3>
                </div>
                <FilterTextInput
                    name="keyword"
                    gaTrackValue="cdt"
                    defaultValue={defaultValues.filter.keyword}
                    handleChange={handleInputChange}
                    placeholder='Keyword anywhere in profile'
                    updateFilter={props.updateFilters}
                    handleChildProp={handleChildProp}
                />
                {defaultValues.related.keyword.length !== 0 && <div className="select_job_area" id="skill-suggestion">
                    <div className="suggestions_row">
                        <div className="suggestions">
                            <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.34147 15.2065L4.85173 16.1416C4.6031 16.2082 4.34521 16.0593 4.27858 15.8107C4.21199 15.5621 4.36088 15.3041 4.60947 15.2375L8.09921 14.3025C8.34784 14.2359 8.60573 14.3847 8.67236 14.6334C8.73902 14.882 8.59006 15.1399 8.34147 15.2065ZM6.72569 4.48215C6.69443 4.90755 6.4538 5.14733 6.08021 5.14126C5.70825 5.13526 5.43754 4.88033 5.44647 4.49215C5.45514 4.11444 5.69195 3.86633 6.0948 3.87074C6.4974 3.87507 6.69695 4.11296 6.72569 4.48215ZM11.1996 3.04845C9.81132 0.755559 7.06169 -0.358477 4.50721 0.387856C2.2717 1.04104 0.814106 2.51711 0.284106 4.78722C-0.240486 7.03411 0.412476 8.9667 2.06503 10.5852C2.45259 10.9648 2.80892 11.4245 3.0274 11.9152C3.24644 12.407 3.31666 12.5607 3.85018 12.4316C4.59114 12.2523 5.32217 12.0321 6.06299 11.8521C6.44277 11.7599 6.58706 11.5478 6.58454 11.165C6.5744 9.61981 6.5778 8.07451 6.58343 6.52929C6.58465 6.17952 6.47473 5.89152 6.0984 5.88278C5.71743 5.87392 5.6008 6.15229 5.60399 6.50889C5.6128 7.5107 5.60666 8.51259 5.60658 9.51444C5.60654 11.1261 5.60658 11.1261 4.02188 11.3577C3.41944 10.6674 2.87084 10.0468 2.33103 9.41873C0.393624 7.16444 0.74792 3.832 3.16595 2.10604C4.90314 0.866003 6.76928 0.797004 8.62984 1.844C10.3574 2.81622 11.2972 4.81918 11.0481 6.75677C10.8817 8.05114 10.2637 9.08436 9.34691 9.97255C8.79365 10.5086 8.28951 11.0857 8.10802 11.8608C8.02313 12.2233 7.81988 12.4031 7.46869 12.4897C7.53432 12.4287 3.68344 13.5077 3.68344 13.5077C3.43981 13.5729 3.29392 13.8257 3.35921 14.0692L3.36925 14.1069C3.43455 14.3505 3.68725 14.4964 3.93088 14.4312C3.93088 14.4312 8.34939 13.2438 8.40732 13.2274C8.8165 13.1145 9.03921 12.8232 9.04854 12.4074C9.06247 11.7867 9.33217 11.3036 9.77106 10.8912C10.006 10.6704 10.2399 10.4476 10.4611 10.2132C12.2861 8.27751 12.5873 5.34055 11.1996 3.04845Z"
                                    fill="#65676B"/>
                            </svg>
                            <span>More suggestions</span>
                        </div>
                        <ul>
                            {defaultValues.related.keyword.map((keyword) => (<li key={keyword.name}
                                                                                 onClick={handleRelated([{
                                                                                     url: 'keyword/related/search',
                                                                                     name: 'keyword'
                                                                                 }], keyword.name, 'keyword')}><a
                                href="#"><span><ion-icon
                                name="add-outline"></ion-icon></span>{keyword.name}</a></li>))}
                        </ul>
                    </div>
                </div>}

                <div className="keyword_options">
                    <FormControl>
                        <RadioGroup
                            sx={{rowGap: '3px'}}
                            value={defaultValues.filter.booleanSearch}
                            aria-labelledby="demo-customized-radios"
                            name="booleanSearch"
                            onChange={(e) => {
                                if (defaultValues.filter.keyword.length === 0) return;
                                props.updateFilters({name: 'booleanSearch', value: e.target.value})
                            }}
                        >
                            <FormControlLabel value="cmo" control={<BpRadio/>} label="CoffeeMug Optimised"/>
                            <FormControlLabel value="and" control={<BpRadio/>} label="And"/>
                            <FormControlLabel value="or" control={<BpRadio/>} label="Or"/>
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            <div className="content_box mb_68">
                <div className="title">
                    <h3>Locations
                        {defaultValues.filter.location.length > 0 &&
                            <span className="count">{defaultValues.filter.location.length}</span>}
                    </h3>
                </div>

                <AsyncDropdown
                    placeholder="Candidate geographical locations"
                    name="location"
                    gaTrackValue="cdt"
                    defaultValue={defaultValues.filter.location}
                    filterOptions={props.filterOptions?.location}
                    url="location/master/search"
                    handleSelectChange={selectChange()}
                />

                {/*<AsyncDropdown*/}
                {/*    name="locationDistance"*/}
                {/*    isMultiple={false}*/}
                {/*    isAsync={false}*/}
                {/*    isCreatable={false}*/}
                {/*    isDisabled={defaultValues.filter.disableRadius}*/}
                {/*    defaultValue={defaultValues.filter.locationDistance}*/}
                {/*    allOptions={[{"name": "10KM"}, {"name": "50KM"}, {"name": "100KM"}]}*/}
                {/*    handleSelectChange={selectChange()}*/}
                {/*/>*/}
                {/*<div className="distance_slider mt_25">
                    <label className="label_style">Radius (Select 1 location)</label>
                    <Slider
                        value={calculateMark(+defaultValues.filter?.locationDistance?.[0]?.value?.replace('KM', '') ?? 1)}
                        step={null}
                        onChange={(_, newValue) => {
                            if (newValue !== value) {
                                props.updateFilters({name: 'locationDistance', value: [{label: newValue, value: `${calculateValue(newValue)}KM`}]})
                                setValue(newValue);
                            }
                        }}
                        marks={marks}
                        scale={calculateValue}
                        min={1}
                        max={4}
                        disabled={defaultValues.filter.disableRadius}
                    />
                </div>*/}
            </div>

            <div className="content_box mb_23 mb_16">
                <div className="title">
                    <h3>Job Titles
                        {defaultValues.filter.jobTitle.length > 0 &&
                            <span className="count">{defaultValues.filter.jobTitle.length}</span>
                        }
                    </h3>
                    <div className="incl_area">
                        <h5>Include Past? </h5>
                        {/*<div className="form-check form-switch">*/}
                        {/*    <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox} role="switch" name="pastJobTitle" checked={defaultValues.filter.pastJobTitle} id="flexSwitchCheckChecked"/>*/}
                        {/*</div>*/}
                        <div className="toggle-switch">
                            <label>
                                <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox}
                                       role="switch" name="pastJobTitle"
                                       checked={defaultValues.filter.pastJobTitle} id="flexSwitchCheckChecked"/>
                                <span>
                                    <small></small>
                                </span>
                            </label>
                        </div>
                    </div>
                    {/*<div className="incl_area">
                        <h5>New Sort? </h5>
                        <div className="toggle-switch">
                            <label>
                                <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox}
                                       role="switch" name="newJobTitle"
                                       checked={defaultValues.filter.newJobTitle} id="flexSwitchCheckChecked"/>
                                <span>
                                    <small></small>
                                </span>
                            </label>
                        </div>
                    </div>*/}
                </div>

                <div className="clearfix"></div>
                <div className="placeholder_ui include_column">
                    <AsyncDropdown
                        styles={{
                            control: (styles, {data}) => ({
                                ":before": {
                                    content: '"Include"',
                                    padding: "0px 12px",
                                    width: "74px",
                                    height: "21px",
                                    background: "#dc0022",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#FFFFFF",
                                    marginTop: "6px",
                                }
                            }), // clearIndicator: (styles) => ({
                            //     ":after": {
                            //         content: '""'
                            //     }
                            // })
                        }}
                        placeholder="Candidate job titles"
                        name="jobTitle"
                        gaTrackValue="cdt"
                        defaultValue={defaultValues.filter.jobTitle}
                        filterOptions={props.filterOptions?.jobTitle}
                        url="title/master/search"
                        handleSelectChange={selectChange([{
                            url: 'title-to-title/related/search', name: 'jobTitle'
                        }, {url: 'title-to-skill/related/search', name: 'skill'}])}
                    />
                    {defaultValues.related.jobTitle.length !== 0 &&
                        <div className="select_job_area" id="skill-suggestion">
                            <div className="suggestions_row">
                                <div className="suggestions">
                                    <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.34147 15.2065L4.85173 16.1416C4.6031 16.2082 4.34521 16.0593 4.27858 15.8107C4.21199 15.5621 4.36088 15.3041 4.60947 15.2375L8.09921 14.3025C8.34784 14.2359 8.60573 14.3847 8.67236 14.6334C8.73902 14.882 8.59006 15.1399 8.34147 15.2065ZM6.72569 4.48215C6.69443 4.90755 6.4538 5.14733 6.08021 5.14126C5.70825 5.13526 5.43754 4.88033 5.44647 4.49215C5.45514 4.11444 5.69195 3.86633 6.0948 3.87074C6.4974 3.87507 6.69695 4.11296 6.72569 4.48215ZM11.1996 3.04845C9.81132 0.755559 7.06169 -0.358477 4.50721 0.387856C2.2717 1.04104 0.814106 2.51711 0.284106 4.78722C-0.240486 7.03411 0.412476 8.9667 2.06503 10.5852C2.45259 10.9648 2.80892 11.4245 3.0274 11.9152C3.24644 12.407 3.31666 12.5607 3.85018 12.4316C4.59114 12.2523 5.32217 12.0321 6.06299 11.8521C6.44277 11.7599 6.58706 11.5478 6.58454 11.165C6.5744 9.61981 6.5778 8.07451 6.58343 6.52929C6.58465 6.17952 6.47473 5.89152 6.0984 5.88278C5.71743 5.87392 5.6008 6.15229 5.60399 6.50889C5.6128 7.5107 5.60666 8.51259 5.60658 9.51444C5.60654 11.1261 5.60658 11.1261 4.02188 11.3577C3.41944 10.6674 2.87084 10.0468 2.33103 9.41873C0.393624 7.16444 0.74792 3.832 3.16595 2.10604C4.90314 0.866003 6.76928 0.797004 8.62984 1.844C10.3574 2.81622 11.2972 4.81918 11.0481 6.75677C10.8817 8.05114 10.2637 9.08436 9.34691 9.97255C8.79365 10.5086 8.28951 11.0857 8.10802 11.8608C8.02313 12.2233 7.81988 12.4031 7.46869 12.4897C7.53432 12.4287 3.68344 13.5077 3.68344 13.5077C3.43981 13.5729 3.29392 13.8257 3.35921 14.0692L3.36925 14.1069C3.43455 14.3505 3.68725 14.4964 3.93088 14.4312C3.93088 14.4312 8.34939 13.2438 8.40732 13.2274C8.8165 13.1145 9.03921 12.8232 9.04854 12.4074C9.06247 11.7867 9.33217 11.3036 9.77106 10.8912C10.006 10.6704 10.2399 10.4476 10.4611 10.2132C12.2861 8.27751 12.5873 5.34055 11.1996 3.04845Z"
                                            fill="#65676B"/>
                                    </svg>
                                    <span>More suggestions</span>
                                </div>
                                <ul>
                                    {defaultValues.related.jobTitle.map((jobTitle) => (<li key={jobTitle.name}
                                                                                           onClick={handleRelated([{
                                                                                               url: 'title-to-title/related/search',
                                                                                               name: 'jobTitle'
                                                                                           }, {
                                                                                               url: 'title-to-skill/related/search',
                                                                                               name: 'skill'
                                                                                           }], jobTitle.name, 'jobTitle')}>
                                        <a href="#"><span><ion-icon
                                            name="add-outline"></ion-icon></span>{jobTitle.name}</a></li>))}
                                </ul>
                            </div>
                        </div>}
                </div>
            </div>

            <div className="content_box">
                {/*<div className="title">*/}
                {/*    <h3>Exclude Job Titles*/}
                {/*        {defaultValues.filter.excludeJobTitle.length > 0 &&*/}
                {/*            <span className="count">{defaultValues.filter.excludeJobTitle.length}</span>*/}
                {/*        }*/}
                {/*    </h3>*/}
                {/*    <div className="incl_area">*/}
                {/*        <h5>Exclude Past?</h5>*/}
                {/*        <div className="toggle-switch">*/}
                {/*            <label>*/}
                {/*                <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox}*/}
                {/*                       role="switch" name="excludePastJobTitle"*/}
                {/*                       checked={defaultValues.filter.excludePastJobTitle} id="flexSwitchCheckChecked"/>*/}
                {/*                <span>*/}
                {/*                    <small></small>*/}
                {/*                </span>*/}
                {/*            </label>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="clearfix"></div>
                <div className="placeholder_ui exclude_column clear_btn">
                    <AsyncDropdown
                        styles={{
                            control: (styles, {data}) => ({
                                ":before": {
                                    content: '"Exclude"',
                                    padding: "0px 12px",
                                    width: "74px",
                                    height: "21px",
                                    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #3D3E40",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#FFFFFF",
                                    marginTop: "6px",
                                }
                            }), // clearIndicator: (styles) => ({
                            //     ":after": {
                            //         content: '""'
                            //     }
                            // })
                        }}
                        placeholder="Candidate job titles"
                        name="excludeJobTitle"
                        gaTrackValue="cdt"
                        defaultValue={defaultValues.filter.excludeJobTitle}
                        filterOptions={props.filterOptions?.jobTitle}
                        url="title/master/search"
                        handleSelectChange={selectChange([{
                            url: 'title-to-title/related/search', name: 'excludeJobTitle'
                        }])}
                    />

                    {defaultValues.related.excludeJobTitle.length !== 0 &&
                        <div className="select_job_area" id="skill-suggestion">
                            <div className="suggestions_row">
                                <div className="suggestions">
                                    <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M8.34147 15.2065L4.85173 16.1416C4.6031 16.2082 4.34521 16.0593 4.27858 15.8107C4.21199 15.5621 4.36088 15.3041 4.60947 15.2375L8.09921 14.3025C8.34784 14.2359 8.60573 14.3847 8.67236 14.6334C8.73902 14.882 8.59006 15.1399 8.34147 15.2065ZM6.72569 4.48215C6.69443 4.90755 6.4538 5.14733 6.08021 5.14126C5.70825 5.13526 5.43754 4.88033 5.44647 4.49215C5.45514 4.11444 5.69195 3.86633 6.0948 3.87074C6.4974 3.87507 6.69695 4.11296 6.72569 4.48215ZM11.1996 3.04845C9.81132 0.755559 7.06169 -0.358477 4.50721 0.387856C2.2717 1.04104 0.814106 2.51711 0.284106 4.78722C-0.240486 7.03411 0.412476 8.9667 2.06503 10.5852C2.45259 10.9648 2.80892 11.4245 3.0274 11.9152C3.24644 12.407 3.31666 12.5607 3.85018 12.4316C4.59114 12.2523 5.32217 12.0321 6.06299 11.8521C6.44277 11.7599 6.58706 11.5478 6.58454 11.165C6.5744 9.61981 6.5778 8.07451 6.58343 6.52929C6.58465 6.17952 6.47473 5.89152 6.0984 5.88278C5.71743 5.87392 5.6008 6.15229 5.60399 6.50889C5.6128 7.5107 5.60666 8.51259 5.60658 9.51444C5.60654 11.1261 5.60658 11.1261 4.02188 11.3577C3.41944 10.6674 2.87084 10.0468 2.33103 9.41873C0.393624 7.16444 0.74792 3.832 3.16595 2.10604C4.90314 0.866003 6.76928 0.797004 8.62984 1.844C10.3574 2.81622 11.2972 4.81918 11.0481 6.75677C10.8817 8.05114 10.2637 9.08436 9.34691 9.97255C8.79365 10.5086 8.28951 11.0857 8.10802 11.8608C8.02313 12.2233 7.81988 12.4031 7.46869 12.4897C7.53432 12.4287 3.68344 13.5077 3.68344 13.5077C3.43981 13.5729 3.29392 13.8257 3.35921 14.0692L3.36925 14.1069C3.43455 14.3505 3.68725 14.4964 3.93088 14.4312C3.93088 14.4312 8.34939 13.2438 8.40732 13.2274C8.8165 13.1145 9.03921 12.8232 9.04854 12.4074C9.06247 11.7867 9.33217 11.3036 9.77106 10.8912C10.006 10.6704 10.2399 10.4476 10.4611 10.2132C12.2861 8.27751 12.5873 5.34055 11.1996 3.04845Z"
                                            fill="#65676B"/>
                                    </svg>
                                    <span>More suggestions</span>
                                </div>
                                <ul>
                                    {defaultValues.related.excludeJobTitle.map((jobTitle) => (
                                        <li key={jobTitle.name} className="suggestion-btn"
                                            onClick={handleRelated([{
                                                url: 'title-to-title/related/search', name: 'excludeJobTitle'
                                            }], jobTitle.name, 'excludeJobTitle')}>
                                            <a href="#"><span><ion-icon
                                                name="add-outline"></ion-icon></span>{jobTitle.name}</a>
                                        </li>))}
                                </ul>
                            </div>
                        </div>}
                </div>
            </div>

            <div className="content_box">
                <div className="title">
                    <h3>Seniority
                        {defaultValues.filter.seniority.length > 0 &&
                            <span className="count">{defaultValues.filter.seniority.length}</span>}
                    </h3>
                </div>
                <AsyncDropdown
                    placeholder="Seniority"
                    isCreatable={false}
                    isAsync={false}
                    onKeyDown={(e) => e.preventDefault()}
                    name="seniority"
                    defaultValue={defaultValues.filter.seniority}
                    filterOptions={props.filterOptions?.seniority}
                    url="seniority/master/search"
                    handleSelectChange={selectChange()}
                />


            </div>

            <div className="content_box mb_68">
                <div className="title clear_option">
                    <h3>Experience (Years)</h3>
                    {defaultValues.filter.experience.length > 0 && <span onClick={() => {
                        props.updateFilters({
                            value: [], name: 'experience'
                        })
                    }}>
                            Clear
                        </span>}
                </div>
                <div className="mui_expe">
                    <div className="range_editable_row">
                        <input type="text" name="expFrom" className="mui_range_editable"
                               onChange={(event) => handleExperienceInputs(event)} value={expInputValue.expFrom}/>
                        <label>to</label>
                        <input type="text" name="expTo" className="mui_range_editable"
                               onChange={(event) => handleExperienceInputs(event)} value={expInputValue.expTo}/>
                    </div>
                    <InputSlider
                        name="experience"
                        gaTrackValue="cdt"
                        min={1}
                        max={25}
                        step={1}
                        value={defaultValues.filter.experience.length > 0 && defaultValues.filter.experience[0].value.split('-').map(v => parseFloat(v))}
                        setInputValue={(value) => props.updateFilters({
                            value: [{
                                label: `${value[0]}-${value[1]}`, value: `${value[0]}-${value[1]}`
                            }], name: 'experience'
                        })}
                    />
                </div>
            </div>

            {/*        <div className="content_box">
            <div className="title">
                <h3>Mandatory Skills
                    {defaultValues.filter.mandatorySkill.length > 0 &&
                        <span className="count">{defaultValues.filter.mandatorySkill.length}</span>}
                </h3>
            </div>
            <AsyncDropdown
                placeholder="Skills possessed"
                name="mandatorySkill"
                defaultValue={defaultValues.filter.mandatorySkill}
                filterOptions={props.filterOptions?.skill}
                url="skills/master/search"
                handleSelectChange={selectChange('skill-to-skill/related/search')}
            />

            {defaultValues.related.mandatorySkill.length !== 0 &&
                <div className="select_job_area" id="skill-suggestion">
                    <div className="suggestions_row">
                        <div className="suggestions">
                            <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.34147 15.2065L4.85173 16.1416C4.6031 16.2082 4.34521 16.0593 4.27858 15.8107C4.21199 15.5621 4.36088 15.3041 4.60947 15.2375L8.09921 14.3025C8.34784 14.2359 8.60573 14.3847 8.67236 14.6334C8.73902 14.882 8.59006 15.1399 8.34147 15.2065ZM6.72569 4.48215C6.69443 4.90755 6.4538 5.14733 6.08021 5.14126C5.70825 5.13526 5.43754 4.88033 5.44647 4.49215C5.45514 4.11444 5.69195 3.86633 6.0948 3.87074C6.4974 3.87507 6.69695 4.11296 6.72569 4.48215ZM11.1996 3.04845C9.81132 0.755559 7.06169 -0.358477 4.50721 0.387856C2.2717 1.04104 0.814106 2.51711 0.284106 4.78722C-0.240486 7.03411 0.412476 8.9667 2.06503 10.5852C2.45259 10.9648 2.80892 11.4245 3.0274 11.9152C3.24644 12.407 3.31666 12.5607 3.85018 12.4316C4.59114 12.2523 5.32217 12.0321 6.06299 11.8521C6.44277 11.7599 6.58706 11.5478 6.58454 11.165C6.5744 9.61981 6.5778 8.07451 6.58343 6.52929C6.58465 6.17952 6.47473 5.89152 6.0984 5.88278C5.71743 5.87392 5.6008 6.15229 5.60399 6.50889C5.6128 7.5107 5.60666 8.51259 5.60658 9.51444C5.60654 11.1261 5.60658 11.1261 4.02188 11.3577C3.41944 10.6674 2.87084 10.0468 2.33103 9.41873C0.393624 7.16444 0.74792 3.832 3.16595 2.10604C4.90314 0.866003 6.76928 0.797004 8.62984 1.844C10.3574 2.81622 11.2972 4.81918 11.0481 6.75677C10.8817 8.05114 10.2637 9.08436 9.34691 9.97255C8.79365 10.5086 8.28951 11.0857 8.10802 11.8608C8.02313 12.2233 7.81988 12.4031 7.46869 12.4897C7.53432 12.4287 3.68344 13.5077 3.68344 13.5077C3.43981 13.5729 3.29392 13.8257 3.35921 14.0692L3.36925 14.1069C3.43455 14.3505 3.68725 14.4964 3.93088 14.4312C3.93088 14.4312 8.34939 13.2438 8.40732 13.2274C8.8165 13.1145 9.03921 12.8232 9.04854 12.4074C9.06247 11.7867 9.33217 11.3036 9.77106 10.8912C10.006 10.6704 10.2399 10.4476 10.4611 10.2132C12.2861 8.27751 12.5873 5.34055 11.1996 3.04845Z"
                                    fill="#65676B"/>
                            </svg>
                            <span>More suggestions</span>
                        </div>
                        <ul>
                            {defaultValues.related.mandatorySkill.map((skill) => (
                                <li key={skill.name} className="suggestion-btn"
                                    onClick={handleRelated('skill-to-skill/related/search', skill.name, 'mandatorySkill')}>
                                    <a href="#"><span><ion-icon
                                        name="add-outline"></ion-icon></span>{skill.name}</a>
                                </li>))}
                        </ul>
                    </div>
                </div>}
        </div>*/}

            <div className="content_box">
                <div className="title">
                    <h3>Skills
                        {defaultValues.filter.skill.length > 0 &&
                            <span className="count">{defaultValues.filter.skill.length}</span>}
                    </h3>
                </div>
                <AsyncDropdown
                    placeholder="Skills possessed"
                    name="skill"
                    gaTrackValue="cdt"
                    defaultValue={defaultValues.filter.skill}
                    filterOptions={props.filterOptions?.skill}
                    url="skills/master/search"
                    handleSelectChange={selectChange('skill-to-skill/related/search')}
                />

                {defaultValues.related.skill.length !== 0 && <div className="select_job_area" id="skill-suggestion">
                    <div className="suggestions_row">
                        <div className="suggestions">
                            <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.34147 15.2065L4.85173 16.1416C4.6031 16.2082 4.34521 16.0593 4.27858 15.8107C4.21199 15.5621 4.36088 15.3041 4.60947 15.2375L8.09921 14.3025C8.34784 14.2359 8.60573 14.3847 8.67236 14.6334C8.73902 14.882 8.59006 15.1399 8.34147 15.2065ZM6.72569 4.48215C6.69443 4.90755 6.4538 5.14733 6.08021 5.14126C5.70825 5.13526 5.43754 4.88033 5.44647 4.49215C5.45514 4.11444 5.69195 3.86633 6.0948 3.87074C6.4974 3.87507 6.69695 4.11296 6.72569 4.48215ZM11.1996 3.04845C9.81132 0.755559 7.06169 -0.358477 4.50721 0.387856C2.2717 1.04104 0.814106 2.51711 0.284106 4.78722C-0.240486 7.03411 0.412476 8.9667 2.06503 10.5852C2.45259 10.9648 2.80892 11.4245 3.0274 11.9152C3.24644 12.407 3.31666 12.5607 3.85018 12.4316C4.59114 12.2523 5.32217 12.0321 6.06299 11.8521C6.44277 11.7599 6.58706 11.5478 6.58454 11.165C6.5744 9.61981 6.5778 8.07451 6.58343 6.52929C6.58465 6.17952 6.47473 5.89152 6.0984 5.88278C5.71743 5.87392 5.6008 6.15229 5.60399 6.50889C5.6128 7.5107 5.60666 8.51259 5.60658 9.51444C5.60654 11.1261 5.60658 11.1261 4.02188 11.3577C3.41944 10.6674 2.87084 10.0468 2.33103 9.41873C0.393624 7.16444 0.74792 3.832 3.16595 2.10604C4.90314 0.866003 6.76928 0.797004 8.62984 1.844C10.3574 2.81622 11.2972 4.81918 11.0481 6.75677C10.8817 8.05114 10.2637 9.08436 9.34691 9.97255C8.79365 10.5086 8.28951 11.0857 8.10802 11.8608C8.02313 12.2233 7.81988 12.4031 7.46869 12.4897C7.53432 12.4287 3.68344 13.5077 3.68344 13.5077C3.43981 13.5729 3.29392 13.8257 3.35921 14.0692L3.36925 14.1069C3.43455 14.3505 3.68725 14.4964 3.93088 14.4312C3.93088 14.4312 8.34939 13.2438 8.40732 13.2274C8.8165 13.1145 9.03921 12.8232 9.04854 12.4074C9.06247 11.7867 9.33217 11.3036 9.77106 10.8912C10.006 10.6704 10.2399 10.4476 10.4611 10.2132C12.2861 8.27751 12.5873 5.34055 11.1996 3.04845Z"
                                    fill="#65676B"/>
                            </svg>
                            <span>More suggestions</span>
                        </div>
                        <ul>
                            {defaultValues.related.skill.map((skill) => (<li key={skill.name} className="suggestion-btn"
                                                                             onClick={handleRelated('skill-to-skill/related/search', skill.name, 'skill')}>
                                <a href="#"><span><ion-icon
                                    name="add-outline"></ion-icon></span>{skill.name}</a>
                            </li>))}
                        </ul>
                    </div>
                </div>}
                <div className="keyword_options">
                    <FormControl>
                        <RadioGroup
                            sx={{rowGap: '3px'}}
                            value={defaultValues.filter.skillsAndOr}
                            aria-labelledby="demo-customized-radios"
                            name="skillsAndOr"
                            onChange={(e) => {
                                if (defaultValues.filter.skill.length === 0) return;
                                props.updateFilters({name: 'skillsAndOr', value: e.target.value})
                            }}
                        >
                            <FormControlLabel value="and" control={<BpRadio/>} label="And"/>
                            <FormControlLabel value="or" control={<BpRadio/>} label="Or"/>
                        </RadioGroup>
                    </FormControl>
                </div>

            </div>

            <div className="content_box">
                <div className="title">
                    <h3>Industry
                        {defaultValues.filter.industry.length > 0 &&
                            <span className="count">{defaultValues.filter.industry.length}</span>}
                    </h3>
                    <div className="incl_area">
                        <h5>Include Past? </h5>
                        {/*<div className="form-check form-switch">*/}
                        {/*    <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox} role="switch" name="pastJobTitle" checked={defaultValues.filter.pastJobTitle} id="flexSwitchCheckChecked"/>*/}
                        {/*</div>*/}
                        <div className="toggle-switch">
                            <label>
                                <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox}
                                       role="switch" name="pastIndustry"
                                       checked={defaultValues.filter.pastIndustry} id="flexSwitchCheckChecked"/>
                                <span>
                                    <small></small>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <AsyncDropdown
                    placeholder="Industries"
                    isCreatable={false}
                    gaTrackValue="cdt"
                    name="industry"
                    defaultValue={defaultValues.filter.industry}
                    filterOptions={props.filterOptions?.industry}
                    url="industry/master/search"
                    handleSelectChange={selectChange([{url: 'industry-to-company/related/search', name: 'company'}])}
                />

            </div>

            <div className="content_box mb_16">
                <div className="title">
                    <h3>Companies
                        {defaultValues.filter.company.length > 0 &&
                            <span className="count">{defaultValues.filter.company.length}</span>
                        }
                    </h3>
                    <div className="incl_area">
                        <h5>Include Past? </h5>
                        <div className="toggle-switch">
                            <label>
                                <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox}
                                       role="switch" name="pastCompany"
                                       checked={defaultValues.filter.pastCompany} id="flexSwitchCheckChecked"/>
                                <span>
                                    <small></small>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <div className="placeholder_ui include_column">
                    <AsyncDropdown
                        styles={{
                            control: (styles, {data}) => ({
                                ":before": {
                                    content: '"Include"',
                                    padding: "0px 12px",
                                    width: "74px",
                                    height: "21px",
                                    background: "#dc0022",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#FFFFFF",
                                    marginTop: "6px",
                                }
                            }), // clearIndicator: (styles) => ({
                            //     ":after": {
                            //         content: '""'
                            //     }
                            // })
                        }}
                        placeholder="Companies"
                        name="company"
                        gaTrackValue="cdt"
                        defaultValue={defaultValues.filter.company}
                        filterOptions={props.filterOptions?.company}
                        url="user-company/master/search"
                        handleSelectChange={selectChange('company-to-company/related/search')}
                    />
                </div>
                {defaultValues.related.company.length !== 0 && <div className="select_job_area" id="skill-suggestion">
                    <div className="suggestions_row">
                        <div className="suggestions">
                            <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.34147 15.2065L4.85173 16.1416C4.6031 16.2082 4.34521 16.0593 4.27858 15.8107C4.21199 15.5621 4.36088 15.3041 4.60947 15.2375L8.09921 14.3025C8.34784 14.2359 8.60573 14.3847 8.67236 14.6334C8.73902 14.882 8.59006 15.1399 8.34147 15.2065ZM6.72569 4.48215C6.69443 4.90755 6.4538 5.14733 6.08021 5.14126C5.70825 5.13526 5.43754 4.88033 5.44647 4.49215C5.45514 4.11444 5.69195 3.86633 6.0948 3.87074C6.4974 3.87507 6.69695 4.11296 6.72569 4.48215ZM11.1996 3.04845C9.81132 0.755559 7.06169 -0.358477 4.50721 0.387856C2.2717 1.04104 0.814106 2.51711 0.284106 4.78722C-0.240486 7.03411 0.412476 8.9667 2.06503 10.5852C2.45259 10.9648 2.80892 11.4245 3.0274 11.9152C3.24644 12.407 3.31666 12.5607 3.85018 12.4316C4.59114 12.2523 5.32217 12.0321 6.06299 11.8521C6.44277 11.7599 6.58706 11.5478 6.58454 11.165C6.5744 9.61981 6.5778 8.07451 6.58343 6.52929C6.58465 6.17952 6.47473 5.89152 6.0984 5.88278C5.71743 5.87392 5.6008 6.15229 5.60399 6.50889C5.6128 7.5107 5.60666 8.51259 5.60658 9.51444C5.60654 11.1261 5.60658 11.1261 4.02188 11.3577C3.41944 10.6674 2.87084 10.0468 2.33103 9.41873C0.393624 7.16444 0.74792 3.832 3.16595 2.10604C4.90314 0.866003 6.76928 0.797004 8.62984 1.844C10.3574 2.81622 11.2972 4.81918 11.0481 6.75677C10.8817 8.05114 10.2637 9.08436 9.34691 9.97255C8.79365 10.5086 8.28951 11.0857 8.10802 11.8608C8.02313 12.2233 7.81988 12.4031 7.46869 12.4897C7.53432 12.4287 3.68344 13.5077 3.68344 13.5077C3.43981 13.5729 3.29392 13.8257 3.35921 14.0692L3.36925 14.1069C3.43455 14.3505 3.68725 14.4964 3.93088 14.4312C3.93088 14.4312 8.34939 13.2438 8.40732 13.2274C8.8165 13.1145 9.03921 12.8232 9.04854 12.4074C9.06247 11.7867 9.33217 11.3036 9.77106 10.8912C10.006 10.6704 10.2399 10.4476 10.4611 10.2132C12.2861 8.27751 12.5873 5.34055 11.1996 3.04845Z"
                                    fill="#65676B"/>
                            </svg>
                            <span>More suggestions</span>
                        </div>
                        <ul>
                            {defaultValues.related.company.map((company) => (
                                <li key={company.name} className="suggestion-btn"
                                    onClick={handleRelated('company-to-company/related/search', company.name, 'company')}>
                                    <a href="#"><span><ion-icon
                                        name="add-outline"></ion-icon></span>{company.name} </a>
                                </li>))}
                        </ul>
                    </div>
                </div>}
            </div>

            <div className="content_box">
                {/*<div className="title">*/}
                {/*    <h3>Exclude Companies*/}
                {/*        {defaultValues.filter.excludeCompany.length > 0 &&*/}
                {/*            <span className="count">{defaultValues.filter.excludeCompany.length}</span>*/}
                {/*        }*/}
                {/*    </h3>*/}
                {/*</div>*/}
                <div className="placeholder_ui exclude_column clear_btn">
                    <AsyncDropdown
                        styles={{
                            control: (styles, {data}) => ({
                                ":before": {
                                    content: '"Exclude"',
                                    padding: "0px 12px",
                                    width: "74px",
                                    height: "21px",
                                    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), #3D3E40",
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    color: "#FFFFFF",
                                    marginTop: "6px",
                                }
                            }),
                            // clearIndicator: (styles) => ({
                            //     ":after": {
                            //         content: '""'
                            //     }
                            // })
                        }}
                        placeholder="Companies"
                        name="excludeCompany"
                        gaTrackValue="cdt"
                        defaultValue={defaultValues.filter.excludeCompany}
                        filterOptions={props.filterOptions?.company}
                        url="user-company/master/search"
                        handleSelectChange={selectChange('company-to-company/related/search')}
                    />
                </div>
                {defaultValues.related.excludeCompany.length !== 0 &&
                    <div className="select_job_area" id="skill-suggestion">
                        <div className="suggestions_row">
                            <div className="suggestions">
                                <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.34147 15.2065L4.85173 16.1416C4.6031 16.2082 4.34521 16.0593 4.27858 15.8107C4.21199 15.5621 4.36088 15.3041 4.60947 15.2375L8.09921 14.3025C8.34784 14.2359 8.60573 14.3847 8.67236 14.6334C8.73902 14.882 8.59006 15.1399 8.34147 15.2065ZM6.72569 4.48215C6.69443 4.90755 6.4538 5.14733 6.08021 5.14126C5.70825 5.13526 5.43754 4.88033 5.44647 4.49215C5.45514 4.11444 5.69195 3.86633 6.0948 3.87074C6.4974 3.87507 6.69695 4.11296 6.72569 4.48215ZM11.1996 3.04845C9.81132 0.755559 7.06169 -0.358477 4.50721 0.387856C2.2717 1.04104 0.814106 2.51711 0.284106 4.78722C-0.240486 7.03411 0.412476 8.9667 2.06503 10.5852C2.45259 10.9648 2.80892 11.4245 3.0274 11.9152C3.24644 12.407 3.31666 12.5607 3.85018 12.4316C4.59114 12.2523 5.32217 12.0321 6.06299 11.8521C6.44277 11.7599 6.58706 11.5478 6.58454 11.165C6.5744 9.61981 6.5778 8.07451 6.58343 6.52929C6.58465 6.17952 6.47473 5.89152 6.0984 5.88278C5.71743 5.87392 5.6008 6.15229 5.60399 6.50889C5.6128 7.5107 5.60666 8.51259 5.60658 9.51444C5.60654 11.1261 5.60658 11.1261 4.02188 11.3577C3.41944 10.6674 2.87084 10.0468 2.33103 9.41873C0.393624 7.16444 0.74792 3.832 3.16595 2.10604C4.90314 0.866003 6.76928 0.797004 8.62984 1.844C10.3574 2.81622 11.2972 4.81918 11.0481 6.75677C10.8817 8.05114 10.2637 9.08436 9.34691 9.97255C8.79365 10.5086 8.28951 11.0857 8.10802 11.8608C8.02313 12.2233 7.81988 12.4031 7.46869 12.4897C7.53432 12.4287 3.68344 13.5077 3.68344 13.5077C3.43981 13.5729 3.29392 13.8257 3.35921 14.0692L3.36925 14.1069C3.43455 14.3505 3.68725 14.4964 3.93088 14.4312C3.93088 14.4312 8.34939 13.2438 8.40732 13.2274C8.8165 13.1145 9.03921 12.8232 9.04854 12.4074C9.06247 11.7867 9.33217 11.3036 9.77106 10.8912C10.006 10.6704 10.2399 10.4476 10.4611 10.2132C12.2861 8.27751 12.5873 5.34055 11.1996 3.04845Z"
                                        fill="#65676B"/>
                                </svg>
                                <span>More suggestions</span>
                            </div>
                            <ul>
                                {defaultValues.related.excludeCompany.map((company) => (
                                    <li key={company.name} className="suggestion-btn"
                                        onClick={handleRelated('company-to-company/related/search', company.name, 'excludeCompany')}>
                                        <a href="#"><span><ion-icon
                                            name="add-outline"></ion-icon></span>{company.name}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>}
            </div>


            <div className="content_box">
                {/*<div className="title">*/}
                {/*    <h3>Hide previously viewed</h3>*/}
                {/*</div>*/}
                <div className="">
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="hideViewedProfile"
                                checked={defaultValues.filter?.hideViewedProfile}
                                onChange={props.handleCheckbox}
                                inputProps={{'aria-label': 'controlled'}}
                                sx={{
                                    width: 20,
                                    height: 20,
                                    background: 'white',
                                    border: 'none',
                                    borderRadius: '2px',
                                    color: 'rgba(0, 0, 0, 0.33)',
                                    transform: "scale(1.0)"
                                }}
                            />
                        } label="Hide previously viewed candidates" sx={{
                            margin: '0',
                            fontFamily: 'Nunito',
                            fontWeight: '400',
                            fontSize: '15.75px',
                            lineHeight: '24px',
                            color: '#000000',
                            display: 'flex',
                            gap: '8px'
                        }}/>
                    </FormGroup>
                </div>
            </div>

            <div className="content_box">
                <div className="title">
                    <h3>Company Size
                        {defaultValues.filter.companySize.length > 0 &&
                            <span className="count">{defaultValues.filter.companySize.length}</span>}
                    </h3>
                </div>
                <AsyncDropdown
                    placeholder="Candidate company size"
                    name="companySize"
                    gaTrackValue="cdt"
                    isCreatable={false}
                    defaultValue={defaultValues.filter.companySize}
                    onKeyDown={(e) => e.preventDefault()}
                    allOptions={[{name: '1-50'}, {name: '51-200'}, {name: '201-500'}, {name: '501-1000'}, {name: '1001-5000'}, {name: '5001-10000'}, {name: '10001'}]}
                    handleSelectChange={selectChange()}
                />
            </div>

            <div className="content_box">
                <div className="title title_row">
                    <h3>Education
                        {defaultValues.filter.education.length > 0 &&
                            <span className="count">{defaultValues.filter.education.length}</span>}
                    </h3>
                    <div className="incl_area">
                        <h5>Premium institutes only?</h5>
                        {/*<div className="form-check form-switch">*/}
                        {/*    <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox}*/}
                        {/*           role="switch" name="premiumInstitutes"*/}
                        {/*           checked={defaultValues.filter.premiumInstitutes} id="flexSwitchCheckChecked"/>*/}
                        {/*</div>*/}
                        <div className="toggle-switch">
                            <label>
                                <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox}
                                       role="switch" name="premiumInstitutes"
                                       checked={defaultValues.filter.premiumInstitutes} id="flexSwitchCheckChecked"/>
                                <span>
                                    <small></small>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
                <AsyncDropdown
                    placeholder="Education done from"
                    name="education"
                    gaTrackValue="cdt"
                    params={{premiumInstitutes: defaultValues.filter.premiumInstitutes}}
                    defaultValue={defaultValues.filter.education}
                    filterOptions={props.filterOptions?.education}
                    url="education/master/search"
                    handleSelectChange={selectChange('educations/related-educations')}
                />
                {defaultValues.related.education.length !== 0 && <div className="select_job_area" id="skill-suggestion">
                    <div className="suggestions_row">
                        <div className="suggestions">
                            <svg width="13" height="17" viewBox="0 0 13 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M8.34147 15.2065L4.85173 16.1416C4.6031 16.2082 4.34521 16.0593 4.27858 15.8107C4.21199 15.5621 4.36088 15.3041 4.60947 15.2375L8.09921 14.3025C8.34784 14.2359 8.60573 14.3847 8.67236 14.6334C8.73902 14.882 8.59006 15.1399 8.34147 15.2065ZM6.72569 4.48215C6.69443 4.90755 6.4538 5.14733 6.08021 5.14126C5.70825 5.13526 5.43754 4.88033 5.44647 4.49215C5.45514 4.11444 5.69195 3.86633 6.0948 3.87074C6.4974 3.87507 6.69695 4.11296 6.72569 4.48215ZM11.1996 3.04845C9.81132 0.755559 7.06169 -0.358477 4.50721 0.387856C2.2717 1.04104 0.814106 2.51711 0.284106 4.78722C-0.240486 7.03411 0.412476 8.9667 2.06503 10.5852C2.45259 10.9648 2.80892 11.4245 3.0274 11.9152C3.24644 12.407 3.31666 12.5607 3.85018 12.4316C4.59114 12.2523 5.32217 12.0321 6.06299 11.8521C6.44277 11.7599 6.58706 11.5478 6.58454 11.165C6.5744 9.61981 6.5778 8.07451 6.58343 6.52929C6.58465 6.17952 6.47473 5.89152 6.0984 5.88278C5.71743 5.87392 5.6008 6.15229 5.60399 6.50889C5.6128 7.5107 5.60666 8.51259 5.60658 9.51444C5.60654 11.1261 5.60658 11.1261 4.02188 11.3577C3.41944 10.6674 2.87084 10.0468 2.33103 9.41873C0.393624 7.16444 0.74792 3.832 3.16595 2.10604C4.90314 0.866003 6.76928 0.797004 8.62984 1.844C10.3574 2.81622 11.2972 4.81918 11.0481 6.75677C10.8817 8.05114 10.2637 9.08436 9.34691 9.97255C8.79365 10.5086 8.28951 11.0857 8.10802 11.8608C8.02313 12.2233 7.81988 12.4031 7.46869 12.4897C7.53432 12.4287 3.68344 13.5077 3.68344 13.5077C3.43981 13.5729 3.29392 13.8257 3.35921 14.0692L3.36925 14.1069C3.43455 14.3505 3.68725 14.4964 3.93088 14.4312C3.93088 14.4312 8.34939 13.2438 8.40732 13.2274C8.8165 13.1145 9.03921 12.8232 9.04854 12.4074C9.06247 11.7867 9.33217 11.3036 9.77106 10.8912C10.006 10.6704 10.2399 10.4476 10.4611 10.2132C12.2861 8.27751 12.5873 5.34055 11.1996 3.04845Z"
                                    fill="#65676B"/>
                            </svg>
                            <span>More suggestions</span>
                        </div>
                        <ul>
                            {defaultValues.related.education.map((education) => (
                                <li key={education.name} className="suggestion-btn"
                                    onClick={handleRelated('educations/related-educations', education.name, 'education')}>
                                    <a href="#"><span><ion-icon
                                        name="add-outline"></ion-icon></span>{education.name}</a>
                                </li>))}
                        </ul>
                    </div>
                </div>}

            </div>

            <div className="content_box">
                <div className="title">
                    <h3>Degree
                        {defaultValues.filter.degree.length > 0 &&
                            <span className="count">{defaultValues.filter.degree.length}</span>}
                    </h3>
                    <div className="incl_area">
                        <h5>Degree from premium institute only?</h5>
                        {/*<div className="form-check form-switch">*/}
                        {/*    <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox}*/}
                        {/*           role="switch" name="premiumInstitutes"*/}
                        {/*           checked={defaultValues.filter.premiumInstitutes} id="flexSwitchCheckChecked"/>*/}
                        {/*</div>*/}
                        <div className="toggle-switch">
                            <label>
                                <input className="form-check-input" type="checkbox" onChange={props.handleCheckbox}
                                       role="switch" name="premiumDegree"
                                       checked={defaultValues.filter.premiumDegree} id="flexSwitchCheckChecked"/>
                                <span>
                                    <small></small>
                                </span>
                            </label>
                        </div>
                    </div>
                </div>
                <AsyncDropdown
                    placeholder="Degree in"
                    name="degree"
                    gaTrackValue="cdt"
                    defaultValue={defaultValues.filter.degree}
                    filterOptions={props.filterOptions?.degree}
                    url="degree/master/search"
                    handleSelectChange={selectChange()}
                />


            </div>

            {/*<div className="content_box">
                <div className="title">
                    <h3>Major
                        {defaultValues.filter.major.length > 0 &&
                            <span className="count">{defaultValues.filter.major.length}</span>
                        }
                    </h3>
                </div>
                <AsyncDropdown
                    placeholder="Major done in"
                    name="major"
                    defaultValue={defaultValues.filter.major}
                    url="major"
                    handleSelectChange={selectChange()}
                />


            </div>*/}

            <div className="content_box">
                <div className="title">
                    <h3>Years In Current Role
                        {defaultValues.filter.currentJobYears.length > 0 &&
                            <span className="count">{defaultValues.filter.currentJobYears.length}</span>}
                    </h3>
                </div>
                <AsyncDropdown
                    placeholder="Years completed in current role"
                    name="currentJobYears"
                    gaTrackValue="cdt"
                    isAsync={false}
                    isMultiple={true}
                    isCreatable={false}
                    onKeyDown={(e) => e.preventDefault()}
                    defaultValue={defaultValues.filter.currentJobYears}
                    allOptions={[{name: '0-1'}, {name: '1-2'}, {name: '2-3'}, {name: '3-4'}, {name: '4+'}]}
                    handleSelectChange={selectChange()}
                />


            </div>

            {/*<div className="content_box">
                <div className="title">
                    <h3>Likely To Switch
                        {defaultValues.filter.availability.length > 0 &&
                            <span className="count">{defaultValues.filter.availability.length}</span>
                        }
                    </h3>
                </div>
                <AsyncDropdown
                    placeholder="Probability of switching"
                    name="availability"
                    isAsync={false}
                    isCreatable={false}
                    onKeyDown={(e) => e.preventDefault()}
                    defaultValue={defaultValues.filter.availability}
                    allOptions={[{name: 'Low'}, {name: 'Medium'}, {name: 'High'}]}
                    handleSelectChange={selectChange()}
                />


            </div>*/}

{/*            <div className="content_box mb_30">
                <div className="title clear_option">
                    <h3>Diversity</h3>
                    {defaultValues.filter?.gender?.[0]?.value && <span onClick={() => {
                        props.updateFilters({
                            value: [], name: 'gender'
                        })
                    }}>
                Clear
            </span>}
                </div>
                <FormControl>
                    <RadioGroup
                        sx={{rowGap: '3px'}}
                        value={defaultValues.filter?.gender?.[0]?.value ?? ''}
                        aria-labelledby="demo-customized-radios"
                        name="gender"
                        onChange={(e) => handleRadioBtn(e)}
                    >
                        <FormControlLabel value="any" control={<BpRadio />} label="Any" />
                        <FormControlLabel value="female" control={<BpRadio />} label="Female" />
                    </RadioGroup>
                </FormControl>
            </div>*/}

            <div className="content_box mb-0">
                <div className="title clear_option">
                    <h3>CoffeeMug Member</h3>
                    {defaultValues.filter?.member?.[0]?.value && <span onClick={() => {
                        props.updateFilters({
                            value: [], name: 'member'
                        })
                    }}>
                            Clear
                        </span>}
                </div>
                <FormControl>
                    <RadioGroup
                        sx={{rowGap: '3px'}}
                        value={defaultValues.filter?.member?.[0]?.value ?? ''}
                        aria-labelledby="demo-customized-radios"
                        name="member"
                        onChange={(e) => handleRadioBtn(e)}
                    >
                        <FormControlLabel value="any" control={<BpRadio/>} label="Any"/>
                        <FormControlLabel value="coffeemugMember" control={<BpRadio/>} label="CoffeeMug Member"/>
                        {/*<FormControlLabel value="coffeemugJobSeeker" control={<BpRadio/>}
                                      label="More Likely To Respond"/>*/}
                    </RadioGroup>
                </FormControl>
            </div>
            {/*<div className="content_box">
                <div className="title">
                    <h3>Candidates On Kaggle</h3>
                </div>
                <div className="">
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox
                                name="kaggle"
                                checked={defaultValues.filter?.kaggle}
                                onChange={props.handleCheckbox}
                                inputProps={{ 'aria-label': 'controlled' }}
                                sx={{
                                    width: 20,
                                    height: 20,
                                    background: 'white',
                                    border: 'none',
                                    borderRadius: '2px',
                                    color: 'rgba(0, 0, 0, 0.33)'
                                }}
                            />
                        } label="Candidates with Kaggle Activity" sx={{
                            margin:'0',
                            fontFamily: 'Nunito',
                            fontWeight: '400',
                            fontSize: '15.75px',
                            lineHeight: '24px',
                            color: '#000000',
                            display: 'flex',
                            gap: '8px'
                        }}/>
                    </FormGroup>
                </div>
            </div>*/}
            <div className="fixed_search_btn">
                <InputButton className='btn btn_fill' type="submit" onClick={() => {
                    mobileKeyword()
                }}>
                    Start Search
                </InputButton>
            </div>
        </LeftPanel>
    )
}

FilterPanel.propTypes = {
    updateFilters: PropTypes.func,
    defaultValues: PropTypes.object,
    resetFilter: PropTypes.func,
    handleCheckbox: PropTypes.func,
}

export default FilterPanel;
