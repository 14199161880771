import {apiHit} from './helpers';
import {setCookie, setCookieExpirationDate} from "../../helpers/cookie";
export const userService = {
    login,
    logout,
    forgetPassword,
    resetPassword,
    changePassword,
    updateUser,
    submitEnquiry,
    registerUser,
    removeUserToken,
    updateRating,
    loginWithToken,
    candidateCheatsheet
};

function login(username, password) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", username)
    urlencoded.append("password", password)
    urlencoded.append("fcm_token", localStorage.getItem('fcmToken'))

    return apiHit('login', urlencoded).then(user => {
        localStorage.setItem('user', JSON.stringify(user.user));
        localStorage.setItem('api_token', JSON.stringify(user.user.api_token));
        setCookie('api_token', user.user.api_token, setCookieExpirationDate());
        return user;
    });
}

function forgetPassword(username) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", username)

    return apiHit('forgot-password', urlencoded).then(user => {
        return user;

    });
}

function resetPassword(input) {
    const urlencoded = new URLSearchParams(input);

    return apiHit('reset-password', urlencoded).then(user => {
        return user;

    });
}

function changePassword(input) {
    const urlencoded = new URLSearchParams(input);

    return apiHit('change-password', urlencoded).then(user => {
        return user;

    });
}

function updateUser(input) {
    const urlencoded = new URLSearchParams(input);

    return apiHit('client/users/update', urlencoded).then(user => {
        return user;

    });
}

function submitEnquiry(inputValueObj) {
    const urlencoded = new URLSearchParams(inputValueObj);

    if (inputValueObj?.enquiryForm) {
        return apiHit(`demo/enquiry`, urlencoded).then(result => {
            return result;
        })
    } else {
        return apiHit(`contact/enquiry`, urlencoded).then(result => {
            return result;
        })
    }
}

function registerUser(input) {
    input.fcm_token = localStorage.getItem('fcmToken');
    const urlencoded = new URLSearchParams(input);

    return apiHit(`registration`, urlencoded).then(user => {
        return user;
    })
}

function logout() {
    const urlencoded = new URLSearchParams();
    setCookie('api_token', false, setCookieExpirationDate());
    return apiHit(`logout`, urlencoded).then(user => {
        return user;
    })
}

function removeUserToken(email, uuid) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("email", email)
    urlencoded.append("uuid", uuid)

    return apiHit(`remove-api-token`, urlencoded).then(user => {
        return user;
    })
}

function updateRating(rating) {
    const urlencoded = new URLSearchParams(rating);

    return apiHit(`client/users/rating/update`, urlencoded).then(user => {
        return user;
    })
}

function loginWithToken(input) {

    const urlencoded = new URLSearchParams(input);
    urlencoded.append("fcm_token", localStorage.getItem('fcmToken'))

    return apiHit(`login/${input.token}`, urlencoded).then(user => {
        localStorage.setItem('user', JSON.stringify(user.user));
        localStorage.setItem('api_token', JSON.stringify(user.user.api_token));
        setCookie('api_token', user.user.api_token, setCookieExpirationDate());
        return user;
    });
}

function candidateCheatsheet(input) {
    const urlencoded = new URLSearchParams(input);
    input?.keyword?.forEach(function ({value}) {
        urlencoded.append(`keyword[]`, value)
    })
    input?.seniority?.forEach(function ({value}) {
        urlencoded.append(`seniority[]`, value)
    })
    urlencoded.delete('keyword')
    urlencoded.delete('seniority')
    return apiHit(`candidate-cheatsheet`, urlencoded).then(user => {
        return user;
    })
}
