import React, {useEffect, useState} from "react";
import AutoComplete from "../../partials/components/AutoComplete";
import useGetProjectAPI from "../../Project/hooks/useGetProjectAPI";
import InputButton from "../../partials/components/InputButton";
import useAddCandidateAPI from "./hooks/useAddCandidateAPI";
import InputField from "../../partials/components/InputField";
import InputSelect from "../../partials/components/InputSelect";
import {createFilterOptions} from "@mui/material";
import useAddOrEditProjectAPI from "../../Project/hooks/useAddOrEditProjectAPI";
import Textarea from "../../partials/components/Textarea";
import validation from "../../../helpers/validation";
import AsyncDropdown from "./AsyncDropdown";
import {isOnlyWhiteSpace} from "../../../helpers";
import {AutoDisabler, ValidationGroup} from "mui-validate";
import useDebounce from "../../../hooks/useDebounce";
import useGATrackAPI from "../../Project/hooks/useGATrackAPI";

const filter = createFilterOptions();

export default function SaveToPipeline(props) {
    const initialState = {
        projectName: '',
        job_title: [],
        industry: [],
        seniority: [],
        companyHiring: '',
        description: '',
        status: 1,
        shared: false
    }

    const autoCompleteInitialState = {
        value: null, errorText: ''
    }

    const [open, setOpen] = useState(false);
    const [openAddProject, setOpenAddProject] = useState(false);
    const [projectDetails, setProjectDetails] = useState(initialState);
    const [project, setProject] = useState(autoCompleteInitialState);
    const [stage, setStage] = useState(autoCompleteInitialState);
    const [searchByProjectParams, setSearchByProjectParams] = useState('');
    const debounceParams = useDebounce(searchByProjectParams, 200);

    const handleChange = (e) => {
        const {name, value, type} = e.target;
        if (type === 'checkbox') {
            setProjectDetails(prevState => ({...prevState, [name]: !prevState[name]}))
        } else {
            setProjectDetails(prevState => ({...prevState, [name]: value}))

        }

    }
    const modal = props.saveToPipeline.showModal

    const onAddProjectSuccess = (data) => {
        setProject(() => ({
            ...initialState, value: {"label": projectDetails.projectName, "value": data?.uuid, 'group': 'Project'}
        }))
        setProjectDetails(initialState)
    }

    const projectAPI = useGetProjectAPI(true, {search_by_project_name: debounceParams}, modal)
    const addCandidateAPI = useAddCandidateAPI();
    const addProject = useAddOrEditProjectAPI(onAddProjectSuccess)

    let options = [];

    useEffect(() => {
        if (!modal) {
            setProjectDetails(initialState)
            setProject(autoCompleteInitialState)
            setStage(autoCompleteInitialState)
        }
    }, [modal])

    if (projectAPI.isSuccess) {
        options = projectAPI.data.projects.map(project => {
            return {"label": project.name, "value": project.uuid, 'group': 'Project'}
        })
    }
    const {getGATrackofDetails} = useGATrackAPI();
    const handleSubmit = () => {
        getGATrackofDetails("saved to pipeline")
        if (!project?.value) {
            setProject(prevState => ({...prevState, errorText: "Please select or add project"}))
            return;
        }

        if (!stage?.value) {
            setStage(prevState => ({...prevState, errorText: "Please select stage"}))
            return;
        }

        if (project?.value && stage?.value) {
            addCandidateAPI.mutate({
                "project": project.value.value,
                "stage": stage.value.value,
                "candidate_uuid": props.saveToPipeline.candidateId
            })
        }
    }

    const selectChange = () => {
        return (value, name) => {
            setProjectDetails(prevState => ({...prevState, [name]: value}))
        }
    }

    const saveProject = (e) => {
        e.preventDefault()
        addProject.mutate({
            "name": projectDetails.projectName,
            "status": projectDetails.status,
            "shared": projectDetails.shared,
            "job_title": projectDetails.job_title?.label ?? '',
            "seniority": projectDetails.seniority?.label ?? '',
            "industry": projectDetails.industry?.label ?? '',
            "description": projectDetails.description,
            "hiring_for_company": projectDetails.companyHiring
        })
    }


    useEffect(() => {
        setStage(autoCompleteInitialState)
        setOpenAddProject(false)
        projectAPI.refetch()
        addProject.reset()

    }, [addProject.isSuccess])

    useEffect(() => {
        if (addCandidateAPI.isSuccess) {
            setProject(autoCompleteInitialState)
            setStage(autoCompleteInitialState)
            addCandidateAPI.reset()
            props.closeSaveToPipeline();

            if (props?.isPaginate) {
                props?.setCandidateSavedInPipeline(true)
            }
        }
    }, [addCandidateAPI.isSuccess])


    // const [isVisible, setIsVisible] = useState(false);
    //
    // const changeHandler = (e) => {
    //     if (e.target.value === 'country') {
    //         setIsVisible(true);
    //     } else {
    //         setIsVisible(false);
    //     }
    // };

    return (<>
        <div style={{width: 550, visibility: modal ? 'visible' : 'hidden'}}
             className={modal ? "offcanvas offcanvas-end show" : "offcanvas offcanvas-end"}
             aria-labelledby="offcanvasRightLabel">
            <div className="pro_title_area">
                <h4>Save to Pipeline</h4>
                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close" onClick={props.closeSaveToPipeline}></button>
            </div>

            <div className="author_des sidebar_widget">
                <div className="offcanvas-body">
                    <div className="author_content">
                        {!openAddProject && <div className="project_field">
                            <AutoComplete
                                fullWidth
                                name="project"
                                label="Projects"
                                placeholder="Type to Search for Project"
                                sx={{
                                    input: {
                                        color: '#3D3E40;', "&::placeholder": {
                                            opacity: 1,
                                        },
                                    }
                                }}
                                required={true}
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                errorText={project?.errorText}
                                isOptionEqualToValue={(o, v) => o.value === v.value}
                                loading={projectAPI.isLoading}
                                classes={{
                                    'listbox': 'rp_mui_listbox',
                                    'groupUl': 'rp_mui_group_ul',
                                    "groupLabel": "rp_mui_group_label"
                                }}
                                options={options}
                                onChange={(e, v) => {
                                    if (v?.setProjectName) {
                                        setProjectDetails((prevState) => ({...prevState, projectName: v.value}))
                                    } else {
                                        setProjectDetails((prevState) => ({...prevState, projectName: ''}))
                                    }
                                    if (v?.newOption) {
                                        setOpenAddProject(true)
                                    } else {
                                        setOpenAddProject(false)
                                    }
                                    setSearchByProjectParams('')
                                    return setProject(prevState => ({...prevState, value: v, errorText: ''}))
                                }}
                                onInputChange={(e) => e?.target && setSearchByProjectParams(e.target.value)}
                                value={project.value}
                                renderOption={(props, option) => {
                                    if (option.newOption) {
                                        return <li {...props} style={{
                                            fontWeight: '700',
                                            width: '100%',
                                            color: '#dc0022',
                                            backgroundColor: '#ffebee'
                                        }}>+ {option.label}</li>
                                    }
                                    return <li {...props}>{option.label}</li>
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const {inputValue} = params;
                                    const isExisting = options.some((option) => inputValue === option.label);
                                    if (inputValue !== '' && !isExisting) {
                                        filtered.unshift({
                                            value: inputValue,
                                            newOption: true,
                                            setProjectName: true,
                                            label: `Add: "${inputValue}"`,
                                        });
                                    } else if (!isExisting) {
                                        filtered.unshift({
                                            value: inputValue, newOption: true, label: `Add new project`,
                                        });
                                    }

                                    return filtered;
                                }}
                            />
                            <AutoComplete
                                fullWidth
                                name="stage"
                                label="Stage"
                                required={true}
                                onKeyDown={(e) => e.preventDefault()}
                                classes={{
                                    'listbox': 'rp_mui_listbox',
                                    'groupUl': 'rp_mui_group_ul',
                                    "groupLabel": "rp_mui_group_label"
                                }}
                                errorText={stage?.errorText}
                                onChange={(e, v) => setStage(prevState => ({
                                    ...prevState, value: v, errorText: ''
                                }))}
                                value={stage.value}
                                isOptionEqualToValue={(o, v) => o.value === v.value}
                                options={[{"label": "Uncontacted", "value": "Uncontacted"}, {
                                    "label": "Contacted", "value": "Contacted"
                                }, {"label": "Rejected", "value": "Rejected"},]}
                            />
                            <InputButton
                                variant="contained"
                                size="large"
                                onClick={handleSubmit}
                                type="submit"
                                isLoading={addCandidateAPI.isLoading}
                                color="warning">
                                Save To Pipeline
                            </InputButton>
                        </div>}
                        {openAddProject &&
                            <ValidationGroup>
                                <form className="form_detail" onSubmit={saveProject}>
                                    <InputField
                                        fullWidth
                                        type="text"
                                        required={[true, 'Please enter project name']}
                                        name="projectName"
                                        placeholder="Create a project"
                                        value={projectDetails.projectName}
                                        onChange={handleChange}
                                        label="Project Name*"
                                        customValidation={[(value) => !isOnlyWhiteSpace(value), 'Please enter project name']}/>
                                    <span
                                        className="tagline">By default, projects are private until shared with others</span>
                                    <h6>Project details</h6>
                                    <div className="project_field">
                                        <AsyncDropdown
                                            mui={true}
                                            placeholder="Role (Optional)"
                                            handleSelectChange={selectChange()}
                                            name="job_title"
                                            url="title/master/search"
                                            label="Job Title"
                                            isMultiple={false}
                                            defaultValue={projectDetails.job_title}
                                        />
                                        <br/>
                                        <AsyncDropdown
                                            mui={true}
                                            placeholder="Industry (optional)"
                                            handleSelectChange={selectChange()}
                                            name="industry"
                                            isMultiple={false}
                                            defaultValue={projectDetails.industry}
                                            url="industry/master/search"
                                            label="Industries"
                                        />
                                        <br/>
                                        <AsyncDropdown
                                            mui={true}
                                            placeholder="Seniority (optional)"
                                            handleSelectChange={selectChange()}
                                            name="seniority"
                                            isMultiple={false}
                                            defaultValue={projectDetails.seniority}
                                            url="seniority/master/search"
                                            label="Seniority"
                                        />
                                        <br/>
                                        <InputField
                                            type="text"
                                            fullWidth
                                            name="companyHiring"
                                            placeholder="CoffeeMug"
                                            required={[true, 'Please enter company name']}
                                            value={projectDetails.companyHiring}
                                            onChange={handleChange}
                                            label="Company hiring for*"
                                            customValidation={[(value) => !isOnlyWhiteSpace(value), 'Please enter company name']}/>
                                        <Textarea
                                            name="description"
                                            label="Project Description"
                                            value={projectDetails.description}
                                            onChange={handleChange}
                                            minRows={4}
                                            placeholder="Project Description (optional)"
                                        />
                                        <div className="inline_buttons">
                                            <AutoDisabler firstDisplayErrors={true}>
                                                <InputButton
                                                    variant="contained"
                                                    size="large"
                                                    color='warning'
                                                    type="submit"
                                                    isLoading={addProject.isLoading}
                                                >
                                                    Save and Continue
                                                </InputButton>
                                            </AutoDisabler>
                                            <button className="btn_outline"
                                                    onClick={() => {
                                                        setProjectDetails(initialState)
                                                        setProject(autoCompleteInitialState)
                                                        setStage(autoCompleteInitialState)
                                                        setOpenAddProject(false)
                                                    }}>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </ValidationGroup>}
                    </div>
                </div>
            </div>
        </div>
        {modal ? <div className="modal-backdrop fade show" onClick={props.closeSaveToPipeline}></div> : <></>}
    </>)
}