import React, {useState} from "react";
import PropTypes from "prop-types";

function GithubData(props) {

    const {candidate, showGithub} = props;

    const github = candidate.other_sources.github

    const [state, setState] = useState({
        allLanguages: false,
        allRepositories: false,
    });

    if (!showGithub) {
        return null;
    }

    return (
        <div className="main_user_content">
            <ul>
                <li>
                    <div className="l_content">
                        <h4>Github</h4>
                    </div>
                    <div className="clearfix">
                        {/*{showGithub &&*/}
                        {/*    <div className="scores">*/}
                        {/*        <h5>Coder Scores</h5>*/}
                        {/*        <ul className="star">*/}
                        {/*            <li className="active"><ion-icon name="star"></ion-icon></li>*/}
                        {/*            <li className="active"><ion-icon name="star"></ion-icon></li>*/}
                        {/*            <li className="active"><ion-icon name="star"></ion-icon></li>*/}
                        {/*            <li className="active"><ion-icon name="star"></ion-icon></li>*/}
                        {/*            <li className="not_active"><ion-icon name="star"></ion-icon></li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*}*/}
                        {showGithub &&
                            <div className="scores">
                                <h5>Coder Scores</h5>
                                <ul className="star">
                                    {Array(Math.floor(github.profile_rank)).fill(1).map((i, j) => (
                                        <li className="active" key={j}><ion-icon name="star"></ion-icon></li>
                                    ))}
                                    {(Math.ceil(github.profile_rank) - Math.floor(github.profile_rank)) === 1 &&
                                        <li className="not_active" key='half'><ion-icon name="star"></ion-icon></li>
                                    }
                                </ul>
                            </div>
                        }
                        <div className="coder_score_budge_gray">
                            {github.languages.slice(0, state.allLanguages ? undefined : 12).map((language, key) => {
                                return <div className="data_list" key={key}>

                                    <div className="coder_score_gray">
                                        <span>{language.rating ?? 0 }</span>
                                        <ion-icon name="star" role="img" className="md hydrated" aria-label="star"></ion-icon>
                                    </div>
                                    <p>{language.lang}</p>
                                    {/*<div className="star">*/}
                                    {/*    {Array(Math.floor(language?.rating ?? 0)).fill(1).map(i => (*/}
                                    {/*        <ion-icon name="star"></ion-icon>*/}
                                    {/*    ))}*/}
                                    {/*    {(Math.ceil(language.rating) - Math.floor(language.rating)) === 1 &&*/}
                                    {/*        <ion-icon name="star-half"></ion-icon>*/}
                                    {/*    }*/}
                                    {/*</div>*/}
                                </div>
                            })}
                        </div>
                        {
                            github.languages.length > 12 &&
                            <div>
                                <button className="show_more_btn mt-0" onClick={() => {
                                    setState(prevState => ({...prevState, allLanguages: !prevState.allLanguages}))
                                }}>
                                    {state.allLanguages ? "Show less" : "Show all"}
                                </button>
                            </div>
                        }
                    </div>
                </li>
                <li>
                    <div className="l_content"></div>
                    <div className="repositories">
                        <h4 className="heading_h4">Top Repositories</h4>
                        <ul>
                            {github.pinnedrepos.slice(0, state.allRepositories ? undefined : 4).map((repo, key) => (
                                <li key={key}>
                                    <a href={"https://github.com" + repo.url} target="_blank" rel="noreferrer">{repo.name}</a>
                                    {repo.lang ?
                                        <>
                                            <div className="black_dot"></div>
                                            <span>({repo.lang})</span>
                                        </> : ''
                                    }
                                </li>
                            ))}
                        </ul>
                        {
                            github.pinnedrepos.length > 4 &&
                            <div className="row_lg">
                                <button className="show_more_btn mt-0" onClick={() => {
                                    setState(prevState => ({
                                        ...prevState,
                                        allRepositories: !prevState.allRepositories
                                    }))
                                }}>
                                    {state.allRepositories ? "Show less" : "Show all"}
                                </button>
                            </div>
                        }
                    </div>
                </li>
            </ul>
        </div>
    )
}

GithubData.propTypes = {
    candidate: PropTypes.object,
    showGithub: PropTypes.bool,
}

export default GithubData