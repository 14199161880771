import {useQuery} from "react-query";
import {candidateService} from "../../../redux/services";

const getPendingDownloadedProfile = async ({queryKey}) => {
    const [_key] = queryKey
    return await candidateService.getPendingDownloadedProfile();
};

export default function useGetPendingDownloadedProfilesAPI(enabled) {
    return useQuery(["getPendingDownloadProfile"], getPendingDownloadedProfile, {
        enabled: enabled,
        refetchOnWindowFocus: false,
        retry: false
    });
}
