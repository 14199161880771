import {useQuery} from "react-query";
import {candidateService} from "../../../redux/services";

const similarList = async ({queryKey}) => {
    const [_key, params] = queryKey

    const data = await candidateService.similarFinder(params);
    return data.data;
};


export default function useSimilarFinderAPI(params, enabled) {
    return useQuery(['userData', params], similarList, {
        enabled: enabled, refetchOnWindowFocus: true, cacheTime: 0
    });
}