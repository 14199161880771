import React from "react";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {Validate} from "mui-validate";

const AutoComplete = (props) => {
    const {
        value,
        name,
        loading,
        options,
        onOpen,
        onClose,
        inputValue,
        onChange,
        onInputChange,
        includeInputInList,
        label,
        fullWidth,
        required = false,
        errorText,
        regex,
        isOptionEqualToValue,
        classes,
        open,
        sx,
        placeholder,
        onKeyDown
    } = props

    let {
        custom, filterOptions, renderOption,
    } = props

    if (!filterOptions) {
        filterOptions = (x) => x;
    }

    if (!renderOption) {
        renderOption = null;
    }

    return (<Validate
        name={name}
        required={required}
        regex={regex}
        custom={custom}>
        <Autocomplete
            fullWidth={fullWidth}
            disablePortal
            // open={open}
            // onClose={onClose}
            includeInputInList={true}
            autoSelect={false}
            openOnFocus={true}
            options={options}
            loading={loading}
            // onOpen={onOpen}
            value={value}
            inputValue={inputValue}
            onChange={onChange}
            name={name}
            classes={classes}
            sx={sx}
            groupBy={(options) => options.group}
            onInputChange={onInputChange}
            filterOptions={filterOptions}
            isOptionEqualToValue={isOptionEqualToValue}
            renderOption={renderOption}
            renderInput={(params) => <TextField
                {...params}
                label={label}
                placeholder={placeholder}
                onKeyDown={onKeyDown}
                required={required}
                helperText={!!errorText && <span className='rp-error'>{errorText}</span>}
                InputProps={{
                    ...params.InputProps, endAdornment: (<React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20}/> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>),
                }}/>}
        />
    </Validate>);
};

export default AutoComplete;