import {useQuery} from "react-query";
import {searchServices} from "../../../redux/services";

const getSavedSearchList = async ({queryKey}) => {
    const [_key, {params}] = queryKey

    return await searchServices.getSavedSearchList(params.prefix, params.data);
};
export default function useGetSavedSearchListAPI(params, queryEnable) {
    return useQuery(["savedSearch", {params}], getSavedSearchList, {
        refetchOnWindowFocus: false,
        retry: false,
        enabled:queryEnable
    });
}
