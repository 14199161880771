import React, {useEffect, useState} from "react";
import _ from "lodash";
import {useLocation} from "react-router-dom";
import {history} from "../../../helpers";
import {masterService} from "../../../redux/services";
import useMasterDataAPI from "../partials/hooks/useMasterDataAPI";

export const useFilter = ({initialFilters, initialRelated = null, filters, filterType, similar}) => {
    const [filter, setFilter] = useState(initialFilters);
    const [related, setRelated] = useState(initialRelated);
    const [savedFilterDetails, setSavedFilterDetails] = useState(null);
    const [isFilterSet, setIsFilterSet] = useState(false);
    const [filterOptions, setFilterOptions] = useState([]);
    const [runOnce, setRunOnce] = useState(true)

    const location = useLocation();
    const relatedLinks = {
        jobTitle: [{url: 'title-to-title/related/search', name: 'jobTitle'}, {
            url: 'title-to-skill/related/search', name: 'skill'
        }],
        excludeJobTitle: [{url: 'title-to-title/related/search', name: 'excludeJobTitle'}],
        mandatorySkill: [{url: 'skill-to-skill/related/search', name: 'mandatorySkill'}],
        skill: [{url: 'skill-to-skill/related/search', name: 'skill'}],
        industry: [{url: 'industry-to-company/related/search', name: 'company'}],
        company: [{url: 'company-to-company/related/search', name: 'company'}],
        excludeCompany: [{url: 'company-to-company/related/search', name: 'excludeCompany'}],
        education: [{url: 'educations/related-educations', name: 'educations'}],
        keyword: [{url: 'keyword/related/search', name: 'keyword'}]
    };

    const {status, data, isLoading, isSuccess} = useMasterDataAPI('', {'url': "all/master/search"}, true)

    if (runOnce && !similar) {
        setRunOnce(false)
        const search = new URLSearchParams(location.search)
        const result = {};
        for (let [key, value] of search) {
            if (filter[key] !== undefined) {
                if (value.includes('~') || (value.includes(',') && key !== 'location')) {
                    if (value.includes('~')) {
                        result[key] = value.split('~').map(v => {
                            return {label: v, value: v}
                        })
                    } else {
                        result[key] = value.split(',').map(v => {
                            return {label: v, value: v}
                        })
                    }
                } else {
                    if (value !== '' && value !== 'false' && value !== 'true' && key !== 'booleanSearch' && key !== 'skillsAndOr') {
                        let val = value
                        if (key === 'experience' && value) {
                            const expValue = value?.split('-')
                            if (expValue && +expValue[0] && +expValue[1]) {
                                val = `${expValue[0]}-${expValue[1]}`
                            } else {
                                val = "0-25"
                            }
                        }
                        result[key] = [{label: val, value: val}]
                    } else {
                        if (value === 'false' || value === 'true') {
                            value = (value === 'true');
                        }
                        result[key] = value;
                    }
                }
            }
        }

        if (Object.keys(result).length > 0) {
            setFilter(prevState => {
                return {...prevState, ...result}
            })
        }

        if (filterType === 'candidateSearch') {
            const fil = JSON.parse(location?.state?.searchData ?? null)
            if (location?.state?.id) {
                setSavedFilterDetails(location.state)
            }
            if (fil && !_.isEqual(filter, fil)) {
                setFilter(prevState => ({...prevState, ...fil.filter}))
            }
        }
    }

    useEffect(() => {
        if (data) {
            setFilterOptions(prevState => ({...initialFilters, ...data.data}))
        }
    }, [isSuccess])

    useEffect(() => {
        if (!runOnce && location?.state) {
            setRunOnce(true)
        }
    }, [location?.state])

    useEffect(() => {
        const paramString = new URLSearchParams(filterUrl()).toString();
        if (!paramString) {
            setIsFilterSet(false)
        } else {
            setIsFilterSet(true)
        }
        history.replace({
            pathname: location.pathname, search: paramString
        });
    }, [filter])

    const handleSuggestions = (filters) => {
        for (const key in filters) {
            if (Array.isArray(filters[key]) && filters[key].length > 0) {
                if (relatedLinks.hasOwnProperty(key)) {
                    updateFilters({url: relatedLinks[key], name: key, value: filters[key]})
                }
            }
        }
    }

    useEffect(() => {
        if (!runOnce) {
            handleSuggestions(filter)
        }
    }, [savedFilterDetails])

    useEffect(() => {
        if (filters) {
            setFilter(prevState => ({...prevState, ...filters}))
        }

        const filterList = similar ? filters : filter

        handleSuggestions(filterList)
    }, [])


    const resetFilter = () => {
        setFilter(initialFilters)
        setRelated(initialRelated)
    }
    const handleCandidateSearchFilter = ({name, value}) => {

        if (filterType === 'candidateSearch') {
            if (name === 'jobTitle' && value.length === 0) {
                setFilter(prevState => ({...prevState, 'pastJobTitle': initialFilters.pastJobTitle}));
            }

            if (name === 'excludeJobTitle' && value.length === 0) {
                setFilter(prevState => ({...prevState, 'excludePastJobTitle': initialFilters.excludePastJobTitle}));
            }

            if (name === 'company' && value.length === 0) {
                setFilter(prevState => ({...prevState, 'pastCompany': initialFilters.pastCompany}));
            }

            if (name === 'industry' && value.length === 0) {
                setFilter(prevState => ({...prevState, 'pastIndustry': initialFilters.pastIndustry}));
            }
            if (name === 'degree' && value.length === 0) {
                setFilter(prevState => ({...prevState, 'premiumDegree': initialFilters.premiumDegree}));
            }

            if (name === 'skill' && value.length === 0) {
                setFilter(prevState => ({...prevState, 'skillsAndOr': initialFilters.skillsAndOr}));
            }
        }
    }
    const _updateFilter = async ({url, value, relatedName, name}) => {
        let valueName = value
        const skill = [];
        if (typeof value === 'string') {
            skill.push(...filter[name])
            skill.push({'value': valueName, 'label': valueName,});
        } else {
            handleCandidateSearchFilter({name, value})
            skill.push(...value)
        }
        const skillNames = skill.map((s) => {
            return s.value
        })

        setFilter(prevState => {
            return {...prevState, [name]: skill}
        })

        await masterService.relatedResult(url, skillNames)
            .then(data => {
                let relatedSkill = [];
                if (data.data) {
                    relatedSkill = data.data.filter(function (item) {
                        return !skillNames.includes(item.name)
                    })
                }

                setRelated(prevState => {
                    return {...prevState, [relatedName]: relatedSkill}
                })
            });
    }
    const updateFilters = async ({url, value, name, type = 'dropdown'}) => {
        if (value === null) value = [];

        if (filterType === 'kaggle') {
            if (value.length === 0) setIsFilterSet(false)
            setFilter(prevState => ({...prevState, [name]: value}));
            return false
        }

        if (url !== undefined) {
            if (typeof url === 'string') {
                await _updateFilter({url, value, relatedName: name, name})
            } else if (Array.isArray(url)) {
                for (const urlKey in url) {
                    await _updateFilter({url: url[urlKey].url, value, relatedName: url[urlKey].name, name})
                }
            }
        } else {

            setFilter(prevState => ({...prevState, [name]: value}));

            if (name === 'keyword' && value.length === 0) {
                setFilter(prevState => ({...prevState, "booleanSearch": initialFilters.booleanSearch}));
            }

            handleCandidateSearchFilter({name, value})

            if (type === 'dropdown' && value) {
                let skillNames = [value.value];
                if (Array.isArray(value)) {
                    skillNames = value.map((s) => {
                        return s.value
                    })
                }
                let relatedSkill = related[name];
                if (relatedSkill && relatedSkill.length > 0) {
                    setRelatedValues(name, relatedSkill, skillNames)
                }
            }
        }

        if (name === 'location') {
            let disableRadius = initialFilters.disableRadius
            if (value) {
                disableRadius = !value.every(v => v.type === 'city')
            }

            setFilter(prevState => ({...prevState, disableRadius}))
        }
    }
    const handleCheckbox = (event) => {
        const name = event.target.name;
        if (event.target.type === 'checkbox') {

            if (filterType === 'candidateSearch') {

                if (name === 'pastJobTitle' && filter.jobTitle.length === 0) return;

                if (name === 'excludePastJobTitle' && filter.excludeJobTitle.length === 0) return;

                if (name === 'pastCompany' && filter.company.length === 0) return;

                if (name === 'pastIndustry' && filter.industry.length === 0) return;

                if (name === 'premiumDegree' && filter.degree.length === 0) return;
            }

            setFilter(prevState => {
                return {...prevState, [name]: !prevState[name]}
            })
        }

        if (name === 'premiumInstitutes') {
            setFilter(prevState => {
                return {...prevState, 'education': []};
            })
        }
    }
    const setRelatedValues = (name, values, skillNames = undefined) => {
        if (skillNames === undefined) {
            skillNames = [filter[name]];
            if (Array.isArray(filter[name])) {
                skillNames = filter[name].map((s) => {
                    return s.value
                })
            }
        }
        let relatedSkill = values;
        relatedSkill = relatedSkill.map(function (item) {
            if (skillNames.includes(item.value)) {
                return {...item, status: false}
            } else {
                return {...item, status: true}
            }
        })
        setRelated(prevState => {
            return {...prevState, [name]: relatedSkill}
        })
    }

    function filterUrl(stateFilter) {
        // const filters = this.state.filter.map((v) => v.value || v)
        const filters = {};
        if (stateFilter === undefined) {
            stateFilter = filter;
        }
        const filterKeys = Object.keys(stateFilter);
        for (const key in filterKeys) {
            const filterKey = filterKeys[key];
            const value = stateFilter[filterKey];
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    filters[filterKey] = value.map(f => f.value).join('~')
                }

                if (filterType === 'candidateSearch') {
                    if (key === 'jobTitle' && value.length === 0 && !filters['pastJobTitle']) {
                        filters['pastJobTitle'] = initialFilters.pastJobTitle;
                    }

                    if (key === 'excludeJobTitle' && value.length === 0 && !filters['excludePastJobTitle']) {
                        filters['excludePastJobTitle'] = initialFilters.excludePastJobTitle;
                    }

                    if (key === 'company' && value.length === 0 && !filters['pastCompany']) {
                        filters['pastCompany'] = initialFilters.pastCompany;
                    }

                    if (key === 'industry' && value.length === 0 && !filters['pastIndustry']) {
                        filters['pastIndustry'] = initialFilters.pastIndustry;
                    }
                    if (key === 'degree' && value.length === 0 && filters['premiumDegree ']) {
                        filters['premiumDegree'] = initialFilters.premiumDegree;
                    }
                }
            } else {
                if (typeof value === 'object') {
                    if (value === null) {
                        filters[filterKey] = []
                    } else {
                        filters[filterKey] = value.value
                    }
                } else {
                    if (value !== initialFilters[filterKey] && value !== '') filters[filterKey] = value
                }
            }
        }
        return filters;
    }

    return {
        filter,
        setFilter,
        related,
        setRelated,
        savedFilterDetails,
        setSavedFilterDetails,
        isFilterSet,
        setIsFilterSet,
        updateFilters,
        handleCheckbox,
        resetFilter,
        setRelatedValues,
        filterOptions
    }
}