import * as React from 'react';
import {Alert as MuiAlert, AlertTitle, Snackbar} from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AlertSnackBar({open, title, message, index, autoHideDuration, onClose, severity, style, url = null, className = ''}) {
    const handleClose = (event, reason) => {
        onClose()
    };

    return (<Snackbar open={open}  onClose={handleClose} autoHideDuration={autoHideDuration}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                      style={style} className={className} key={index}
        >
            <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                <AlertTitle>
                    {!url ? title : <a href={url} target="_blank" rel="noreferrer">{title}</a>}
                </AlertTitle>
                {message}
            </Alert>
        </Snackbar>

    );
}