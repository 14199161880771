import {useQuery} from "react-query";
import {candidateService} from "../../../../redux/services";

const getCandidateDetails = async ({queryKey}) => {
    const [_key, {details}] = queryKey
    return await candidateService.details(details)
};

export default function useCandidateDetailsAPI(details, current) {
    return useQuery(["candidateDetails", {details}], getCandidateDetails, {
        refetchOnWindowFocus: false,
        enabled: current
    });
}
