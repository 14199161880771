import React, {useState} from "react";
import CardHeader from "./CardHeader";
import CardData from "./CardData";
import PropTypes from "prop-types";

function SimilarCard(props) {
    const {candidate, openJsonPopup, openDetailsModal} = props;

    const [state, setState] = useState(false);

    return (
        <div className={`user_item ${state ? 'mystyle' : ''}`} id="user_content1">
            <CardHeader
                candidate={candidate}
                pipeline={false}
                highlight={true}
                openJsonPopup={openJsonPopup}
                openDetailsModal={openDetailsModal}
            />
            {/*<CardData*/}
            {/*    candidate={candidate}*/}
            {/*/>*/}
            {/*<button onClick={() => setState(prevState => !prevState)} className="show_less" type="button">*/}
            {/*    <span>*/}
            {/*        <ion-icon name={state ? "arrow-up" : "arrow-down"}></ion-icon>*/}
            {/*    </span> <span>{state ? 'show less' : 'show more'}</span>*/}
            {/*</button>*/}
        </div>
    )
}

SimilarCard.propTypes = {
    candidate: PropTypes.object,
}

export default SimilarCard
