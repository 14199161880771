import React, {useEffect, useState} from "react";
import useRemoveUserTokenAPI from "./partials/hooks/useRemoveUserTokenAPI";
import useLoginAPI from "./partials/hooks/useLoginAPI";
import InputButton from "./partials/components/InputButton";
import {useNavigate} from "react-router-dom";

export default function MultipleLoginHandleModal(props) {

    const {multipleLoginDetails, showModal, closeShowModal, setLoginDetails} = props;
    const [deviceList, setDeviceList] = useState([])
    const removeUserLogoutApi = useRemoveUserTokenAPI();
    const navigate = useNavigate()
    const loginApi = useLoginAPI(multipleLoginDetails?.token ?? '');
    const handleCloseModal = () => {
        closeShowModal(false);
    }
    const handleUserToken = (e, uuid) => {
        removeUserLogoutApi.mutate({uuid, email: multipleLoginDetails.email})
        const newDeviceList = multipleLoginDetails?.devices.filter((item) => item.uuid !== uuid);

        setLoginDetails(prevState => ({...prevState, devices: newDeviceList}));

        if (newDeviceList.length === 0) {
            handleLogin();
        }
    }

    const handleLogin = () => {
        if(multipleLoginDetails?.token) {
            loginApi.mutate({token: multipleLoginDetails?.token})
        } else {
            loginApi.mutate({username: multipleLoginDetails.email, password: multipleLoginDetails.password})
        }
    }

    if(loginApi.isSuccess && multipleLoginDetails?.afterLoginSendTo) {
        localStorage.removeItem('userToken')
        navigate(multipleLoginDetails?.afterLoginSendTo)
        closeShowModal(false);
        loginApi.reset()
    }

    useEffect(() => {
        if (multipleLoginDetails) {
            const tempData = []
            multipleLoginDetails?.devices?.forEach((multipleLoginDetail) => {
                tempData.push(<li key={multipleLoginDetail.uuid}>
                    <span> {multipleLoginDetail?.device}</span>
                    <span>{multipleLoginDetail?.last_used_at}</span>
                    <span>
                            <InputButton
                                type="submit"
                                className='btn_outline remove_btn'
                                onClick={(e) => handleUserToken(e, multipleLoginDetail.uuid)}
                            > Remove
                        </InputButton>
                        </span>
                </li>)
            })

            setDeviceList(tempData)
        }
    }, [multipleLoginDetails])

    return (<div className="modal modal_style">
        <div className="modal_content">
            <button className="close" onClick={handleCloseModal}>X</button>
            <h4>Device limit reached</h4>
            <p>You've already reached the device limit of your license. Please remove a device or get help from your
                company support</p>
            <h6>Device limit
                (<span>{multipleLoginDetails?.devices?.length}</span>{`/${multipleLoginDetails?.maxDevices}`})</h6>
            <div className="all_device">
                <ul className="modal_head">
                    <li>Device</li>
                    <li>Last Accessed</li>
                    <li>Edit Access</li>
                </ul>
                <ul className="modal_body">
                    {deviceList}
                </ul>
            </div>
            <ul className="modal_footer">
                <li>
                    <a onClick={handleCloseModal} className="btn btn_fill fill_btn text_dec">Cancel</a>
                </li>
                <li>
                    <InputButton
                        type="submit"
                        className='btn btn_fill fill_btn text_dec'
                        disabled={(+multipleLoginDetails?.maxDevices === +multipleLoginDetails?.devices?.length)}
                        onClick={handleLogin}
                        isLoading={loginApi.isLoading}> Try Again
                    </InputButton>
                </li>
            </ul>
        </div>
    </div>)
}





