import {useQuery} from "react-query";
import {candidateService} from "../../../../redux/services";

const getCandidateEmailAndPhone = async ({queryKey}) => {
    const [_key, uuid] = queryKey
    return await candidateService.getCandidateEmailAndPhone(uuid);
};

export default function useGetCandidateEmailAndPhoneAPI(uuid, fetchData) {
    return useQuery(["candidateEmailAndPhone", uuid], getCandidateEmailAndPhone, {
        enabled: fetchData, retry: 0, refetchOnWindowFocus: false, cacheTime: 0
    });
}