import React from 'react';
import {Skeleton} from "@mui/material";

export default function CardSkeleton() {
    return (<div className="prof_layout3 user_item">
        <div className="user_template_item">
            <div className="user_template_header">
                <div className="user_template_info">
                    <div className="user_title user_all_details">
                        <div className="user_profl">
                            <div className="userimage">
                                <Skeleton variant="circular" width={70} height={70}/>
                            </div>
                        </div>
                        <div className="user_details_area">
                            <div className="user_details">
                                <div className="template_user_name"><h3>
                                    <a href="#"><Skeleton variant="text" sx={{width: '150px'}}/></a>
                                </h3></div>
                                <div className="user_designation">
                                    <h4><Skeleton variant="text" sx={{width: '110px'}}/></h4>
                                </div>
                                <div className="user_address"><Skeleton variant="text" sx={{width: '100px'}}/></div>
                                <div className="perfomance_bar">
                                    <p><Skeleton variant="text" sx={{width: '100px', marginRight: "20px"}}/></p>
                                    <ul className="perfomance_graph">
                                        {[1, 2, 3, 4, 5].map((i) => <Skeleton key={i} variant="text"
                                                                              sx={{width: '35px'}}/>)}
                                    </ul>
                                </div>
                            </div>
                            <div className="user_right row_gap">
                                <div className="btn-group">
                                    <Skeleton variant="text" sx={{width: '180px', height: '60px'}}/>
                                </div>
                                <Skeleton variant="text" sx={{width: '150px', height: '60px'}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="main_user_content">
            <ul>
                <li>
                    <div className="l_content"><h4><Skeleton variant="text" sx={{width: '100px'}}/></h4></div>
                    <div className="r_content">
                        <div className="row_lg"><p className="highlight_text"><Skeleton variant="text"
                                                                                        sx={{width: '80px'}}/></p>
                        </div>
                        <div>
                            <div className="user_designation">
                                <Skeleton variant="text" sx={{width: '400px'}}/>
                            </div>
                        </div>
                        <div>
                            <div className="user_designation">
                                <Skeleton variant="text" sx={{width: '400px'}}/>
                            </div>
                        </div>
                        <div>
                            <div className="user_designation">
                                <Skeleton variant="text" sx={{width: '400px'}}/>
                            </div>
                        </div>
                        <div className="row_lg"><a className="more_less" href="#">
                            <Skeleton variant="text" sx={{width: '80px'}}/>
                        </a></div>
                    </div>
                </li>
                <li>
                    <div className="l_content"><h4><Skeleton variant="text" sx={{width: '100px'}}/></h4></div>
                    <div className="r_content">
                        <div className="row_lg"><p className="highlight_text"><Skeleton variant="text"
                                                                                        sx={{width: '80px'}}/></p>
                        </div>
                        <div>
                            <div className="user_designation">
                                <Skeleton variant="text" sx={{width: '400px'}}/>
                            </div>
                        </div>
                        <div>
                            <div className="user_designation">
                                <Skeleton variant="text" sx={{width: '400px'}}/>
                            </div>
                        </div>
                        <div>
                            <div className="user_designation">
                                <Skeleton variant="text" sx={{width: '400px'}}/>
                            </div>
                        </div>
                        <div className="row_lg"><a className="more_less" href="#">
                            <Skeleton variant="text" sx={{width: '80px'}}/>
                        </a></div>
                    </div>
                </li>
                <li>
                    <div className="l_content"><h4><Skeleton variant="text" sx={{width: '100px'}}/></h4></div>
                    <div className="r_content inline_pills">
                        <ul>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => <Skeleton key={i} variant="rounded" sx={{
                                width: '35px', borderRadius: '20px'
                            }}/>)}
                        </ul>
                        <div className="row_lg"><a className="more_less" href="#"><Skeleton variant="text"
                                                                                            sx={{width: '80px'}}/></a>
                        </div>
                    </div>
                </li>
                <li>
                    <div className="l_content"><h4><Skeleton variant="text" sx={{width: '100px'}}/></h4></div>
                    <div className="r_content inline_pills">
                        <ul>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => <Skeleton key={i} variant="rounded" sx={{
                                width: '80px', borderRadius: '20px'
                            }}/>)}
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </div>)
}