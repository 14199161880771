import {useQuery} from "react-query";
import {companyService} from "../../../redux/services";

const projectList = async ({queryKey, signal}) => {
    const [_key, params] = queryKey
    const data = await companyService.getAllProject(signal, params);
    return data.data;
};

export default function useGetAllProjectAPI(params, enabled) {
    return useQuery(["projectData", params], projectList, {enabled: enabled, refetchOnWindowFocus: false});
}
