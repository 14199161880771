import React, {useEffect, useState} from 'react';
import Select from 'react-select'
import useMasterDataAPI from "./hooks/useMasterDataAPI";
import PropTypes from "prop-types";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import useDebounce from "../../../hooks/useDebounce";
import _ from 'lodash';
import useGATrackAPI from "../../Project/hooks/useGATrackAPI";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

function AsyncDropdown(props) {

    const [inputValue, setInputValue] = useState("");
    const [enabled, setEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const setSearchTextDebounced = useDebounce(inputValue, 500);

    let isDisabled = false, isMultiple = true, /*isCreatable = true,*/ isAsync = true

    if (props.isDisabled === true) isDisabled = true
    if (props.isMultiple === false) isMultiple = false
    // if (props.isCreatable === false) isCreatable = false
    if (props.isAsync === false) isAsync = false
    const {status, data, isLoading, isSuccess} = useMasterDataAPI(setSearchTextDebounced, props, enabled)

    useEffect(() => {
        if (props?.filterOptions === undefined && !props.mui) {
            if (!(props?.allOptions?.length > 0)) {
                setLoading(true)
            }
        } else {
            setLoading(false)
        }
        if (!enabled && (props.mui || props?.filterOptions?.length === 0 || props?.allOptions?.length > 0)) {
            setEnabled(true)
        }
    }, [props?.filterOptions])

    useEffect(() => {
        if (status === 'success' && typeof props.setRelatedValues === 'function') {
            props.setRelatedValues(props.name, data)
        }
    }, [status])

    const onInputChangeMui = (e, value, reason) => {
        if (isAsync && reason === 'input') {
            setInputValue(value)
        }
    }

    const onInputChange = (value) => {
        if (isAsync) {
            if (!enabled && value) {
                setEnabled(true)
            }
            setInputValue(value)
        }
    }
    const handleChangeMui = (e, selectedOptions) => {
        props.handleSelectChange(selectedOptions, props.name)
    }
    const {getTrackOfEvent} = useGATrackAPI();
    const handleChange = (selectedOptions, e) => {
        if (e?.option?.value === 'Loading...') {
            return;
        }
        if (props.name && e?.option?.value) {
            getTrackOfEvent(`${props.gaTrackValue} ${props.name}`, `${props.gaTrackValue} ${e.option.value}`);
        }
        setSelectedOptions(selectedOptions)
        props.handleSelectChange(selectedOptions, props.name)
    }

    // useEffect(() => {
    //     if (props?.defaultValue?.length > 0 && props?.handleSelectChange) {
    //         props.handleSelectChange(props.defaultValue, props.name)
    //     }
    // }, [])

    useEffect(() => {
        if (!_.isEqual(props.defaultValue, selectedOptions)) {
            setSelectedOptions(props.defaultValue)
            // props.handleSelectChange(props.defaultValue, props.name)
        }
    }, [props?.defaultValue?.length])

    /*useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }, [handleChange])*/

    let noOptionsMessageMui = "No " + (props.name === 'job_title' ? 'Job title' : props.name);
    if (props.allOptions && props.allOptions.length === 0) {
        noOptionsMessageMui = "Type to Load...";
    }

    let noOptionsMessage = () => "No " + props.name;
    if (props.allOptions && props.allOptions.length === 0) {
        noOptionsMessage = () => "Type to Load...";
    }

    const getOptions = () => {
        let options = [];

        if (isLoading || loading) {
            options.push({
                value: "Loading...", label: "Loading..."
            });
        }

        if (isSuccess) {
            options = [...(options ?? []), ...(data ?? [])];
        } else {
            if (!isLoading && props?.filterOptions) {
                options = props?.filterOptions
            }
        }
        let check = true
        options = options.map(option => {
            if (option.value.toLowerCase() === inputValue.toLowerCase()) {
                check = false
            }
            if (option.type) {
                return {value: option.value || option.name, label: option.label || option.name, type: option.type}
            } else {
                return {value: option.value || option.name, label: option.label || option.name}
            }
        })
        if (inputValue.length > 0 && check && (props.isCreatable || props.isCreatable === undefined)) {
            options.unshift({value: inputValue, label: inputValue})
        }

        return options;
    };


    if (props.mui) {
        return (<Autocomplete
            open={props.open}
            multiple={isMultiple}
            options={getOptions()}
            autoSelect={props.autoSelect === true}
            disableCloseOnSelect={false}
            limitTags={1}
            value={props.defaultValue}
            name={props.name}
            loading={status === 'loading'}
            placeholder={props.placeholder}
            onChange={handleChangeMui}
            onInputChange={onInputChangeMui}
            getOptionLabel={option => option.value ?? ''}
            disabled={isDisabled}
            className="rp-select-container"
            classes={props.classes}
            onClose={() => setInputValue("")}
            noOptionsText={noOptionsMessageMui}
            isOptionEqualToValue={(o, v) => {
                return (o.value ?? '') === (v.value ?? '')
            }}
            renderOption={(props, option, state) => {
                return <li {...props} dangerouslySetInnerHTML={{__html: option.label ?? ''}}></li>;
                /*return <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={state.selected}
                    />
                    <span dangerouslySetInnerHTML={{__html: option.label ?? ''}}></span>
                </li>*/
            }}
            renderInput={(params) => <TextField
                sx={props.sx}
                {...params}
                label={props.label ?? props.name}
                required={false}
                variant={props.variant}
                placeholder={props.placeholder}
                InputProps={{
                    ...params.InputProps, endAdornment: (<React.Fragment>
                        {status === 'loading' ? <CircularProgress color="inherit" size={20}/> : null}
                        {params.InputProps.endAdornment}
                    </React.Fragment>),
                }}/>}
        />)
    } else {
        return <Select
            menuIsOpen={props.menuIsOpen}
            isMulti={isMultiple}
            styles={props.styles}
            options={getOptions()}
            value={selectedOptions}
            inputValue={inputValue}
            name={props.name}
            classNamePrefix="rp-select"
            placeholder={props.placeholder}
            onChange={handleChange}
            isClearable={true}
            noOptionsMessage={noOptionsMessage}
            onInputChange={onInputChange}
            onKeyDown={props.onKeyDown}
            isDisabled={isDisabled}
            isLoading={isLoading || loading}
            filterOption={() => true}
            className="rp-select-container"
            formatOptionLabel={function (data) {
                return (<span className="capitalize_first" dangerouslySetInnerHTML={{__html: data.label}}
                              title={data?.label?.replace(/(<([^>]+)>)/ig, '') ?? ''}/>)
            }}
        />
        // return <Select
        //     menuIsOpen={props.menuIsOpen}
        //     isMulti={isMultiple}
        //     styles={props.styles}
        //     options={data}
        //     value={props.defaultValue}
        //     name={props.name}
        //     onKeyDown={props.onKeyDown}
        //     // isLoading={status === 'loading'}
        //     classNamePrefix="rp-select"
        //     placeholder={props.placeholder}
        //     onChange={handleChange}
        //     isClearable={true}
        //     noOptionsMessage={noOptionsMessage}
        //     onInputChange={onInputChange}
        //     isDisabled={isDisabled}
        //     className="rp-select-container"
        // filterOption={() => true}
        // formatOptionLabel={function (data) {
        //     return (<span dangerouslySetInnerHTML={{__html: data.label}} title={data.label} />)
        // }}
        // />
    }
}

AsyncDropdown.propTypes = {
    isDisabled: PropTypes.bool,
    isMultiple: PropTypes.bool,
    isAsync: PropTypes.bool,
    setRelatedValues: PropTypes.func,
    name: PropTypes.string,
    allOptions: PropTypes.array,
    defaultValue: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    handleSelectChange: PropTypes.func,
    filterOptions: PropTypes.array,
}

export default AsyncDropdown
