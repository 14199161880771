import React, {useState} from 'react';
import useUpdateCandidatePlanNotificationAPI from "./candidates/partials/hooks/useUpdateCandidatePlanNotificationAPI";
import {useDispatch, useSelector} from "react-redux";
import {userConstants} from "../redux/constants";
import InputButton from "./partials/components/InputButton";
import useGATrackAPI from "./Project/hooks/useGATrackAPI";
import useUpdateUserDetails from "../hooks/useUpdateUserDetails";

export default function PlanExpiryModal() {
    const authUser = JSON.parse(localStorage.getItem('user'))
    const [btnClicked, setBtnClicked] = useState({
        notNowBtn: true, tellMeMoreBtn: false, showSuccessMsg: false
    })
    const [successMsg, setSuccessMsg] = useState('')
    const [checkBtnValue, setBtnValue] = useState(false);
    const updateCandidatePlanNotificationAPI = useUpdateCandidatePlanNotificationAPI()
    const [message, setMessage] = useState(authUser.plan_expire_reminder)
    const {getGATrackofDetails} = useGATrackAPI();
    const {updateUser} = useUpdateUserDetails()

    const handleCloseModal = () => {
        updateUser({...authUser, plan_expire_reminder: ''})
        if (localStorage.getItem('downloadProfile')) {
            const profileLink = localStorage.getItem('downloadProfile');
            localStorage.removeItem('downloadProfile')
            window.location.href = profileLink
        }
    }
    const handleUserResponse = (type) => {
        if (type === 1) setBtnValue(true)
        updateCandidatePlanNotificationAPI.mutate({
            event_id: type
        })
        if (type === 3) window.location.href = "mailto:contact@talentmug.ai";
    }

    if (updateCandidatePlanNotificationAPI.isSuccess) {
        const payload = updateCandidatePlanNotificationAPI.variables

        if (payload?.event_id === 1) {
            setMessage(updateCandidatePlanNotificationAPI?.data?.message)
            setBtnClicked(prevState => ({...prevState, notNowBtn: false, tellMeMoreBtn: true}))
        } else if (payload?.event_id === 2 || payload?.event_id === 3) {
            if (payload?.event_id === 2 && updateCandidatePlanNotificationAPI?.data?.message) {
                setSuccessMsg(updateCandidatePlanNotificationAPI?.data?.message)
                setBtnClicked(prevState => ({
                    ...prevState, notNowBtn: false, tellMeMoreBtn: false, showSuccessMsg: true
                }))
            }

            if (payload?.event_id === 3) {
                handleCloseModal();
            }
        }
        updateCandidatePlanNotificationAPI.reset()
    }

    if (updateCandidatePlanNotificationAPI.isError) {
        handleCloseModal();
        updateCandidatePlanNotificationAPI.reset();
    }

    return (<div className="modal modal_style active plr_15">
        <div className="modal_expiry modal_content width-full p-0">
            <div className="height_100vh me_column_pd">
                <div className="modal_title">
                    {authUser?.credits_left === 0 && !btnClicked.showSuccessMsg &&
                        <h1> Insufficient Credits!</h1>}
                    <button className="close" onClick={() => {
                        getGATrackofDetails("subscription modal closed")
                        handleCloseModal()
                    }}>
                        <svg width="12" height="12" viewBox="0 0 8 8" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 7L1 1M7 1L1 7" stroke="black" strokeOpacity="0.8" strokeWidth="2"
                                  strokeLinecap="round"/>
                        </svg>
                    </button>
                </div>
                <div className="modal_text">
                    {btnClicked?.showSuccessMsg ? successMsg :
                        <div dangerouslySetInnerHTML={{__html: message}}></div>
                    }
                </div>
                <div className={`modal_btns ${btnClicked.showSuccessMsg && 'remove_grid'}`}>
                    {btnClicked.notNowBtn && <>
                        <InputButton
                            className="btn btn_outline"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                                getGATrackofDetails("subscription not now")
                                handleUserResponse(1)
                            }}
                            isLoading={checkBtnValue && updateCandidatePlanNotificationAPI.isLoading}
                            disabled={updateCandidatePlanNotificationAPI.isLoading}
                        >Not now
                        </InputButton>

                        <InputButton
                            className="btn btn_fill"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                                getGATrackofDetails("subscription tell me more")
                                handleUserResponse(2)
                            }}
                            isLoading={!checkBtnValue && updateCandidatePlanNotificationAPI.isLoading}
                            disabled={updateCandidatePlanNotificationAPI.isLoading}
                        >Tell me more
                        </InputButton>


                    </>}
                    {btnClicked.tellMeMoreBtn && <>

                        <InputButton
                            className="btn btn_outline"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                                getGATrackofDetails("subscription got it")
                                handleCloseModal()
                            }}
                            disabled={updateCandidatePlanNotificationAPI.isLoading}
                        >Got it
                        </InputButton>

                        <InputButton
                            className="btn btn_fill"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                                handleUserResponse(3)
                                getGATrackofDetails("subscription i have an issue")
                            }}
                            isLoading={updateCandidatePlanNotificationAPI.isLoading}
                        >I have an issue
                        </InputButton>

                    </>}
                    {btnClicked.showSuccessMsg &&
                        <InputButton
                            className="btn btn_outline"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={() => {
                                getGATrackofDetails("subscription modal closed")
                                handleCloseModal()
                            }}
                        >Close
                        </InputButton>
                    }
                </div>
            </div>
        </div>
    </div>);
};
