import {userConstants} from '../constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? {cancel: false, loggedIn: true, user} : {cancel: false, loggedIn: false};

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                cancel: false,
                loggingIn: true,
                user: action.user
            };
        case userConstants.REFRESH_TOKEN:
            return {...state, cancel: true};
        case userConstants.LOGIN_SUCCESS:
            return {
                cancel: false,
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_UPDATE:
            return {
                cancel: false,
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {
                cancel: false,
                loggedIn: false
            };
        case userConstants.LOGOUT:
            return {
                cancel: false,
                loggedIn: false
            };
        default:
            return state
    }
}