import React from "react";
import {Skeleton} from "@mui/material";
import {useNavigate} from "react-router-dom";

export default function ProjectsHeaderModal({project}) {
    const navigate = useNavigate()
    const formatDate = (dateString) => {
        const options = {year: "numeric", month: "long", day: "numeric"}
        return new Date(dateString).toLocaleDateString(undefined, options)
    }
    const tempData = [];

    if (project.isLoading) {
        for (let i = 0; i < 5; i++) {
            tempData.push(
                    <li key={i}>
                        <Skeleton variant="text" sx={{width: '335px', height: '50px'}}/>
                    </li>
                )
        }
    } else if (project.isSuccess) {
        tempData.push(<div key="projectModal">
                {project?.data?.projects?.slice(0, 5).map((item, key) => (<li key={key}>
                    <a className="rp-link" onClick={() => navigate(`${process.env.REACT_APP_BASENAME}/project/${item.uuid}/pipeline`)}>
                        <h6>{item.name}</h6>
                        <span>
                                <span>{item.hiring_for_company}</span>
                                <span className="dot black"></span>
                                <em>{formatDate(item.created_at)}</em>
                            </span>
                    </a>
                </li>))}
                {project?.data?.projects.length === 0 ?
                    (<h6>There are no projects</h6>) :
                    (<div className="show_all_col" key="showAll" onClick={() => navigate(`${process.env.REACT_APP_BASENAME}/projects`)}>
                        <a className="more_less">Show all ({project?.data?.totalRecords})</a>
                    </div>)
                }
        </div>)
    }

    return (<div className={project?.data?.projects.length === 0 ? 'dropdown_rel nothing_found' : 'dropdown_rel'} >
        <div className="dropdown_style">
            <div className="project_title">
                <div>
                    <h6>Recent Projects</h6>
                </div>
                <div className="text-end">
                    <a onClick={() => navigate(`${process.env.REACT_APP_BASENAME}/project/add`, {state: {resetData: true}})} href="#" className="btn_transparent rp-link">
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 1.07227V9.07227M9 5.07227H1" stroke="#D8232A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Create New
                    </a>
                </div>
            </div>
            <ul>
                {tempData}
            </ul>
        </div>
    </div>)
}