import CandidateSearch from "./CandidateSearch";
import {useParams} from "react-router";
import useCandidateDetailsAPI from "./partials/hooks/useCandidateDetailsAPI";
import {Candidate} from "../../helpers";
import moment from "moment";

export default function SimilarCandidates() {

    const {candidate_id} = useParams()
    const candidateCheck = !!candidate_id;

    const similarCandidateAPI = useCandidateDetailsAPI({uuid: candidate_id}, candidateCheck);

    if (similarCandidateAPI.isSuccess && similarCandidateAPI.data?.data) {

        const candidate = new Candidate(similarCandidateAPI.data?.data)
        const filterHavingValue = Object.keys(candidate?.similarFilters).filter(key => (Array.isArray(candidate?.similarFilters[key]) && candidate?.similarFilters[key].length))

        return <CandidateSearch
            similar={true}
            filters={candidate?.similarFilters}
            haveFilter={filterHavingValue.length > 0 ? true : false}
            similarCandidate={new Candidate(similarCandidateAPI.data?.data)}
        />;
    }
}