export class Gtm {
    static initialize() {

        if (!window.dataLayer) {
            window.dataLayer = [];
        }

        const gtmScript = document.createElement('script');
        gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_ID}`;
        gtmScript.async = true;

        gtmScript.onload = () => {
            Gtm.gtag('js', new Date());
            Gtm.gtag('config', `${process.env.REACT_APP_GTM_ID}`);
        };

        document.head.appendChild(gtmScript);
    }

    static gtag() {
        if (process.env.REACT_APP_GA_ENABLED === 'true') {
            window.dataLayer.push(arguments);
        }
    }
}