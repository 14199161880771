import React, {useState} from "react";

export const ReadMore = ({text, size = 200}) => {

    const [isReadMore, setIsReadMore] = useState(true);

    if (!text) return
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    let formattedText = isReadMore ? text.slice(0, size) : text
    return (<>
        <p dangerouslySetInnerHTML={{__html: formattedText}}/>
        {text.length > size && <button className="show_more_btn mt-0"
                                       onClick={() => toggleReadMore()}>{!isReadMore ? 'Show less' : 'Show more'}</button>}
    </>);

};