import React, {useState} from "react";
import {ObjectView} from 'react-object-view'

function CustomModal(props) {
    const [state, setState] = useState(true)
    const jsonData = props.data.jsonData
    if (jsonData.recruitplus && jsonData.recruitplus.skills_logic && typeof jsonData.recruitplus.skills_logic === "string") {
        jsonData.recruitplus.skills_logic = JSON.parse(`${jsonData.recruitplus.skills_logic}`)
    }
    if (jsonData.recruitplus && jsonData.recruitplus.growth_trajectory_debug && typeof jsonData.recruitplus.growth_trajectory_debug === "string") {
        jsonData.recruitplus.growth_trajectory_debug = JSON.parse(`${jsonData.recruitplus.growth_trajectory_debug}`)
    }
    if (jsonData.recruitplus && jsonData.recruitplus.availability_logic && typeof jsonData.recruitplus.availability_logic === "string") {
        jsonData.recruitplus.availability_logic = JSON.parse(`${jsonData.recruitplus.availability_logic}`)
    }

    const sortValues = () => {
        let score = 0;
        const scoreDebug = [];
        if (!Array.isArray(jsonData)) {
            const filters = new Map(Object.entries(props.filters));
            filters.forEach((filter, key) => {
                if (filter.length > 0) {
                    if (key === 'seniority') {
                        jsonData.positions.forEach(position => {
                            if (position.seniority) {
                                filter.forEach(f => {
                                    if (position.seniority.toLowerCase() === f.value.toLowerCase()) {
                                        scoreDebug.push({score: 20, key: 'seniority', value: position.seniority})
                                        score += 20
                                    }
                                })
                            }
                        })
                    } else if (key === 'jobTitle') {
                        jsonData.positions.forEach(position => {
                            if (position.title) {
                                filter.forEach(f => {
                                    if (position.title.toLowerCase() === f.value.toLowerCase()) {
                                        scoreDebug.push({score: 10, key: 'jobTitle', value: position.title})
                                        if (filters.get('similar') === true) {
                                            scoreDebug.push({
                                                score: 2 * 3,
                                                key: 'jobTitle-similar',
                                                value: position.title
                                            })
                                            score += 2 * 3
                                        }
                                        score += 10
                                    }
                                })
                            }
                        })
                        /*scoreDebug.push({score: 10, key: 'jobTitle', value: filter})
                        score += 10*/
                    } else if (key === 'seniority') {
                        scoreDebug.push({score: 20, key: 'seniority', value: filter})
                        score += 20
                    } else if (key === 'keyword') {
                        filter.forEach(f => {
                            if (jsonData.basic && jsonData.basic.highlight) {
                                if (jsonData.basic.highlight.toLowerCase().includes(f.value)) {
                                    scoreDebug.push({score: 30, key: 'keyword', value: f.value})
                                    score += 30
                                }
                            }
                            if (jsonData.basic && jsonData.basic.currentPosition && jsonData.basic.currentPosition.position_summary) {
                                if (jsonData.basic.currentPosition.position_summary.toLowerCase().includes(f.value)) {
                                    scoreDebug.push({score: 15, key: 'keyword', value: f.value})
                                    score += 15
                                }
                            }
                            if (jsonData.recruitplus.calculated_skills) {
                                jsonData.recruitplus.calculated_skills.forEach(skill => {
                                    if (skill.name && f.value.toLowerCase() === skill.name.toLowerCase()) {
                                        scoreDebug.push({score: 5, key: 'keyword', value: f.value})
                                        score += 5
                                    }
                                })
                            }
                        })
                    } else if (key === 'companySize') {
                        scoreDebug.push({score: 10, key: 'companySize', value: filter})
                        score += 10
                    } else if (key === 'experience') {
                        scoreDebug.push({score: 10, key: 'experience', value: filter})
                        if (filters.get('similar') === true) {
                            scoreDebug.push({score: 1 * 3, key: 'experience-similar', value: filter})
                            score += 1 * 3
                        }
                        score += 10
                    } else if (key === 'skill') {
                        jsonData.recruitplus.calculated_skills.forEach(skill => {
                            filter.forEach(f => {
                                if (f.value.toLowerCase() === skill.name.toLowerCase()) {
                                    // if (f.value.toLowerCase().includes(skill.name.toLowerCase())) {
                                    scoreDebug.push({
                                        score: skill.weight * 5,
                                        score_debug: `${skill.weight}*5`,
                                        key: 'skill',
                                        value: skill.name
                                    })
                                    if (filters.get('similar') === true && skill.weight > 2) {
                                        scoreDebug.push({
                                            score: skill.weight * 3,
                                            key: 'skill-similar',
                                            value: skill.name
                                        })
                                        score += skill.weight * 3
                                    }
                                    score += +skill.weight * 5
                                }
                            })
                        })
                    } else if (key === 'industry') {
                        jsonData.positions.forEach(position => {
                            if (position.industries) {
                                position.industries.forEach(industry => {
                                    filter.forEach(f => {
                                        if (industry.name.toLowerCase().includes(f.value.toLowerCase())) {
                                            scoreDebug.push({
                                                score: industry.score * 5,
                                                score_debug: `${industry.score}*5`,
                                                key: 'industry',
                                                value: industry.name
                                            })
                                            if (filters.get('similar') === true && industry.score > 1) {
                                                scoreDebug.push({
                                                    score: industry.score * 3,
                                                    key: 'industry-similar',
                                                    value: industry.name
                                                })
                                                score += industry.score * 3
                                            }
                                            score += +industry.score * 5
                                        }
                                    })
                                })
                            }
                        })
                    }
                }
            })
            scoreDebug.push({score: jsonData.sort.total + 11, key: 'static_sort_score', value: 'score'})
            scoreDebug.push({score: jsonData.extra_score, key: 'extra_score', value: 'extra_score'})
            jsonData.dynamic_score = {total: score + jsonData.sort.total + 11 + jsonData.extra_score, debug: scoreDebug}
        }
        // console.log('score', scoreDebug, score, jsonData.sort.total+11, jsonData.extra_score);
    }
    // sortValues();
    let style = {width: '50%', visibility: props.data.showModal ? 'visible' : 'hidden'}
    if (state) {
        style = {width: '50%', background: "#222222", visibility: props.data.showModal ? 'visible' : 'hidden'}
    }
    return (
        <>
            <div style={style}
                 className={props.data.showModal ? "offcanvas offcanvas-end show" : "offcanvas offcanvas-end"}
                 aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <h5 id="offcanvasRightLabel">
                        <button style={{float: 'right'}} className="btn btn-primary"
                                onClick={() => setState(!state)}>{state ? 'Raw' : 'Pretty'}</button>
                    </h5>
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas"
                            aria-label="Close" onClick={props.closeJsonPopup}></button>
                </div>
                <div className="offcanvas-body">
                    {state ? <ObjectView data={jsonData}/> : <div>{JSON.stringify(jsonData)}</div>}
                </div>
            </div>
            {props.data.showModal ?
                <div className="modal-backdrop fade show" onClick={props.closeJsonPopup}></div> : <></>}
        </>
    );
}

export default CustomModal
