import {Autocomplete, Card, CardActionArea, CardContent, InputAdornment, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import useGetSearchList from "../hooks/useGetSearchList";
import {useLocation, useNavigate} from "react-router-dom";
import SimilarFinder from "../../similarFinder";
import useGetHeaderSearches from "../../candidates/partials/hooks/useGetHeaderSearches";
import useDebounce from "../../../hooks/useDebounce";
import userDefaultImage from "../../../assets/img/default.png";
import loader from "../../../assets/img/loader.gif";

export default function HeaderSearch() {
    const navigate = useNavigate();
    const location = useLocation();

    let fixedOptions = [{
        label: "New Candidate Search",
        img: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.4351 10.0629H10.7124L10.4563 9.81589C11.3528 8.77301 11.8925 7.4191 11.8925 5.94625C11.8925 2.66209 9.23042 0 5.94625 0C2.66209 0 0 2.66209 0 5.94625C0 9.23042 2.66209 11.8925 5.94625 11.8925C7.4191 11.8925 8.77301 11.3528 9.81589 10.4563L10.0629 10.7124V11.4351L14.6369 16L16 14.6369L11.4351 10.0629ZM5.94625 10.0629C3.66838 10.0629 1.82962 8.22413 1.82962 5.94625C1.82962 3.66838 3.66838 1.82962 5.94625 1.82962C8.22413 1.82962 10.0629 3.66838 10.0629 5.94625C10.0629 8.22413 8.22413 10.0629 5.94625 10.0629Z"
                fill="#65676B"/>
        </svg>,
        type: "Recruiter search",
        index: 1,
        url: `${process.env.REACT_APP_BASENAME}/candidate-search`
    }, {
        label: "Search by Ideal Candidate",
        img: <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.74708 8.19101C7.03595 8.19101 6.3408 7.98014 5.74952 7.58506C5.15824 7.18998 4.6974 6.62844 4.42526 5.97145C4.15313 5.31445 4.08192 4.59152 4.22066 3.89406C4.35939 3.1966 4.70183 2.55594 5.20467 2.0531C5.70751 1.55026 6.34817 1.20782 7.04563 1.06909C7.74309 0.930355 8.46602 1.00156 9.12302 1.27369C9.78001 1.54583 10.3415 2.00667 10.7366 2.59795C11.1317 3.18923 11.3426 3.88438 11.3426 4.59551C11.3426 5.54909 10.9638 6.46363 10.2895 7.13791C9.61519 7.8122 8.70066 8.19101 7.74708 8.19101ZM7.74708 2.06838C7.23913 2.06838 6.74259 2.219 6.32025 2.5012C5.89791 2.7834 5.56873 3.1845 5.37435 3.65378C5.17997 4.12306 5.12911 4.63945 5.22821 5.13763C5.3273 5.63582 5.5719 6.09343 5.93107 6.4526C6.29024 6.81177 6.74786 7.05637 7.24604 7.15547C7.74423 7.25456 8.26061 7.2037 8.72989 7.00932C9.19917 6.81494 9.60027 6.48577 9.88247 6.06342C10.1647 5.64108 10.3153 5.14454 10.3153 4.6366C10.3153 4.29934 10.2489 3.96537 10.1198 3.65378C9.99074 3.34219 9.80156 3.05908 9.56308 2.82059C9.3246 2.58211 9.04148 2.39294 8.72989 2.26387C8.4183 2.13481 8.08434 2.06838 7.74708 2.06838ZM10.6081 8.81252C7.82845 8.18705 4.92051 8.4883 2.32814 9.67031C1.97161 9.84061 1.67081 10.1087 1.46075 10.4433C1.25068 10.778 1.14001 11.1654 1.14162 11.5605V14.6167C1.14162 14.6841 1.1549 14.7509 1.18072 14.8133C1.20653 14.8756 1.24437 14.9322 1.29206 14.9799C1.33976 15.0276 1.39638 15.0654 1.4587 15.0912C1.52102 15.1171 1.58781 15.1303 1.65526 15.1303C1.72272 15.1303 1.78951 15.1171 1.85183 15.0912C1.91414 15.0654 1.97077 15.0276 2.01846 14.9799C2.06616 14.9322 2.10399 14.8756 2.12981 14.8133C2.15562 14.7509 2.16891 14.6841 2.16891 14.6167V11.5605C2.16443 11.3606 2.21843 11.1636 2.32427 10.9939C2.43011 10.8242 2.5832 10.6891 2.76473 10.6051C4.32635 9.88409 6.02705 9.51414 7.74708 9.52135C8.71083 9.52012 9.67129 9.63393 10.6081 9.86035V8.81252ZM10.68 13.6973H13.8338V14.4164H10.68V13.6973Z"
                fill="#65676B" stroke="#65676B" strokeWidth="0.3"/>
            <path
                d="M16.3456 10.6466H13.6901V11.6739H15.832V15.9731H8.55365V11.6739H11.7896V11.8896C11.7896 12.0259 11.8437 12.1565 11.9401 12.2528C12.0364 12.3492 12.167 12.4033 12.3033 12.4033C12.4395 12.4033 12.5701 12.3492 12.6665 12.2528C12.7628 12.1565 12.8169 12.0259 12.8169 11.8896V9.89157C12.8169 9.75535 12.7628 9.6247 12.6665 9.52837C12.5701 9.43205 12.4395 9.37793 12.3033 9.37793C12.167 9.37793 12.0364 9.43205 11.9401 9.52837C11.8437 9.6247 11.7896 9.75535 11.7896 9.89157V10.6466H8.04001C7.90378 10.6466 7.77314 10.7007 7.67681 10.7971C7.58048 10.8934 7.52637 11.024 7.52637 11.1603V16.4868C7.52637 16.623 7.58048 16.7536 7.67681 16.85C7.77314 16.9463 7.90378 17.0004 8.04001 17.0004H16.3456C16.4819 17.0004 16.6125 16.9463 16.7088 16.85C16.8052 16.7536 16.8593 16.623 16.8593 16.4868V11.1603C16.8593 11.024 16.8052 10.8934 16.7088 10.7971C16.6125 10.7007 16.4819 10.6466 16.3456 10.6466Z"
                fill="#65676B" stroke="#65676B" strokeWidth="0.3"/>
        </svg>,
        type: "Recruiter search",
        index: 2
    }, {
        label: "Search History",
        img: <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.974 0.999195C6.45047 0.874775 2.74453 4.50962 2.74453 8.99763H1.15373C0.753807 8.99763 0.55829 9.47754 0.842679 9.75304L3.32219 12.2414C3.49994 12.4192 3.77544 12.4192 3.95318 12.2414L6.43269 9.75304C6.49411 9.69039 6.5356 9.61096 6.55195 9.52476C6.56829 9.43857 6.55875 9.34947 6.52454 9.26868C6.49032 9.1879 6.43296 9.11905 6.35968 9.07081C6.2864 9.02258 6.20049 8.99711 6.11276 8.99763H4.52196C4.52196 5.53164 7.34807 2.73219 10.8318 2.77663C14.1379 2.82106 16.9195 5.60274 16.964 8.90876C17.0084 12.3836 14.2089 15.2186 10.743 15.2186C9.31213 15.2186 7.98795 14.7298 6.93926 13.9033C6.76904 13.7692 6.55547 13.7024 6.33917 13.7155C6.12287 13.7286 5.91894 13.8208 5.76616 13.9744C5.3929 14.3477 5.41956 14.9787 5.83726 15.2986C7.23354 16.4028 8.96284 17.0012 10.743 16.9961C15.231 16.9961 18.8658 13.2901 18.7414 8.76656C18.6259 4.59849 15.1421 1.11473 10.974 0.999195ZM10.5208 5.44277C10.1564 5.44277 9.85425 5.74493 9.85425 6.10931V9.37978C9.85425 9.69083 10.0231 9.9841 10.2897 10.1441L13.0625 11.7882C13.3824 11.9748 13.7913 11.8682 13.9779 11.5571C14.1645 11.2372 14.0579 10.8284 13.7468 10.6418L11.1873 9.12205V6.10042C11.1873 5.74493 10.8852 5.44277 10.5208 5.44277Z"
                fill="#65676B"/>
        </svg>,
        type: "Recruiter search",
        index: 3,
        url: `${process.env.REACT_APP_BASENAME}/search/history`
    }, {
        label: "Saved searches",
        img: <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.875 2.75003C0.875 2.28589 1.0901 1.84077 1.47299 1.51257C1.85588 1.18438 2.37518 1 2.91667 1H11.0833C11.6248 1 12.1441 1.18438 12.527 1.51257C12.9099 1.84077 13.125 2.28589 13.125 2.75003V14.5627C13.125 14.6419 13.0999 14.7195 13.0524 14.7874C13.0049 14.8553 12.9369 14.9108 12.8555 14.9482C12.7741 14.9856 12.6824 15.0033 12.5902 14.9995C12.498 14.9957 12.4087 14.9706 12.3318 14.9267L7 11.2898L1.66819 14.9267C1.59132 14.9706 1.50203 14.9957 1.40981 14.9995C1.31758 15.0033 1.22589 14.9856 1.1445 14.9482C1.0631 14.9108 0.995054 14.8553 0.947596 14.7874C0.900137 14.7195 0.875048 14.6419 0.875 14.5627V2.75003ZM2.91667 1.87501C2.64592 1.87501 2.38627 1.9672 2.19483 2.1313C2.00338 2.2954 1.89583 2.51796 1.89583 2.75003V13.7455L6.22063 10.7689C6.3044 10.7212 6.89938 10.2774 7 10.2774C7.10062 10.2774 7.80448 10.7212 7.88825 10.7689L12.1042 13.7455V2.75003C12.1042 2.51796 11.9966 2.2954 11.8052 2.1313C11.6137 1.9672 11.3541 1.87501 11.0833 1.87501H2.91667Z"
                fill="#65676B" stroke="#65676B" strokeWidth="0.5"/>
        </svg>,
        url: `${process.env.REACT_APP_BASENAME}/search/saved`,
        type: "Recruiter search",
        index: 4
    }]

    const initialState = {
        enabled: false, options: fixedOptions, searchOpen: false, showComponent: '', inputValue: '',
    }

    const [state, setState] = useState(initialState)

    const searchList = useGetSearchList(null, state.enabled)
    const debounceParams = useDebounce(state.inputValue, 200)
    const searchSuggestions = useGetHeaderSearches(debounceParams, !!debounceParams)

    const handleCloseSimilarFinder = () => {
        setState(prevState => ({...prevState, showComponent: ''}))
    }

    const searchListOptions = data => {
        return data.map((item) => ({
            label: item.search_name,
            value: item.search_data,
            type: 'savedSearch',
            id: item.id,
            project_uuid: item.project_uuid,
            icon: <ion-icon name="trash-outline"></ion-icon>
        }))
    }

    if (searchList.status === 'success') {
        const arr = searchListOptions(searchList.data)
        setState(prevState => ({...prevState, enabled: false, options: (arr)}))
        searchList.remove();
    }

    useEffect(() => {
        if (state.showComponent === '') {
            setState(initialState)
        }
    }, [state.showComponent])

    const handleInputChange = (event, value) => {
        const currentPageOpen = Object.values([...fixedOptions, ...state.options]).every(row => {
            if ((row?.url === location.pathname || !row.url) && row.label === value) {
                return false;
            }
            return true;
        })

        if (value === 'Search by Ideal Candidate') {
            return;
        }

        if (currentPageOpen && value !== 'undefined') {
            setState(prevState => ({...prevState, inputValue: (value)}))
        }
    }

    let loadingOption = [];
    if (searchSuggestions.isLoading) {
        loadingOption.push({label: "loading", img: '', url: '', type: '', index: 6})
    } else {
        loadingOption = loadingOption.filter((option) => option.index !== 6)
    }

    useEffect(() => {
        if (searchSuggestions.isSuccess) {
            const {ideal_candidates, job_titles, saved_searches, user_projects} = searchSuggestions.data;
            const searchedOptions = []

            if (ideal_candidates.length > 0) {
                ideal_candidates.forEach((idealCandidate, i) => searchedOptions.push({
                    label: idealCandidate.basic.fullname,
                    img: idealCandidate.basic.image_url,
                    location: idealCandidate.basic.location, ...idealCandidate,
                    type: 'Candidate matches',
                    uuid: idealCandidate.recruitplus.uuid,
                    index: i
                }))
            }

            if (job_titles.length > 0) {
                job_titles.forEach(({name}, i) => searchedOptions.push({
                    label: name, value: name, type: 'Job title matches', index: i
                }))
                searchedOptions.push({
                    label: `Search all people who matches "${state.inputValue}"`,
                    value: state.inputValue,
                    type: 'Job title matches',
                    index: job_titles.length + 1
                })
            }

            if (saved_searches.length > 0) {
                saved_searches.forEach(({search_name, uuid, project_uuid, search_data}, i) => searchedOptions.push({
                    label: search_name, type: 'Saved searches', data: {uuid, project_uuid, search_data}, index: i
                }))
            }

            if (user_projects.length > 0) {
                user_projects.forEach(({name, uuid}, i) => searchedOptions.push({
                    label: name, type: 'Projects', uuid, index: i
                }))

                searchedOptions.push({
                    label: `Search in all projects`, value: 'projects', type: 'Projects', index: job_titles.length + 1
                })
            }

            setState(prevState => ({...prevState, options: [...searchedOptions]}))
        }

        if (searchSuggestions.isIdle && !state.inputValue && state.options.length > 4) {
            setState(prevState => ({...prevState, options: fixedOptions}))
        }

    }, [searchSuggestions.status, searchSuggestions.data])


    const handleValueChange = (event, value, reason) => {

        setState(prevState => ({...prevState, showComponent: ''}))
        if (reason === 'clear') {
            setState(prevState => ({...prevState, options: (fixedOptions), searchOpen: true}))
        } else {
            setState(prevState => ({...prevState, inputValue: ''}))
            switch (value?.type) {
                case 'Recruiter search':
                    if (value?.url) {
                        navigate(value.url)
                    } else {
                        setState(prevState => ({...prevState, showComponent: 'similar'}))
                    }
                    break;
                case 'Job title matches':
                    navigate(`${process.env.REACT_APP_BASENAME}/candidate-search?jobTitle=${value?.value}`, {
                        state: {
                            jobTitle: value?.value
                        }
                    })

                    break;
                case 'Saved searches':
                    if (value?.data?.project_uuid) {
                        navigate(`${process.env.REACT_APP_BASENAME}/project/${value?.data?.project_uuid}/search`, {
                            state: {
                                searchData: value.data.search_data, id: value.data.uuid, name: value.label
                            }
                        })
                    } else {
                        navigate(`${process.env.REACT_APP_BASENAME}/candidate-search`, {
                            state: {
                                searchData: value.data.search_data, id: value.data.uuid, name: value.label
                            }
                        })
                    }
                    break;
                case 'Projects':
                    if (value.uuid) {
                        navigate(`${process.env.REACT_APP_BASENAME}/project/${value.uuid}/pipeline`)
                    } else {
                        navigate(`${process.env.REACT_APP_BASENAME}/projects`, {
                            state: {
                                searchInProject: state.inputValue
                            }
                        })
                    }
                    break;
                case 'Candidate matches':
                    navigate(`${process.env.REACT_APP_BASENAME}/profile/${value.uuid}`)
                    break;
                default:
                    navigate(`${process.env.REACT_APP_BASENAME}/candidate-search?keyword=${state.inputValue}`, {
                        state: {
                            keyword: state.inputValue
                        }
                    })
            }


            // switch (value?.label) {
            //     case 'Saved searches':
            //         navigate('/search/saved')
            //         // setState(prevState => ({...prevState, enabled: true, searchOpen: true}))
            //         break;
            //
            //     case 'Start new Candidate Search':
            //         console.log("inside")
            //
            //         navigate('/candidate-search', {
            //             state: {resetFilter: true}
            //         })
            //         break;
            //
            //     case 'Search by Ideal Candidate':
            //         setState(prevState => ({...prevState, showComponent: 'similar'}))
            //         break;
            //
            //     case 'Advance filters':
            //         break;
            //
            //     case 'Search History':
            //         navigate('/search/history')
            //         break;
            // }
        }
    }

    const similarProfile = ({option, props}) => {
        return (<Card  {...props} key={`${option.type}-${option.index}`}>
            <CardContent className="similar_finder_dd">
                <div className="rp-img">
                    <div
                        className={option.recruitplus.cm_member !== '' ? 'user_profl' : 'user_profl'}>
                        <img
                            src={option.basic.image_url ?? userDefaultImage}
                            onError={({currentTarget}) => currentTarget.src = userDefaultImage}
                            alt=""/>
                    </div>
                    <div>
                        <Typography variant="h6" component="h6">
                            {option?.basic?.fullname}
                        </Typography>
                        <Typography variant="div" component="div">
                            {option?.current_position?.title && <>
                                <span className="rp_text1">{option?.current_position?.title}</span>
                                <span className="rp_text2">@</span>
                            </>}
                            {option?.current_position?.company &&
                                <span className="rp_text1">{option?.current_position?.company}</span>}
                        </Typography>
                        <Typography variant="div" component="div">
                            <h5>{option?.basic?.location}</h5>
                        </Typography>
                    </div>
                </div>
            </CardContent>
        </Card>);
    }

    return (<>
        <div className="search_bar">
        <Autocomplete
            disablePortal
            componentsProps={{
                popper: {
                    style: {
                        width: '100%'
                    }
                }
            }}
            freeSolo
            openOnFocus={true}
            /*open={state.searchOpen}
            onOpen={() => {
                setState(prevState => ({...prevState, searchOpen: true, showComponent: ''}))
            }}
            onClose={(event) => {
                setState(prevState => ({...prevState, inputValue: ''}))
                if (event.target.innerText !== 'Saved searches') {
                    setState(prevState => ({...prevState, searchOpen: false}))
                }
            }}*/
            id="combo-box-demo"
            options={state.options}
            groupBy={(option) => option.type}
            sx={{height: 100, width: 285, padding: 0}}
            getOptionLabel={(option) => `${option?.label}`}
            onChange={handleValueChange}
            onInputChange={handleInputChange}
            filterOptions={(options, ref) => [...loadingOption, ...options.filter(({type}) => type != 'Recruiter search'), ...fixedOptions]}
            inputValue={state.inputValue}
            renderInput={(params) => <TextField
                placeholder="Search"
                {...params}
                // label="Search"
                size="small"
                InputProps={{
                    ...params.InputProps, endAdornment: (<>
                        {params.inputProps.value !== '' ? params.InputProps.endAdornment :
                            <InputAdornment position="end">
                                <a href="src/components/partials/header/Header#" style={{color: "black"}}>
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                                            fill="#CCCCCC"/>
                                    </svg>
                                </a>
                            </InputAdornment>}
                    </>)
                }}
            />}
            renderOption={(props, option, {selected}) => {
                const label = option.label === 'loading' ? <img src={loader}/> : option.label
                if (option.type === 'Candidate matches') {
                    return similarProfile({option, props})
                }
                return ((<Card variant="outlined search_hover" {...props}
                               key={`${option.type}-${option.index}`}
                               className={option.label === 'loading' ? 'loader_search' : ''}>
                    <CardActionArea>
                        <div style={{display: "flex", gap: 16}}>
                            {option.img && <Typography component="span" className="svg_size">
                                {option.img}
                            </Typography>}
                            <Typography component="span" className="search-text">
                                {label}
                            </Typography>
                            <Typography component="span">
                                {option.icon}
                            </Typography>
                        </div>
                    </CardActionArea>
                </Card>))
            }}
        />
        </div>
        <SimilarFinder tab={state.showComponent === 'similar'} closeSimilarFinder={handleCloseSimilarFinder}/>
    </>)
}