import {useQuery} from "react-query";
import {candidateService} from "../../../redux/services";

const searchList = async ({queryKey, signal}) => {
    const [_key, params] = queryKey
    const data = await candidateService.getSearchList(signal, params);
    return data.data;
};

export default function useGetSearchList(params, enabled) {
    return useQuery([params], searchList, {enabled: enabled, refetchOnWindowFocus: false});
}
