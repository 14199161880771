import {useSelector, useDispatch} from "react-redux";
import {userConstants} from "../redux/constants";

export default function useUpdateUserDetails() {
    const dispatch = useDispatch();
    const authUser = useSelector(state => state.authentication.user)

    function updateUser(user) {
        const updatedUser = {...authUser, ...user}
        localStorage.setItem('user', JSON.stringify(updatedUser));

        dispatch({type: userConstants.LOGIN_UPDATE, user: updatedUser})
    }

    return {updateUser};
}