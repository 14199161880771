import React, {Suspense, useEffect, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {useQueryClient} from "react-query";
import {ReactQueryDevtools} from "react-query/devtools";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import AlertSnackBar from "./components/partials/components/AlertSnackBar";
import {protectedRoutes, routes} from "./routes";
import {history} from "./helpers";
import {alertConstants} from "./redux/constants";
import {createTheme, ThemeProvider} from "@mui/material";
import useLoginAPI from "./components/partials/hooks/useLoginAPI";
import Layout from "./components/partials/Layout";
import Loader from "./components/partials/components/Loader";
import MultipleLoginHandleModal from "./components/MultipleLoginHandleModal";
import useLogoutAPI from "./components/partials/hooks/useLogoutAPI";
import useGetPendingDownloadedProfilesAPI from "./components/partials/hooks/useGetPendingDownloadedProfilesAPI";
import TermsModal from "./components/TermsModal";
import PlanExpiryModal from "./components/PlanExpiryModal";
import {Gtm} from "./helpers/gtm";
import {setCookie, setCookieExpirationDate} from "./helpers/cookie";

const basename = process.env.REACT_APP_BASENAME;

const firebaseConfig = {
    apiKey: "AIzaSyCLhk3UL_kHIR52vFU6HB5FTTIe1Hx4mgQ",
    authDomain: "recruitplus-8a3dc.firebaseapp.com",
    projectId: "recruitplus-8a3dc",
    storageBucket: "recruitplus-8a3dc.appspot.com",
    messagingSenderId: "1096091281431",
    appId: "1:1096091281431:web:fbd384e4483631ed1b1452",
    measurementId: "G-48CWR3SV5B",
    mimeType: 'application/javascript'
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const apiToken = localStorage.getItem('api_token')?.replace(/^"|"$/g, '');
if (apiToken) {
    setCookie('api_token', apiToken, setCookieExpirationDate());
}

if ('serviceWorker' in navigator) {
    let scope = process.env.REACT_APP_BASENAME + '/';
    if (process.env.REACT_APP_BASENAME === '/') {
        scope = '/'
    }
    navigator.serviceWorker.getRegistration().then(registration => {
        if (!registration) {
            navigator.serviceWorker.register(`${process.env.REACT_APP_BASENAME}/firebase-messaging-sw.js`, {scope: scope}).then(function (serviceWorkerRegistration) {
                console.log('Registration successful, scope is:', serviceWorkerRegistration.scope);
                getToken(messaging, {serviceWorkerRegistration}).then((currentToken) => {
                    if (currentToken) {
                        localStorage.setItem("fcmToken", currentToken);
                    }
                }).catch((err) => {
                    console.error('An error occurred while retrieving token. ', err);
                });
            }).catch(function (err) {
                console.error('Service worker registration failed, error:', err);
            });
        }
    });
}

const getLastPageUrl = () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const lastPageUrl = localStorage.getItem(`lastPage-${user?.id}`)

    if (lastPageUrl) localStorage.removeItem(`lastPage-${user?.id}`)

    return lastPageUrl ?? `${process.env.REACT_APP_BASENAME}/dashboard`
}

const ComponentWrapper = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user'))
    const [multipleLoginDetails, setMultipleLoginDetails] = useState({
        devices: '', loginCount: '', token: '', maxDevices: 0
    })
    const [showLoginErrorModal, setShowLoginErrorModal] = useState(false);
    const [intervelId, setIntervelId] = useState(null);
    const urlParams = new URLSearchParams(location.search);
    const locationHistoryParams = new URLSearchParams(history.location.search);
    let userToken = locationHistoryParams.get("token")
    if (urlParams.get("token")) localStorage.setItem('userToken', userToken)
    const loginWithToken = useLoginAPI(true)
    const logoutAPI = useLogoutAPI()
    const getPendingDownloadedProfilesAPI = useGetPendingDownloadedProfilesAPI(Boolean(+localStorage.getItem("isDownloadProfileBtnClicked")))
    const userFromRedux = useSelector(state => state.authentication.user);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (process.env.REACT_APP_GA_ENABLED === 'true') {
            Gtm.initialize()
        }
    }, [])

    useEffect(() => {
        const allPath = [
            {title: 'TalentMug | Candidate Search', pathName: `/candidate-search`},
            {title: 'TalentMug | Github Search', pathName: `/github-search`},
            {title: 'TalentMug | Target Companies', pathName: `/similar-company`},
            {title: 'TalentMug | Add Or Edit Project', pathName: `/project/add`},
            {title: 'TalentMug | Dashboard', pathName: `/dashboard`},
            {title: 'TalentMug | User Setting', pathName: `/settings`},
            {title: 'TalentMug | Project', pathName: `/projects`},
            {title: 'TalentMug | Project Candidates', pathName: `/project/`},
            {title: 'TalentMug | History', pathName: `/search/history`},
            {title: 'TalentMug | Saved Searches', pathName: `/similar-company-saved`},
            {title: 'TalentMug | Candidate Details', pathName: `/profile/`},
            {title: 'TalentMug | Login', pathName: `/auth/login`},
            {title: 'TalentMug | Kaggle Search', pathName: `/kaggle`},
            {title: 'TalentMug | Candidate Saved Search', pathName: `/search/saved`},
            {title: 'TalentMug | Candidate History', pathName: `/search/history`},
            {title: 'TalentMug | Target Company History', pathName: `/search/history/similar-company`},
            {title: 'TalentMug | Target Company Saved Search', pathName: `/search/similar-company-saved`},
            {title: 'TalentMug | Similar Candidates', pathName: `/similar-candidate/`},
            {title: 'TalentMug | Reset Password', pathName: `/auth/reset-password`},
        ]

        const matchedPath = allPath.find(path => location.pathname.startsWith(`${basename}${path.pathName}`));

        if (matchedPath) {
            document.title = matchedPath.title;
        } else {
            document.title = 'TalentMug | 404 NOT FOUND'
        }

        if (process.env.REACT_APP_GA_ENABLED === 'true') {
            window.dataLayer.push({
                event: 'pageview',
                page: window.location.pathname
            });
        }

    }, [location]);

    useEffect(() => {

        if (getPendingDownloadedProfilesAPI?.isSuccess && +localStorage.getItem("isDownloadProfileBtnClicked")) {
            const profiles = getPendingDownloadedProfilesAPI?.data?.downloads ?? []
            if (profiles.length) {
                const profileHavingUrl = profiles?.filter((profile) => profile?.profile_link) ?? []

                if (profileHavingUrl.length) {
                    profileHavingUrl.map((profile) => {
                        window.location.href = profile?.profile_link
                        // window.open(profile?.profile_link);
                    })
                }

                const id = setInterval(() => {
                    queryClient.refetchQueries("getPendingDownloadProfile")
                }, 5000);

                setIntervelId(id);
            }

            if (getPendingDownloadedProfilesAPI?.data?.message === 'No pending downloads found' && +localStorage.getItem("isDownloadProfileBtnClicked")) {
                clearInterval(intervelId);
                localStorage.setItem('isDownloadProfileBtnClicked', '0');
            }
        }

        if (getPendingDownloadedProfilesAPI?.isError && +localStorage.getItem("isDownloadProfileBtnClicked")) {
            clearInterval(intervelId);
            localStorage.setItem('isDownloadProfileBtnClicked', '0');
        }

    }, [getPendingDownloadedProfilesAPI?.data?.downloads, getPendingDownloadedProfilesAPI?.status])

    if (urlParams.get("email") && user?.email && urlParams.get("email") === user?.email) {
        locationHistoryParams.delete('token')
        locationHistoryParams.delete('email')
        navigate(`${history.location.pathname}?${locationHistoryParams.toString()}`)
    } else if (localStorage.getItem('userToken')) {

        if (urlParams.get("token") && props?.loggedIn) {
            logoutAPI.mutate({token: userToken, id: user?.id})
        }

        if (logoutAPI.isSuccess) {
            logoutAPI.reset()

            userToken = logoutAPI?.variables?.token;
            // loginWithToken.mutate({token: userToken})
        }

        if (urlParams.get("token") && !props.loggedIn && userToken) {
            // loginWithToken.mutate({token: userToken})
        }


        if (loginWithToken.isSuccess) {
            localStorage.removeItem('userToken')
            locationHistoryParams.delete('token')
            locationHistoryParams.delete('email')
            navigate(`${history.location.pathname}?${locationHistoryParams.toString()}`)
            loginWithToken.reset()
        }

        if (loginWithToken.isError) {
            localStorage.removeItem('userToken')
            locationHistoryParams.delete('token')
            locationHistoryParams.delete('email')

            if (loginWithToken?.error?.multiple) {
                const {multiple, data, max_devices} = loginWithToken.error;
                setMultipleLoginDetails(prevState => ({
                    ...prevState,
                    devices: data,
                    email: loginWithToken.error.email,
                    loginCount: multiple,
                    token: loginWithToken?.variables?.token,
                    afterLoginSendTo: `${history.location.pathname}?${locationHistoryParams.toString()}`,
                    maxDevices: max_devices
                }));
                setShowLoginErrorModal(true);
            }
            loginWithToken.reset()
        }
    }

    return <>
        {showLoginErrorModal && <MultipleLoginHandleModal multipleLoginDetails={multipleLoginDetails}
                                                          closeShowModal={setShowLoginErrorModal}
                                                          setLoginDetails={setMultipleLoginDetails}/>}
        {props.loggedIn && !(!!userFromRedux?.terms_acceptance) &&
            <TermsModal/>
        }
        {props.loggedIn && !!userFromRedux?.plan_expire_reminder && !!userFromRedux?.terms_acceptance &&
            <PlanExpiryModal/>}
        {props.children}
    </>;
}

function App() {
    const dispatch = useDispatch();
    const loggedIn = useSelector(state => state.authentication.loggedIn);
    const [snackBars, setSnackBars] = useState([])

    const alert = useSelector(state => state.alert);
    const cancel = useSelector(state => state.authentication.cancel);
    const queryClient = useQueryClient();

    if (cancel && false) {
        const queryCache = queryClient.getQueryCache();
        const activeQueries = queryCache.getAll();
        // console.log(activeQueries)
        // Cancel each active query
        activeQueries.forEach((query) => {
            query.cancel();
        });
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: "#dc0022"
            },
        },
    });

    onMessage(messaging, (payload) => {
        const data = payload?.data;

        /*if (Notification.permission === "granted") {
            const notificationOptions = {
                body: payload.notification.body,
                icon: `/${process.env.REACT_APP_BASENAME}/firebase-logo.png`,
                data1: payload
            };
            new Notification(payload.notification.title, notificationOptions);
        }*/

        if (data.uuid) {
            setSnackBars((prevSnackbars) => [...prevSnackbars, {
                message: payload.notification.body,
                title: payload.notification.title,
                severity: 'success',
                url: data?.type === 'download' ? data?.profile_link : `${process.env.REACT_APP_URL}/${process.env.REACT_APP_BASENAME}/profile/${data.uuid}`
            },]);
        }
    });


    useEffect(() => {
        if (alert.message) {
            setTimeout(() => {
                dispatch({type: alertConstants.CLEAR})
            }, 2000)
        }
    }, [alert])
    const getRoutes = (allRoutes) => allRoutes.map((route) => {
        if (route.collapse) {
            return getRoutes(route.collapse);
        }

        if (route.route) {
            return <Route exact path={route.route} element={route.component} key={route.key}/>;
        }

        return null;
    });

    return (
        <>
            {snackBars.map((snackbar, index) => (
                <AlertSnackBar
                    key={index}
                    open={true}
                    autoHideDuration={6000}
                    onClose={() =>
                        setSnackBars((prevSnackbars) =>
                            prevSnackbars.filter((_, i) => i !== index)
                        )
                    }
                    message={snackbar.message}
                    title={snackbar.title}
                    severity={snackbar.severity}
                    url={snackbar.url}
                    style={{
                        top: 80 * index + 10,
                    }}
                    className="notification"
                />
            ))}
            <ThemeProvider theme={theme}>
                <div className={alert.message ? 'rp-toast show' : 'rp-toast'}>
                    <div
                        className={alert.message ? `toast align-items-center text-white ${alert.type} border-0 fade show` : "toast align-items-center text-white border-0"}
                        role="alert" aria-live="assertive" aria-atomic="true">
                        <div>
                            <div className="toast-body">
                                {alert.message}
                            </div>
                        </div>
                    </div>
                </div>

                <BrowserRouter location={history.location} navigator={history}>
                    <ComponentWrapper loggedIn={loggedIn}>
                        <Suspense fallback={loggedIn ? <Layout/> : <Loader/>}>
                            <Routes>
                                {loggedIn
                                    ? getRoutes(protectedRoutes)
                                    : getRoutes(routes)
                                }
                                {loggedIn
                                    ? <Route path="*" element={<Navigate to={getLastPageUrl()}/>}/>
                                    : <Route path="*" element={<Navigate to={basename + "/auth/login"}/>}/>
                                }
                            </Routes>
                        </Suspense>
                    </ComponentWrapper>
                </BrowserRouter>
                <ReactQueryDevtools initialIsOpen={false}/>
            </ThemeProvider>
        </>
    );

}

export default App;
