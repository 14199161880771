import {useMutation, useQueryClient} from "react-query";
import {candidateService} from "../../../../redux/services";
import {alertActions} from "../../../../redux/actions";
import {useDispatch} from "react-redux";

const addCandidate = async (input) => {
    return await candidateService.addCandidate(input);
}


export default function useAddCandidateAPI() {
    const queryClient = useQueryClient()
    const dispatch = useDispatch();

    return useMutation(addCandidate, {
        onSuccess: (data, variables) => {
            if (variables?.candidate_uuid) {
                queryClient.setQueryData(['userData'], data)
                queryClient.refetchQueries('candidateData')
            }
            dispatch(alertActions.success('Added to pipeline'));
        },
        onError: (error, variables, context) => {
            console.log(error, variables, context)
            dispatch(alertActions.error(error));

        }
    })
}


