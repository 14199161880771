import {useQuery} from "react-query";
import {candidateService} from "../../../../redux/services";

const getCandidateDetails = async ({queryKey}) => {
    const [_key, data] = queryKey
    return await candidateService.getSimilarCandidates(data);
};

export default function useGetSimilarCandidatesAPI(data, fetchData) {
    return useQuery(["similarCandidatesSidebar", data], getCandidateDetails, {
        enabled: fetchData,
        retry: false,
        refetchOnWindowFocus: false,
    });
}